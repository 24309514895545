import React, { useEffect } from 'react';
import orderSlice from '../../redux/orderSlice';
import LoadingWrapper from '../LoadingWrapper/LoadingWrapper';
import EventLogsCard from './EventLogsCard';

const MemberOrderLogs = ({ orderId, onError }) => {
  const orderLogsQuery = orderSlice.endpoints.getOrderLogs.useQuery(orderId);

  useEffect(() => {
    orderLogsQuery.isError && onError();
  }, [orderLogsQuery, onError]);

  return (
    <LoadingWrapper isLoading={!orderLogsQuery.isSuccess}>
      <EventLogsCard events={orderLogsQuery.currentData} />
    </LoadingWrapper>
  );
};

export default MemberOrderLogs;
