import React from 'react';
import PropTypes from 'prop-types';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';

const LoadingWrapper = ({ isLoading, children, ...otherProps }) => {
  if (isLoading) {
    return <LoadingIndicator {...otherProps} />;
  }
  return children;
};

LoadingWrapper.propTypes = {
  isLoading: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
};

LoadingWrapper.defaultProps = {
  isLoading: false,
};

export default LoadingWrapper;
