export const specialChars = [
  '^',
  '$',
  '*',
  '.',
  '[',
  ']',
  '{',
  '}',
  '(',
  ')',
  '?',
  '"',
  '!',
  '@',
  '#',
  '%',
  '&',
  '/',
  ',',
  '>',
  '<',
  "'",
  '\\',
  ':',
  ';',
  '|',
  '_',
  '~',
  '`',
  '=',
];

const passwordValidator = (password) => {
  const requirements = [];
  requirements.push({ text: 'Be at least 8 characters.', valid: password.length >= 8 });
  requirements.push({ text: 'Must include at least 1 number.', valid: password.match(/[0-9]+/) });
  requirements.push({
    text: 'Must include at least 1 upper case letter.',
    valid: password.match(/[A-Z]+/),
  });
  requirements.push({
    text: 'Must include at least 1 lower case letter.',
    valid: password.match(/[a-z]+/),
  });
  requirements.push({
    text: `Must include at least 1 special character: ${specialChars.join(' ')}`,
    valid: specialChars.some((char) => password.includes(char)),
  });

  return requirements;
};

export default passwordValidator;
