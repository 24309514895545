import { ReactComponent as CheckboxLine } from '../../../assets/checkbox-line.svg';
import { ReactComponent as CheckboxTick } from '../../../assets/checkbox-tick.svg';
import Ripple from '../../Ripple/Ripple';
import styles from './Checkbox.module.scss';

export type CheckType = 'selected' | 'indeterminate';

interface CheckboxProps extends React.HTMLAttributes<HTMLElement> {
  checked: boolean;
  setChecked: Function;
  disabled?: boolean;
  label?: string;
  checkType?: CheckType;
}

const Checkbox: React.FunctionComponent<CheckboxProps> = ({
  checked,
  setChecked,
  disabled = false,
  label,
  checkType = 'selected',
  ...props
}) => {
  return (
    <Ripple>
      <div className={styles.container}>
        <div
          className={`
          ${styles.checkbox} 
          ${checked && styles['checkbox_checked']} 
          ${disabled && styles['checkbox_disabled']}
          `}
          onClick={() => !disabled && setChecked(!checked)}
          {...props}
        >
          {checked && checkType === 'selected' && <CheckboxTick />}
          {checked && checkType === 'indeterminate' && <CheckboxLine />}
        </div>
        {label && (
          <div
            className={`
            ${styles.label} 
            ${checked && styles['label_checked']}
            ${disabled && styles['label_disabled']}
            `}
          >
            {label}
          </div>
        )}
      </div>
    </Ripple>
  );
};

export default Checkbox;
