import { createSlice } from '@reduxjs/toolkit';

const tabSlice = createSlice({
  name: 'tab',
  initialState: { selectedTab: 'Processing' },
  reducers: {
    selectTab: (state, action) => {
      state.selectedTab = action.payload;
    },
  },
});

export const { selectTab } = tabSlice.actions;

export default tabSlice;
