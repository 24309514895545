import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import {
  setAuthenticated,
  setLoaded,
  selectAuthLoaded,
  selectAuthAuthenticated,
} from './redux/authSlice';
import { overlayOpenSelector } from './redux/overlaySlice';
import { AuthProvider, isSessionValid } from './services/auth';
import LoadingWrapper from './Components/LoadingWrapper/LoadingWrapper';
import orderSlice from './redux/orderSlice';
import { setProcessingOrders, clearProcessingOrders } from './redux/processingOrdersSlice';
import RouteComponent from './RouteComponent';

function App() {
  const dispatch = useDispatch();
  const authLoaded = useSelector(selectAuthLoaded);
  const loggedIn = useSelector(selectAuthAuthenticated);
  const selectedTab = useSelector((state) => state.tab.selectedTab);
  const processingOrders = useSelector((state) => state.processingOrders);
  const [processingOrderPollId, setProcessingOrderPollId] = useState(null);
  const overlayOpen = useSelector(overlayOpenSelector);
  const settingsEnabled = process.env.REACT_APP_ENABLE_SETTINGS === 'true';
  const servicesEnabled = process.env.REACT_APP_ENABLE_SERVICES === 'true';
  const orderPollInterval = parseInt(process.env.REACT_APP_POLL_ORDERS_INTERVAL_MS, 10);
  const location = useLocation();
  const [getProcessingOrders, getProcessingOrdersQuery] =
    orderSlice.endpoints.getProcessingOrders.useLazyQuery();
  const [getOrderCounts] = orderSlice.endpoints.getOrderCounts.useLazyQuery();
  const [getOrders] = orderSlice.endpoints.getOrders.useLazyQuery();

  useEffect(() => {
    if (isNaN(orderPollInterval) || orderPollInterval < 1) {
      return;
    }

    if (loggedIn && !processingOrderPollId) {
      const intervalId = setInterval(getProcessingOrders, orderPollInterval);
      setProcessingOrderPollId(intervalId);
    } else if (!loggedIn && processingOrderPollId) {
      clearInterval(processingOrderPollId);
      setProcessingOrderPollId(null);
      dispatch(clearProcessingOrders());
    }
  }, [dispatch, getProcessingOrders, loggedIn, processingOrderPollId, orderPollInterval]);

  useEffect(() => {
    if (getProcessingOrdersQuery.isSuccess) {
      dispatch(setProcessingOrders(getProcessingOrdersQuery.currentData));
    }
  }, [dispatch, getProcessingOrdersQuery.currentData, getProcessingOrdersQuery.isSuccess]);

  useEffect(() => {
    getOrderCounts();
    if (selectedTab === 'Processing') {
      getOrders([
        'Prescription Issued',
        'Prescription Part Issued',
        'Prescription Processed',
        'Dispensing',
      ]);
    }
  }, [dispatch, processingOrders.orders, getOrderCounts, getOrders, selectedTab]);

  useEffect(() => {
    isSessionValid().then((valid) => {
      dispatch(setAuthenticated(valid));
      dispatch(setLoaded(true));
    });
  }, [dispatch]);

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname });
  }, [location]);

  return (
    <LoadingWrapper isLoading={!authLoaded} className="mx-auto mt-5" style={{ display: 'block' }}>
      <AuthProvider>
        <RouteComponent
          overlayOpen={overlayOpen}
          settingsEnabled={settingsEnabled}
          servicesEnabled={servicesEnabled}
        />
      </AuthProvider>
    </LoadingWrapper>
  );
}

export default App;
