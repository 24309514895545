import PropTypes from 'prop-types';
import { ERROR_MESSAGES } from '../../../constants';
import addressShortFormatter from '../../../helpers/addressShortFormatter';
import Error from '../../../Components/Error/Error';
import './SearchBarResults.css';
import { Col, Row } from 'react-bootstrap';
import { Pill } from '../../../Components/Atoms';

const SearchBarResults = ({
  showSearchBarResults,
  setShowSearchBarResults,
  queryState,
  changeOrderDetails,
}) => {
  const isOrderArchived = (singleResult) => {
    const thirtyDaysAgo = new Date().getTime() - 30 * 24 * 60 * 60 * 1000;

    if (singleResult.status === 'Completed' && singleResult.order_completed_date) {
      if (thirtyDaysAgo > new Date(singleResult.order_completed_date)) {
        return true;
      }
    }

    return false;
  };

  if (queryState.isError && showSearchBarResults) {
    return (
      <div className="searchBarResultsFlexContainer">
        <div className="searchBarResultsContainer">
          <div className="searchBarErrorContainer">
            <Error>{ERROR_MESSAGES.SEARCH}</Error>
          </div>
        </div>
      </div>
    );
  } else if (
    queryState.isSuccess &&
    queryState.currentData &&
    queryState.currentData.length &&
    showSearchBarResults
  ) {
    return (
      <Col md={12} className="searchBarResultsFlexContainer">
        <Col className="resultsTableContainer">
          {/* HEADINGS */}
          <Row className="resultsTableHeaderContainer">
            <Col md={3} className="resultsTableList">
              <span className="resultsTableHeading">Patient Name</span>
            </Col>
            <Col md={4} className="resultsTableList">
              <span className="resultsTableHeading">Address</span>
            </Col>
            <Col md={2} className="resultsTableList">
              <span className="resultsTableHeading">Date Created</span>
            </Col>
            <Col md={2} className="resultsTableList">
              <span className="resultsTableHeading">Order Completed</span>
            </Col>
            <Col md={1} className="resultsTableList">
              <span className="resultsTableHeading"></span>
            </Col>
          </Row>
          {/* HEADINGS */}
          {/* BODY */}
          <Row className="resultsTableBodyContainer">
            {queryState.currentData.map((singleResult, index) => (
              <Col
                md={12}
                className={`resultsTableRow ${isOrderArchived(singleResult) ? 'archived' : ''}`}
                key={index}
                onClick={() => {
                  changeOrderDetails(singleResult.coop_id);
                  setShowSearchBarResults(false);
                }}
                onKeyPress={(event) => {
                  if (event.key === 'Enter' && event.target.className === 'searchBarResult') {
                    changeOrderDetails(singleResult.coop_id);
                    setShowSearchBarResults(false);
                  }
                }}
              >
                <Col md={3}>
                  <span className="resultsTableBody">
                    {singleResult.last_name} {singleResult.first_name}
                  </span>
                </Col>
                <Col md={4}>
                  <span className="resultsTableBody">
                    {addressShortFormatter(singleResult.gp_registered_address)}
                  </span>
                </Col>
                <Col md={2}>
                  <span className="resultsTableBody">
                    {new Date(singleResult.received_on).toLocaleDateString('en-GB')}
                  </span>
                </Col>
                <Col md={2}>
                  <span className="resultsTableBody">
                    {singleResult.order_completed_date
                      ? new Date(singleResult.order_completed_date).toLocaleDateString('en-GB')
                      : '--'}
                  </span>
                </Col>
                <Col md={1}>
                  <span className="resultsTableBody">
                    {isOrderArchived(singleResult) && (
                      <Pill variant="archive" size="extra-small">
                        Archived
                      </Pill>
                    )}
                  </span>
                </Col>
              </Col>
            ))}
          </Row>
        </Col>
      </Col>
    );
  } else if (
    queryState.isSuccess &&
    queryState.currentData &&
    !queryState.currentData.length &&
    showSearchBarResults
  ) {
    return (
      <div className="searchBarResultsFlexContainer">
        <div className="searchBarResultsContainer">
          <div className="searchBarResultEmpty">No results found.</div>
        </div>
      </div>
    );
  }
  return null;
};

SearchBarResults.propTypes = {
  showSearchBarResults: PropTypes.bool,
  setShowSearchBarResults: PropTypes.func,
  changeOrderDetails: PropTypes.func,
  queryState: PropTypes.object,
};

export default SearchBarResults;
