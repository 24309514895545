const displayAddressLine = (address) => {
  if (address.address1) {
    return address.address1;
  } else if (address.address2) {
    return address.address2;
  } else if (address.address3) {
    return address.address3;
  } else if (address.address4) {
    return address.address4;
  }
  return '';
};

const addressShortFormatter = (address) => {
  let result = displayAddressLine(address);

  if (result) {
    if (address.postcode) {
      result = `${result}, ${address.postcode}`;
    }
  } else if (address.postcode) {
    result = address.postcode;
  }

  return result || 'N/A';
};

export default addressShortFormatter;
