const emailValidator = (email) => {
  //eslint-disable-next-line
  if (!email.match(/^(.+)@(.+)\.(.+)$/)) {
    return 'Must be a valid email address.';
  } else {
    return null;
  }
};

export default emailValidator;
