const orderStyling = `
.container:before,
.container:after,
.container-fluid:before,
.container-fluid:after,
.row:before,
.row:after {
  content: " ";
  display: table;
}

body, input, label, td, th, div, span, textarea, a, button, select, legend, .form-control,
input.form-control, textarea.form-control, select.form-control, .purpose {
    font-family: "Campton Book", Helvetica, Arial, sans-serif;
    font-size: 15px;
    line-height: 1.5;
    color: #6C6C6C;
}

h1 {
    font-size: 24pt;
}

h3 {
    font-size: 14pt;
}

h4 {
    font-size: 14pt;
}

h1 span a {
    font-size: 100%;
}

h1 span {
    font-size: 0.75em;
}

.remove-margins {
    margin-bottom: 0px;
    margin-top: 0px;
}

.orders .clear-filters-container {
    margin-top: 50px;
}

@media (min-width: 1140px) {
    .orders {
        position: relative;
    }
    .orders .clear-filters-container {
        position: absolute;
        right: 30px;
        top: 22px;
        margin-top: 0;
    }
}
.orders .details-container {
    margin-bottom: 10px;
}
/* The 'value' section of the data displayed in the details panel */
.orders .details-container .row div {
    word-wrap: break-word;
}
.orders .details-container th,
.orders .details-container td,
.orders .details-container button {
    font-size: 12px;
}

.print-invoice {
    margin-top: 10px;
    margin-bottom: 10px;
}

.row.padding-top {
    padding-top: 15px;
}

.container-fluid {
    page-break-after: always;
}

.container-fluid:last-child {
    page-break-after: auto;
}

.container-fluid .card {
    width: calc(100% - 2rem);
    margin: 1rem;
    padding: 1.5rem;
    border-radius: 8px;
}

.container-fluid .card h3 {
    font-weight: 700;
    margin-bottom: 0.5rem;
}

.container-fluid .card h6 {
    margin-bottom: 0.125rem;
    margin-top: 0.5rem;
    font-weight: 700;
}

.container-fluid .col-md-8 .card {
    margin-left: 0;
    width: calc(100% - 1rem);
}

.container-fluid .card .fieldName {
    font-family: var(--font-family-medium);
    font-size: 18px;
    font-weight: 500;
}

.container-fluid .card .fieldValue {
    color: var(--hp-color-mid-grey);
    font-weight: 400;
    font-size: 18px;
}

.container-fluid .medicationCardContent .row {
    margin-top: 0.5rem;
}

.container-fluid .medicationCardContent .row:first-child {
    margin-top: 0;
}

.container-fluid .medicationCardContent .row .col:first-child {
    padding-left: 0;
}

.container-fluid .medicationCardContent .row .col-3 {
    padding-right: 0;
}

.container-fluid .medicationCardContent .fieldValue {
    font-size: 16px;
}

.container-fluid .headerCard {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.container-fluid .headerCard .headerRight {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.container-fluid .headerCard,
.container-fluid .headerCard .name,
.container-fluid .headerCard .orderNumber {
    margin-bottom: 0;
}

.container-fluid .headerCard .orderNumber {
    color: var(--hp-color-mid-grey);
    font-weight: 500;
}

.container-fluid .headerCard .printIcon {
    margin-left: 1rem;
}

.container-fluid .col-md-4,
.container-fluid .col-md-8 {
    padding: 0;
}

#wrapper { width: 710px; height: 1420px; padding: 0; overflow: hidden; }
#scaled-frame { width: 1000px; height: 2000px; border: 0px; }
#scaled-frame {
    zoom: 0.71;
    -moz-transform: scale(0.71);
    -moz-transform-origin: 0 0;
    -o-transform: scale(0.71);
    -o-transform-origin: 0 0;
    -webkit-transform: scale(0.71);
    -webkit-transform-origin: 0 0;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
    #scaled-frame  { zoom: 1;  }
}
    `;

export default orderStyling;
