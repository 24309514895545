import { createSlice } from '@reduxjs/toolkit';

export const overlayContentKeys = {
  PrivacyPolicy: 'PrivacyPolicy',
  TandC: 'TandC',
  CookiePolicy: 'CookiePolicy',
};

const overlaySlice = createSlice({
  name: 'overlay',
  initialState: { open: false },
  reducers: {
    setOpen: (state, action) => {
      state.open = action.payload;
    },
    setContentKey: (state, action) => {
      state.contentKey = action.payload;
    },
  },
});

export const { setOpen, setContentKey } = overlaySlice.actions;
export const overlayOpenSelector = (state) => state.overlay.open;
export const overlayContentKeySelector = (state) => state.overlay.contentKey;

export default overlaySlice;
