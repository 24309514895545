import { useDispatch, useSelector } from 'react-redux';
import { clearSelectedOrders, selectedOrdersSelector } from '../../redux/selectedOrdersSlice';
import { Icon } from '../Atoms';
import Chip from '../Atoms/Chip/Chip';
import OrderStatusDropdown from '../OrderStatusDropdown/OrderStatusDropdown';
import BulkPrintOrderControl from '../OrderPrint/BulkPrintOrderControl';
import styles from './BulkOperationBar.module.scss';

const BulkOperationBar = ({ onError }) => {
  const selectedOrders = useSelector(selectedOrdersSelector);
  const dispatch = useDispatch();

  return (
    <div className={styles['bulk-operation-bar']}>
      <div className={styles['left-container']}>
        <div className={styles['total-selected']}>
          {selectedOrders.length} {selectedOrders.length === 1 ? 'order' : 'orders'} selected
        </div>
        <Chip onClick={() => dispatch(clearSelectedOrders())}>
          <Icon iconName="cross" className={styles['chip-cross']} />
          Clear Selection
        </Chip>
      </div>
      <div className={styles['right-container']}>
        <div className={styles['dropdown']}>
          <OrderStatusDropdown bulk onError={(e) => e && onError()} />
        </div>
        {selectedOrders.every(
          (order) =>
            order.status === 'Prescription Issued' ||
            order.status === 'Prescription Part Issued' ||
            order.status === 'Prescription Processed'
        ) ? (
          <BulkPrintOrderControl key={selectedOrders.length} />
        ) : (
          <Icon iconName="printerDisabled" className={styles['disabled-printer']} />
        )}
      </div>
    </div>
  );
};

export default BulkOperationBar;
