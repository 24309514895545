import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'react-bootstrap';
import ReactGA from 'react-ga4';
import { renderToStaticMarkup } from 'react-dom/server';
import generateIframeUrl from '../../helpers/generateIframeUrl';
import orderSlice from '../../redux/orderSlice';
import { Icon } from '../Atoms';
import OrderContent from './OrderContent';
import orderStyling from './OrderStyling';
import './PrintOrderControl.css';

const PrintOrderControl = ({ order, coop_id }) => {
  const [isError, setIsError] = useState(false);
  const [iframeSource, setIframeSource] = useState('');
  const [getOrderDetails, orderDetailsQuery] = orderSlice.endpoints.getOrderDetails.useLazyQuery();

  useEffect(() => {
    if (orderDetailsQuery.isSuccess) {
      setIframeSource(
        generateIframeUrl({
          html: renderToStaticMarkup(OrderContent({ order: orderDetailsQuery.currentData })),
          css: orderStyling,
        })
      );
    }
  }, [orderDetailsQuery.isSuccess, orderDetailsQuery.currentData]);

  useEffect(() => {
    if (orderDetailsQuery.isError) {
      setIsError(true);
    } else {
      setIsError(false);
    }
  }, [orderDetailsQuery.isError]);

  const handleClick = (e) => {
    ReactGA.event({
      category: 'Orders',
      action: 'Print_Icon_Click',
    });
    e.stopPropagation();
    if (!order) {
      getOrderDetails(coop_id);
    } else {
      setIframeSource(
        generateIframeUrl({
          html: renderToStaticMarkup(OrderContent({ order })),
          css: orderStyling,
        })
      );
    }
  };
  if (isError) {
    return (
      <Alert variant="danger" id="errorMessage">
        Report error.
      </Alert>
    );
  } else {
    return (
      <>
        {iframeSource && (
          <iframe
            title="htmlPrintDialog"
            src={iframeSource}
            type="application/html"
            width="0"
            height="0"
            onLoad={(event) => {
              event.target.contentWindow.print();
            }}
            tabIndex="-1"
            style={{ display: 'none' }}
          />
        )}
        <Icon
          className="printIcon"
          iconName="printer"
          onClick={(e) => {
            handleClick(e);
          }}
          tabIndex="0"
          onKeyPress={(e) => (e.key === 'Enter' ? handleClick(e) : null)}
        />
      </>
    );
  }
};

PrintOrderControl.propTypes = {
  coop_id: PropTypes.any,
  order: PropTypes.object,
};

export default PrintOrderControl;
