import React from 'react';

const TandC = () => (
  <>
    <h1>Hey Pharmacist Portal</h1>
    <h1>Information about us</h1>
    <p>
      We are Numark Digital Limited, a company registered in England and Wales with company number
      13316137, trading as Rowlands Pharmacy Online, part of the PHOENIX Group UK, registered at
      Rivington Road, Whitehouse Industrial Estate, Preston Brook, Runcorn, Cheshire, WA7 3DJ.
    </p>
    <p>
      Hey Pharmacist Portal is our digital service which allows our pharmacy members to search for
      patients who have nominated the member Pharmacy for collection of their medication. It allows
      the members to update their patient's medication journey.
    </p>
    <p>
      The term "Hey Pharmacist Portal" or "us" or "we" refers to the owner of the website whose
      registered office is Rivington Road, Whitehouse Industrial Estate, Preston Brook, Runcorn,
      Cheshire, WA7 3DJ. The term "you" refers to the user or viewer of the Portal
    </p>
    <h1>Terms and Conditions</h1>
    <p>
      By accessing or using the Hey Pharmacist Portal, you are agreeing to be bound by and to comply
      with these terms and conditions of use, which together with our privacy policy govern Numark
      Digital's relationship with you in relation to this website.
    </p>
    <p>
      We may update these terms and conditions from time to time for technical, legislative or
      regulatory reasons or if we notice any errors. If we make any changes that would have a
      significant detrimental effect on your ability to use Hey Pharmacist or on your rights under
      these terms then we will send you an email to let you know.
    </p>
    <h1>Who may use the Portal</h1>
    <p>Users must be a GPHC registered pharmacy site, and a current Numark member.</p>
    <p>Both Numark membership and Hey Pharmacist fee for the month ahead must be paid.</p>
    <p>
      Any Numark members who have opted out of being a collection point for Hey Pharmacist will not
      have access to the Portal.
    </p>
    <h1>The use of this website is subject to the following:</h1>
    <p>
      The content of this Portal is for your use only, and must only be accessed for the purpose for
      which it is intended. It is subject to change without notice.
    </p>
    <p>
      Your use of any information from the Portal is entirely at your own risk, for which we shall
      not be liable.
    </p>
    <p>
      This Portal contains material which is owned by or licensed to us. This material includes, but
      is not limited to, the design, layout, look, appearance and graphics. Reproduction is
      prohibited other than in accordance with the copyright notice, which forms part of these terms
      and conditions.
    </p>
    <p>
      All trademarks reproduced in this website, which are not the property of Numark digital are
      acknowledged on the website and cannot be reproduced.
    </p>
    <p>
      It shall be your own responsibility to ensure that any products, services or information
      provided to Patients following your access of the Portal comply with legal requirements.
    </p>
    <h1>1. Definitions</h1>
    <p>
      <b>Data Controller</b> is defined under the General Data Protection Legislation (GDPR) Article
      4(7) as; the natural or legal person, public authority, agency or other body which, alone or
      jointly with others, determines the purpose and means of the processing of personal data.
    </p>
    <p>
      <b>Data Subject</b> means each identified or identifiable (whether directly or indirectly)
      natural person to whom any Personal Data relates;
    </p>
    <p>
      <b>Personal Data</b> means any information relating to an identified or identifiable living
      individual; and
    </p>
    <p>
      <b>Data Protection Law</b> means all data protection and privacy laws in force from time to
      time in the UK applicable to the personal data in question, including the Data Protection Act
      2018 and the General Data Protection Regulation (Regulation 2016/679) as incorporated into UK
      Law pursuant to the European Union (Withdrawal Act) 2018, in each case as may be amended or
      superseded from time to time.
    </p>
    <h1>2. Data Processing</h1>
    <p>
      This Clause sets out the framework for the sharing of personal data between the parties. Both
      Numark Digital Limited and Numark Members using the Portal are deemed to be independent{' '}
      <b>Data Controllers</b>.
    </p>
    <p>
      Each party acknowledges that they are independent <b>Data Controllers</b> and will be
      accessing data via the Portal for the agreed purpose, as set out in section 3 data sharing
      activities, to search for patients (<b>Data Subject</b>) who have nominated the Numark Member
      Pharmacy for collection of their medication, and to update the patient's medication journey.
    </p>
    <p>
      The parties are solely responsible for the processing of the <b>personal data</b> via the Hey
      Pharmacist Portal Both parties agree that they are responsible for their own compliance with
      current UK Data Protection frameworks for any persons accessing the Portal.
    </p>
    <h1>3. Data sharing Activities</h1>
    <table className="overlayTable">
      <tbody>
        <tr>
          <td>Scope, nature and purpose of processing of Data</td>
          <td>
            The purpose of the data sharing and processing is to enable Numark Members access to the
            Hey Pharmacist Portal. This access will enable the patient's nominated pharmacy, the
            Numark Member, to update the patient on their medication dispensing journey.
          </td>
        </tr>
        <tr>
          <td>Duration of processing of Data</td>
          <td>
            For the duration of the Numark Membership, as set out in the above section{' '}
            <b>Who may use the Portal</b> (and where necessary to meet any post-contractual
            obligations).
          </td>
        </tr>
        <tr>
          <td>Types of Data processed</td>
          <td>Personal Sensitive Data.</td>
        </tr>
        <tr>
          <td>Categories of data subject whose Data is processed</td>
          <td>
            Prescription and contact data including; <br />
            Name, addresses, date of birth, NHS numbers, prescribed medication, dosage, prescriber,
            prescriber address, dispensing pharmacy, dispensed medication, clinical notes, telephone
            numbers, email addresses and other contact details.
          </td>
        </tr>
      </tbody>
    </table>
    <p>
      Use of the Portal is entirely at your own risk, for which we shall not be liable. It shall be
      your own responsibility to ensure that the data accessed through this portal is used only for
      the purpose for which it is intended, which is to ensure the patient's medication journey is
      completed and updated accordingly (within a reasonable timeframe).
    </p>
    <h1>Other important terms</h1>
    <p>
      Unauthorised use of the Portal may give rise to a claim for damages and/or be a criminal
      offence.
    </p>
    <p>
      The information held on the Portal is for professional use only, any error or omission in, or
      any failure to update the patient via the portal is the sole responsibility of the member.
    </p>
    <p>You may not create a link to this Portal from any other source.</p>
    <p>
      In using this website you agree to abide by the "General Pharmaceutical Council's Standards
      for Conduct, Ethics and Performance" External website.
    </p>
    <p>
      We may transfer our rights and obligations under these terms to another organisation. We will
      contact you to let you know if we plan to do this. You may only transfer your rights or your
      obligations under these terms to another person if we agree to this in writing (not to be
      unreasonably withheld).
    </p>
    <p>
      Any contracts entered into under these terms are between you and us. No other person shall
      have any rights to enforce any of these terms.
    </p>
    <p>
      Each of the paragraphs of these terms operates separately. If any court or relevant authority
      decides that any of them are unlawful, the remaining paragraphs will remain in full force and
      effect.
    </p>
    <p>
      These terms are governed by English law and you can bring legal proceedings in respect of the
      products in the English courts.
    </p>
  </>
);

export default TandC;
