const locale = 'en-GB';

const dateTimeFormatter = (dateTimeString) => {
  if (!dateTimeString) {
    return null;
  }

  var d = new Date(dateTimeString);

  return {
    LocalDate: d.toLocaleDateString(locale),
    LocalTime: d.toLocaleTimeString(locale),
  };
};

export default dateTimeFormatter;
