import React from 'react';

const PrivacyPolicy = () => (
  <>
    <h1>
      Privacy Policy - Numark Digital Limited T/A Hey Pharmacist &amp; Rowlands Pharmacy - <br />
      Hey Pharmacy Portal
    </h1>
    <p>
      We are Numark Digital Limited, a company registered in England and Wales with company number
      13316137, trading as Hey Pharmacist &amp; Rowlands Pharmacy Online, part of the PHOENIX Group
      UK registered at Rivington Road, Whitehouse Industrial Estate, Preston Brook, Runcorn,
      Cheshire, WA7 3DJ.
    </p>
    <p>ICO Registration Number: ZA441454</p>
    <p>
      Email:{' '}
      <a href="mailto:dataprotection@phoenixmedical.co.uk">dataprotection@phoenixmedical.co.uk</a>
    </p>
    <h1>Your Data Matters</h1>
    <p>
      This privacy notice tells you what to expect when PHOENIX collects and processes your personal
      information. We take the protection of your data seriously and are committed to being
      transparent with you about the data we collect and how it is used and shared. We are committed
      to ensuring that we meet all our obligations under the Data Protection Act 2018 and the UK
      General Data Protection Regulation as amended from time to time.
    </p>
    <h1>1. Your personal data and what we use it for</h1>
    <p>
      We process your personal data, which may include your name, job title, contact details,
      location details such as postcode, your preferences and interests plus information relating to
      our customer surveys and offers for the purposes of:-
    </p>
    <ul>
      <li>Internal record keeping;</li>
      <li>Providing you with our products and services;</li>
      <li>Improving our products and services;</li>
      <li>
        We may share the information with our associate group companies for the purpose of managing
        the terms of your membership;
      </li>
      <li>
        We may share the information with our other third party business partners which may include
        the NHS Business Services Authority and other relevant NHS Local Authorities in enabling us
        to manage any schemes and services which we administer on your behalf. This includes
        pharmaceutical manufacturers, suppliers, pharmaceutical commissioning authorities and our
        third party IT providers;
      </li>
      <li>
        We may share the information with our other third party business partners for the provision
        of on-line services such as webinars;
      </li>
      <li>For the purpose of training our staff and to improve our quality of service;</li>
      <li>
        For the safety and security of our employees and visitors, we may use CCTV on our premises;
      </li>
      <li>
        We may periodically send details about new products, special offers or services in order to
        fulfil our obligations under our membership agreement with you;
      </li>
      <li>Market research.</li>
    </ul>
    <h1>2. Lawful basis for processing your data</h1>
    <p>
      We process your data in order to provide the goods and services set out in our contractual
      agreement with you and for our legitimate business needs and purposes.
    </p>
    <h1>3. Your rights</h1>
    <p>
      Data protection laws provide you with a number of rights as set out below, not all of these
      rights are absolute and some only apply in certain circumstances.
    </p>
    <h4>Right of Access</h4>
    <p>
      You have the right to ask us for copies of your personal information. This right always
      applies, however, there are some exemptions, which means you may not always receive all the
      information we process about you. If this is the case, an explanation will be provided to you.
    </p>
    <h4>Right to rectification</h4>
    <p>
      You have the right to ask us to rectify information you think is inaccurate. You also have the
      right to ask us to complete information you think is incomplete. This right always applies.
    </p>
    <h4>Right to erasure</h4>
    <p>
      In certain circumstances, you have the right to request that your data be deleted; however,
      there are some exemptions that mean this may not be possible, for example where our legal
      obligations prevent this.
    </p>
    <h4>Right to restriction of processing</h4>
    <p>
      In certain circumstances, you may request a restriction of the processing of your data;
      however, there are some exemptions that mean this may not be possible, for example where our
      legal obligations prevent this.
    </p>
    <h4>Right of data portability</h4>
    <p>
      This only applies to information you have given us. You have the right to ask that we transfer
      the information you gave us from one organisation to another, or give it to you. The right
      only applies if we are processing information based on your consent.
    </p>
    <h4>Right to object</h4>
    <p>
      In certain circumstances, you may object to the processing of your data; however, there are
      some exemptions that mean this may not be possible, for example where our legal obligations
      prevent this.
    </p>
    <p>
      You have the right to lodge a complaint with the supervisory authority (although we would
      encourage you to contact us in the first instance). The Information Commissioner can be
      contacted at; Information Commissioners Office, Wycliffe house, Water Lane, Wilmslow,
      Cheshire, SK9 5AF <a href="https://ico.org.uk/">https://ico.org.uk/</a>
    </p>
    <h1>4. Retaining your data</h1>
    <p>
      We hold your information for a period of 6 years from the date of supply of goods to you in
      accordance with the Limitation Act 1980 or as long as is reasonably necessary in accordance
      with the Data Protection Act 2018.
    </p>
    <h1>5. Security</h1>
    <p>
      We are committed to ensuring that your information is secure. In order to prevent unauthorised
      access or disclosure we have put in place suitable physical, electronic and managerial
      procedures to safeguard and secure the information we collect. We may need to transfer your
      information outside the United Kingdom to other service providers and business partners. We
      will only transfer your personal information in this way where there is an adequate level of
      protection that is the same as the level of protection required under UK Data Protection
      legislation.
    </p>
    <h1>6. Links to other websites</h1>
    <p>
      Our Portal may contain links to enable you to visit other websites of interest easily.
      However, once you have used these links to leave our site, you should note that we do not have
      any control over that other website. Therefore, we cannot be responsible for the protection
      and privacy of any information which you provide whilst visiting such sites and such sites are
      not governed by this privacy statement. You should exercise caution and look at the privacy
      statement applicable to the website in question.
    </p>
  </>
);

export default PrivacyPolicy;
