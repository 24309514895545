import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import orderSlice from '../../../redux/orderSlice';
import SearchBar from './SearchBar';
import SearchBarResults from './SearchBarResults';

const Search = ({ onSelectSearchResult }) => {
  const searchRef = useRef();
  const [showSearchBarResults, setShowSearchBarResults] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [showArchive] = useState(true);
  const [searchOrders, searchOrdersQuery] = orderSlice.endpoints.searchOrders.useLazyQuery();
  const minLength = 3;

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (showSearchBarResults && searchRef.current && !searchRef.current.contains(e.target)) {
        setShowSearchBarResults(false);
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [showSearchBarResults]);

  const performOrderSearch = async (term) => {
    await searchOrders({ searchTerm: term, includeArchive: showArchive });
    setShowSearchBarResults(true);
  };

  const clearOrderSearch = () => {
    setShowSearchBarResults(false);
  };

  return (
    <div
      className="searchBarContainer"
      ref={searchRef}
      onFocus={() => {
        setShowSearchBarResults(!!searchValue && searchValue.length >= minLength);
      }}
    >
      <SearchBar
        searchFunction={performOrderSearch}
        minLength={minLength}
        placeholderText={'Search Patient name, NHS No, Order No'}
        inProgress={searchOrdersQuery.inProgress}
        autoFocus={false}
        clearFunction={() => clearOrderSearch()}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
      />

      <SearchBarResults
        showSearchBarResults={showSearchBarResults}
        setShowSearchBarResults={(val) => setShowSearchBarResults(val)}
        changeOrderDetails={(id) => {
          clearOrderSearch();
          setSearchValue('');
          onSelectSearchResult(id);
        }}
        queryState={searchOrdersQuery}
      />
    </div>
  );
};

Search.propTypes = {
  onSelectSearchResult: PropTypes.func.isRequired,
};

export default Search;
