import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import ReactGA from 'react-ga4';
import { renderToStaticMarkup } from 'react-dom/server';
import generateIframeUrl from '../../helpers/generateIframeUrl';
import orderSlice from '../../redux/orderSlice';
import { selectedOrdersSelector } from '../../redux/selectedOrdersSlice';
import Error from '../Error/Error';
import { Icon } from '../Atoms';
import OrderContent from './OrderContent';
import orderStyling from './OrderStyling';
import './BulkPrintOrderControl.css';

const BulkPrintOrderControl = () => {
  const selectedOrders = useSelector(selectedOrdersSelector);
  const [isError, setIsError] = useState(false);
  const [iframeSource, setIframeSource] = useState('');

  const [getOrderDetailsFunctions, orderDetailsQueries] = selectedOrders.reduce(
    ([detailFunctions, detailQueries]) => {
      const [getOrderDetails, orderDetailsQuery] =
        orderSlice.endpoints.getOrderDetails.useLazyQuery();
      detailFunctions.push(getOrderDetails);
      detailQueries.push(orderDetailsQuery);
      return [detailFunctions, detailQueries];
    },
    [[], []]
  );

  useEffect(() => {
    if (orderDetailsQueries.length && orderDetailsQueries.every((query) => query.isSuccess)) {
      setIframeSource(
        generateIframeUrl({
          html: renderToStaticMarkup(
            <>
              {orderDetailsQueries.map((orderQuery) => {
                return OrderContent({ order: orderQuery.currentData });
              })}
            </>
          ),
          css: orderStyling,
        })
      );
    }
  }, [orderDetailsQueries]);

  useEffect(() => {
    if (orderDetailsQueries.length && orderDetailsQueries.some((query) => query.isError)) {
      setIsError(true);
    } else {
      setIsError(false);
    }
  }, [orderDetailsQueries]);

  const handleClick = (e) => {
    ReactGA.event({
      category: 'Orders',
      action: 'Bulk_Print_Click',
    });
    e.stopPropagation();

    selectedOrders.forEach((order, index) => {
      getOrderDetailsFunctions[index](order.coop_id);
    });
  };
  if (isError) {
    return (
      <div className="errorContainer">
        <Error>Report error.</Error>
      </div>
    );
  } else {
    return (
      <>
        {iframeSource && (
          <iframe
            title="htmlPrintDialog"
            src={iframeSource}
            type="application/html"
            width="0"
            height="0"
            onLoad={(event) => {
              event.target.contentWindow.print();
            }}
            tabIndex="-1"
            style={{ display: 'none' }}
          />
        )}
        <Icon
          className="printIcon"
          iconName="printer"
          onClick={(e) => {
            handleClick(e);
          }}
          tabIndex="0"
          onKeyPress={(e) => (e.key === 'Enter' ? handleClick(e) : null)}
        />
      </>
    );
  }
};

export default BulkPrintOrderControl;
