import PropTypes from 'prop-types';
import { Col, Form, InputGroup, Row } from 'react-bootstrap';
import { ReactComponent as SearchIcon } from '../../../assets/search.svg';
import { Icon } from '../../../Components/Atoms';
import './SearchBar.css';

const SearchBar = ({
  searchFunction,
  minLength,
  maxLength,
  placeholderText,
  inProgress,
  autoFocus,
  clearFunction,
  searchValue,
  setSearchValue,
}) => {
  const onInput = (e) => {
    e.preventDefault();

    const text = e.target.value ? e.target.value.trim() : null;

    if (!inProgress && isValid(text)) {
      searchFunction(text);
    } else {
      clearFunction();
    }
  };

  const isValid = (text) => {
    return !!text && text.length >= minLength && text.length <= maxLength;
  };

  return (
    <Form autoComplete="off">
      <Row>
        <Col>
          <InputGroup className="mb-3 mt-5 searchGroup">
            <InputGroup.Prepend className="searchIconContainer">
              <SearchIcon className="searchIcon" />
            </InputGroup.Prepend>
            <Form.Control
              placeholder={placeholderText}
              id="search"
              required
              minLength={minLength}
              maxLength={maxLength}
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
              onInput={onInput}
              autoFocus={autoFocus}
              className="searchInput"
            />
            {searchValue && (
              <InputGroup.Append
                onClick={() => {
                  setSearchValue('');
                  clearFunction();
                }}
                className="closeIconContainer"
              >
                <Icon iconName="cross" className="closeIcon" />
              </InputGroup.Append>
            )}
          </InputGroup>
        </Col>
      </Row>
    </Form>
  );
};

SearchBar.propTypes = {
  searchFunction: PropTypes.func.isRequired,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  placeholderText: PropTypes.string,
  inProgress: PropTypes.bool,
  autoFocus: PropTypes.bool,
  clearFunction: PropTypes.func.isRequired,
  searchValue: PropTypes.string,
  setSearchValue: PropTypes.func.isRequired,
};

SearchBar.defaultProps = {
  minLength: 0,
  maxLength: 255,
  placeholderText: '',
  inProgress: false,
  autoFocus: true,
  searchValue: '',
};

export default SearchBar;
