import { Icon } from '../../../Components/Atoms';
import PrintOrderControl from '../../../Components/OrderPrint/PrintOrderControl';
import OrderReminder from '../../../Components/OrderReminder/OrderReminder';
import OrderStatusDropdown from '../../../Components/OrderStatusDropdown/OrderStatusDropdown';
import { ERROR_MESSAGES } from '../../../constants';
import addressShortFormatter from '../../../helpers/addressShortFormatter';
import dateFormatter from '../../../helpers/dateFormatter';
import nhsNumberFormatter from '../../../helpers/nhsNumberFormatter';
import './headings.scss';

const headings = (selectedTab, setError) => {
  return [
    {
      id: 1,
      name: ' ',
      selector: (order) => order.fulfillment_type,
      cell: (order) => (
        <Icon
          iconName={order.fulfillment_type === 'pharmacyDelivery' ? 'van' : 'buildingHospital'}
          className="deliveryIcon"
        />
      ),
      sortable: true,
      eventName: 'Order_Type',
      grow: 1,
      width: '56px',
    },
    {
      id: 2,
      name: 'Patient Name',
      selector: (order) =>
        order?.first_name && order?.last_name ? `${order.last_name} ${order.first_name}` : null,
      sortable: true,
      eventName: 'Patient_Name',
      grow: 2,
    },
    {
      id: 3,
      name: 'Address',
      selector: (order) => addressShortFormatter(order.gp_registered_address),
      sortable: true,
      eventName: 'Address',
      grow: 2,
    },
    {
      id: 4,
      name: 'NHS No',
      selector: (order) => {
        if (order?.nhs_number) {
          return nhsNumberFormatter(order.nhs_number);
        } else {
          return null;
        }
      },
      sortable: true,
      eventName: 'NHS_Number',
      grow: 2,
    },
    {
      id: 5,
      name: 'Date Created',
      selector: (order) => dateFormatter(order.received_on),
      sortable: true,
      eventName: 'Date',
      grow: 2,
    },
    {
      id: 6,
      name: 'Current Status',
      selector: (order) => order.status,
      cell: (order) => (
        <OrderStatusDropdown
          order={order}
          onError={(e) => e && setError(order.coop_id, ERROR_MESSAGES.STATUS)}
        />
      ),
      sortable: true,
      eventName: 'Status',
      grow: 3,
    },
    {
      id: 7,
      name: '',
      selector: (order) => order.fulfillment_type,
      cell: (order) => {
        if (order) {
          switch (selectedTab) {
            case 'Completed':
              if (order.fulfillment_type === 'collectFromPharmacy') {
                return (
                  <OrderReminder
                    coop_id={order.coop_id}
                    onError={() => setError(order.coop_id, ERROR_MESSAGES.REMINDER)}
                  />
                );
              } else {
                return null;
              }
            case 'Processing':
              return order.status === 'Prescription Issued' ||
                order.status === 'Prescription Part Issued' ||
                order.status === 'Prescription Processed' ? (
                <PrintOrderControl coop_id={order.coop_id} />
              ) : null;
            default:
              return null;
          }
        }
        return null;
      },
      grow: 1,
    },
  ];
};

export default headings;
