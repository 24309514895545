import React from 'react';
import { Table } from 'react-bootstrap';
import PropTypes from 'prop-types';
import dateTimeFormatter from '../../helpers/dateTimeFormatter';

const EventLogsCard = ({ events }) => {
  const addRow = (event, index) => {
    const d = dateTimeFormatter(event.date_time);

    return (
      <tr key={index}>
        <td>{event.event}</td>
        <td>{event.actioned_by}</td>
        <td>{d.LocalDate}</td>
        <td>{d.LocalTime}</td>
      </tr>
    );
  };

  return (
    <Table bordered striped responsive>
      <thead>
        <tr className="card-header">
          <th>Event</th>
          <th>Actioned By</th>
          <th>Date</th>
          <th>Time</th>
        </tr>
      </thead>
      <tbody>
        <tr></tr>
        {events.map((event, i) => addRow(event, i))}
      </tbody>
    </Table>
  );
};

EventLogsCard.propTypes = {
  events: PropTypes.arrayOf(
    PropTypes.shape({
      event: PropTypes.string.isRequired,
      actioned_by: PropTypes.string.isRequired,
      date_time: PropTypes.string.isRequired,
    })
  ),
};

export default EventLogsCard;
