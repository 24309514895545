import styles from './Notification.module.scss';
import React from 'react';
import { Link } from 'react-router-dom';
import Icon from '../../Atoms/Icon/Icon';

interface NotificationProps extends React.HTMLAttributes<HTMLElement> {
  titleText: string;
  descriptionText: string;
  urlText?: string;
  url?: string;
  urlOnclickHandler?: any;
  buttonOnclickHandler?: any;
  dismissible: boolean;
}

const Notification: React.FunctionComponent<NotificationProps> = ({
  titleText,
  descriptionText,
  urlText,
  url,
  urlOnclickHandler,
  buttonOnclickHandler,
  dismissible,
  ...props
}) => {
  return (
    <div className={styles.notificationContainer}>
      <div className={styles['frame']}>
        <div className={styles['icon-container']}>
          <Icon iconName="alertTriangle" />
        </div>
        <div className={styles['title-text']}>{titleText}</div>
        <div className={styles['description-text']}>{descriptionText}</div>
        <Link to={url} onClick={urlOnclickHandler}>
          {urlText}
        </Link>
      </div>
      {dismissible && (
        <div className={styles['close-icon-container']}>
          <button onClick={buttonOnclickHandler} className={styles['button-collapse']}>
            <Icon iconName="circleXBlack" />
          </button>
        </div>
      )}
    </div>
  );
};

export default Notification;
