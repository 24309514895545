import React from 'react';
import { Container } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import ReactGA from 'react-ga4';
import { overlayContentKeys, setContentKey, setOpen } from '../../redux/overlaySlice';
import './Footer.css';

const Footer = () => {
  const dispatch = useDispatch();

  const appVersion = process.env.REACT_APP_CLIENT_VERSION;

  const changeTextOverlay = (key) => {
    dispatch(setContentKey(key));
    dispatch(setOpen(true));
  };

  return (
    <footer className="footer">
      <Container id="Footer">
        <ul className="footerMenu">
          <li className="footerMenuItem">
            <span
              onClick={() => {
                ReactGA.event({
                  category: 'Other',
                  action: 'Privacy_Policy_Click',
                });
                changeTextOverlay(overlayContentKeys.PrivacyPolicy);
              }}
              onKeyPress={(event) => {
                if (event.key === 'Enter') {
                  ReactGA.event({
                    category: 'Other',
                    action: 'Privacy_Policy_Click',
                  });
                  changeTextOverlay(overlayContentKeys.PrivacyPolicy);
                }
              }}
              className="linkBlack"
              role="menuitem"
              data-contenttype="Footer"
              data-contentparent="Legal"
              data-linktext="Privacy policy"
              tabIndex="0"
            >
              Privacy policy
            </span>
          </li>
          <li className="footerMenuItem">
            <span
              onClick={() => {
                ReactGA.event({
                  category: 'Other',
                  action: 'Cookies_Click',
                });
                changeTextOverlay(overlayContentKeys.CookiePolicy);
              }}
              onKeyPress={(event) => {
                if (event.key === 'Enter') {
                  ReactGA.event({
                    category: 'Other',
                    action: 'Cookies_Click',
                  });
                  changeTextOverlay(overlayContentKeys.CookiePolicy);
                }
              }}
              className="linkBlack"
              role="menuitem"
              data-contenttype="Footer"
              data-contentparent="Legal"
              data-linktext="Cookies"
              tabIndex="0"
            >
              Cookies
            </span>
          </li>
          <li className="footerMenuItem">
            <a
              href="https://assets.ctfassets.net/jjne31qgt3xo/7lufZPgMRrib1Zo9YO79QQ/cc7a1ac36d522a282bdb9943ca7efb82/P5018.1-V1-FINAL_MODERN_SLAVERY_AND_HUMAN_TRAFFICKING_STATEMENT.pdf"
              className="linkBlack"
              role="menuitem"
              data-contenttype="Footer"
              data-contentparent="Legal"
              data-linktext="Modern slavery statement"
              onClick={() =>
                ReactGA.event({
                  category: 'Other',
                  action: 'Modern_Slavery_Statement_Click',
                })
              }
            >
              Modern slavery statement
            </a>
          </li>
          <li className="footerMenuItem">
            <span
              onClick={() => {
                ReactGA.event({
                  category: 'Other',
                  action: 'Terms_Conditions',
                });
                changeTextOverlay(overlayContentKeys.TandC);
              }}
              onKeyPress={(event) => {
                if (event.key === 'Enter') {
                  ReactGA.event({
                    category: 'Other',
                    action: 'Terms_Conditions',
                  });
                  changeTextOverlay(overlayContentKeys.TandC);
                }
              }}
              className="linkBlack"
              role="menuitem"
              data-contenttype="Footer"
              data-contentparent="Legal"
              data-linktext="Terms and Conditions"
              tabIndex="0"
            >
              Terms and Conditions
            </span>
          </li>
          {appVersion && (
            <li className="footerMenuItem">
              <p className="footerText">App Version: {appVersion}</p>
            </li>
          )}
        </ul>
      </Container>
    </footer>
  );
};

export default Footer;
