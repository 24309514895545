export type IconName =
  | 'attention'
  | 'warning'
  | 'settings'
  | 'settingsGreen'
  | 'cross'
  | 'questionMark'
  | 'refresh'
  | 'deliveryTruck'
  | 'pill'
  | 'pillGreen'
  | 'tick'
  | 'printer'
  | 'printerDisabled'
  | 'notificationEmpty'
  | 'notificationFilled'
  | 'informationEmpty'
  | 'informationFilled'
  | 'chevronRight'
  | 'chevronRightTeal'
  | 'chevronsRight'
  | 'chevronLeft'
  | 'chevronsLeft'
  | 'chevronUp'
  | 'chevronDown'
  | 'tealChevronDown'
  | 'help'
  | 'checked'
  | 'unchecked'
  | 'passwordShow'
  | 'passwordHide'
  | 'bell'
  | 'bellOff'
  | 'infoCircle'
  | 'alertCircle'
  | 'radioButtonFilled'
  | 'circleXGreen'
  | 'circleXBlack'
  | 'radioButtonEmpty'
  | 'van'
  | 'vanGreen'
  | 'buildingHospital'
  | 'buildingHospitalOrange'
  | 'caretDown'
  | 'calendarEvent'
  | 'calendarEventGreen'
  | 'externalLink'
  | 'patients'
  | 'orders'
  | 'chartBar'
  | 'chartBarGreen'
  | 'alertTriangle'
  | 'arrowsMaximize'
  | 'arrowsMinimize'
  | 'gp'
  | 'clock-with-pill'
  | 'alertCircleOrange'
  | 'alertTriangleRed'
  | 'link'
  | 'filter';

interface IconProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  iconName: IconName;
}

const IconMap = {
  attention: require('../../../assets/icons/attention.svg').default,
  warning: require('../../../assets/icons/warning.svg').default,
  settings: require('../../../assets/icons/settings.svg').default,
  cross: require('../../../assets/icons/close.svg').default,
  questionMark: require('../../../assets/icons/question-circle.svg').default,
  refresh: require('../../../assets/icons/refresh.svg').default,
  deliveryTruck: require('../../../assets/icons/truck.svg').default,
  pill: require('../../../assets/icons/pill.svg').default,
  tick: require('../../../assets/icons/check-circle-green.svg').default,
  printer: require('../../../assets/icons/printer.svg').default,
  printerDisabled: require('../../../assets/icons/printer-disabled.svg').default,
  notificationEmpty: require('../../../assets/icons/notification-empty.svg').default,
  notificationFilled: require('../../../assets/icons/notification-filled.svg').default,
  informationEmpty: require('../../../assets/icons/information-empty.svg').default,
  informationFilled: require('../../../assets/icons/information-filled.svg').default,
  chevronRight: require('../../../assets/icons/chevron-right.svg').default,
  chevronRightTeal: require('../../../assets/icons/chevron-right-teal.svg').default,
  chevronsRight: require('../../../assets/icons/chevrons-right.svg').default,
  chevronLeft: require('../../../assets/icons/chevron-left.svg').default,
  chevronsLeft: require('../../../assets/icons/chevrons-left.svg').default,
  chevronUp: require('../../../assets/icons/chevron-up.svg').default,
  chevronDown: require('../../../assets/icons/chevron-down.svg').default,
  tealChevronDown: require('../../../assets/icons/teal-chevron-down.svg').default,
  help: require('../../../assets/icons/help.svg').default,
  checked: require('../../../assets/icons/checkbox-ticked.svg').default,
  unchecked: require('../../../assets/icons/checkbox-empty.svg').default,
  passwordShow: require('../../../assets/icons/show.svg').default,
  passwordHide: require('../../../assets/icons/hide.svg').default,
  radioButtonFilled: require('../../../assets/icons/radio-button-checked.svg').default,
  radioButtonEmpty: require('../../../assets/icons/radio-button-empty.svg').default,
  pillGreen: require('../../../assets/icons/pillGreen.svg').default,
  settingsGreen: require('../../../assets/icons/settingsGreen.svg').default,
  bell: require('../../../assets/icons/bell.svg').default,
  bellOff: require('../../../assets/icons/bell-off.svg').default,
  infoCircle: require('../../../assets/icons/info-circle.svg').default,
  circleXGreen: require('../../../assets/icons/circle-x.svg').default,
  circleXBlack: require('../../../assets/icons/circle-x-black.svg').default,
  alertCircle: require('../../../assets/icons/alert-circle.svg').default,
  van: require('../../../assets/icons/van.svg').default,
  vanGreen: require('../../../assets/icons/van-green.svg').default,
  buildingHospital: require('../../../assets/icons/building-hospital.svg').default,
  buildingHospitalOrange: require('../../../assets/icons/building-hospital-orange.svg').default,
  caretDown: require('../../../assets/icons/caret-down.svg').default,
  calendarEvent: require('../../../assets/icons/calendar-event.svg').default,
  calendarEventGreen: require('../../../assets/icons/calendar-event-green.svg').default,
  externalLink: require('../../../assets/icons/external-link.svg').default,
  patients: require('../../../assets/icons/patients.svg').default,
  orders: require('../../../assets/icons/orders.svg').default,
  chartBar: require('../../../assets/icons/chart-bar.svg').default,
  chartBarGreen: require('../../../assets/icons/chart-bar-green.svg').default,
  alertTriangle: require('../../../assets/icons/alert-triangle.svg').default,
  arrowsMaximize: require('../../../assets/icons/arrows-maximize.svg').default,
  arrowsMinimize: require('../../../assets/icons/arrows-minimize.svg').default,
  gp: require('../../../assets/icons/gp.svg').default,
  'clock-with-pill': require('../../../assets/icons/clock-with-pill.svg').default,
  alertCircleOrange: require('../../../assets/icons/alert-circle-orange.svg').default,
  alertTriangleRed: require('../../../assets/icons/alert-triangle-red.svg').default,
  link: require('../../../assets/icons/link.svg').default,
  filter: require('../../../assets/icons/filter.svg').default,
};

const Icon: React.FunctionComponent<IconProps> = ({ iconName, ...props }) => {
  return <img {...props} alt={`icon ${iconName}`} src={IconMap[iconName]} />;
};

export default Icon;
