import React, { useEffect, useRef, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { overlayContentKeySelector, setOpen } from '../../redux/overlaySlice';
import CookiePolicy from '../CookiePolicy/CookiePolicy';
import PrivacyPolicy from '../PrivacyPolicy/PrivacyPolicy';
import TandC from '../TandC/TandC';
import './TextOverlay.css';

const TextOverlay = () => {
  const [containerRefCurrent, setContainerRefCurrent] = useState(null);
  const dispatch = useDispatch();
  const containerRef = useRef(containerRefCurrent);
  const overlayContentKey = useSelector(overlayContentKeySelector);

  const overlayContent = {
    PrivacyPolicy: <PrivacyPolicy />,
    TandC: <TandC />,
    CookiePolicy: <CookiePolicy />,
  };

  useEffect(() => {
    containerRef.current = containerRefCurrent;
    if (containerRef.current) {
      containerRef.current.focus();
    }
  }, [containerRefCurrent]);

  const closeWindow = () => dispatch(setOpen(false));

  return (
    <div className="overlayBackground" onClick={() => closeWindow()}>
      <Container
        id="OverlayDetails"
        data-testid="overlay-details"
        className="overlayContainer"
        onClick={(e) => e.stopPropagation()}
        tabIndex="0"
      >
        <div
          className="closeButton"
          onClick={() => closeWindow()}
          ref={(el) => {
            setContainerRefCurrent(el);
            containerRef.current = el;
          }}
          data-testid="overlay-close"
          tabIndex="0"
          onKeyPress={(event) => {
            if (event.key === 'Enter' && event.target.className === 'closeButton') {
              event.stopPropagation();
              closeWindow();
            }
          }}
        >
          ×
        </div>
        <div className="overlayContent">{overlayContent[overlayContentKey]}</div>
      </Container>
    </div>
  );
};

export default TextOverlay;
