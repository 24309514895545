import { Col, Container, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { ReactComponent as SadFaceIcon } from '../../assets/sad-face.svg';
import { Button } from '../../Components/Atoms';
import { ROUTES } from '../../constants';
import './NotFoundPage.css';

const NotFoundPage = () => {
  const history = useHistory();
  return (
    <Container id="NotFoundPage" className="not-found-container">
      <Row className="justify-content-md-center mt-3">
        <Col className="text-center">
          <h1>Oops…</h1>
          <SadFaceIcon className="not-found-face-icon" />
          <p>
            The page you are looking for doesn’t exist. <br />
            Please double check the URL in the address bar and try again.
          </p>
          <p className="not-found-sub-text">
            If the problem persists, please contact members@heypharmacist.co.uk
          </p>
          <Button variant="secondary" onClick={() => history.push(ROUTES.ORDERS)}>
            Back to Home
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default NotFoundPage;
