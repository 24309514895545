import React, { ReactNode, useState } from 'react';
import { Icon } from '../..';
import { IconName } from '../../Icon/Icon';
import styles from './Dropdown.module.scss';
import {
  Dropdown as BootstrapDropdown,
  DropdownProps as BootstrapDropdownProps,
} from 'react-bootstrap';
import { SelectCallback } from 'react-bootstrap/esm/helpers';

interface DropdownProps extends BootstrapDropdownProps {
  label?: string | ReactNode;
  options: Array<string>;
  optionsDisplay?: Array<string>;
  value: string;
  icons?: Array<IconName>;
  errorText?: string;
  disabled?: boolean;
  onChange: SelectCallback;
}

const Dropdown: React.FunctionComponent<DropdownProps> = React.forwardRef(
  (
    {
      label,
      className,
      options,
      optionsDisplay,
      value,
      icons,
      errorText,
      disabled,
      onChange,
      ...props
    },
    ref
  ) => {
    const [show, setShow] = useState(false);
    return (
      <div className={[styles.container, className].join(' ')}>
        {label && <label className={styles.label}>{label}</label>}
        <BootstrapDropdown
          show={show}
          onToggle={disabled ? null : () => setShow(!show)}
          onSelect={onChange}
          className={[styles.dropdown, show && styles.show, 'd-flex'].join(' ')}
        >
          <BootstrapDropdown.Toggle
            className={[
              styles.button,
              disabled && styles['button--disabled'],
              errorText && styles['button--error'],
            ].join(' ')}
          >
            {value}
            <Icon className={styles.chevron} iconName={show ? 'chevronUp' : 'tealChevronDown'} />
          </BootstrapDropdown.Toggle>
          <BootstrapDropdown.Menu className={styles.menu}>
            {options.map((option, key) => {
              return (
                <BootstrapDropdown.Item className={styles.item} key={key} eventKey={key}>
                  {icons && <Icon className={styles.icon} iconName={icons[key]} />}
                  {optionsDisplay ? optionsDisplay[key] : option}
                </BootstrapDropdown.Item>
              );
            })}
          </BootstrapDropdown.Menu>
        </BootstrapDropdown>
        {errorText && <div className={styles['error-text']}>{errorText}</div>}
      </div>
    );
  }
);

export default Dropdown;
