import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { retrieveIdToken } from '../services/auth';

const tagOnSuccess = (tags) => (result, error) => error ? [] : tags;
const dateApiFormat = (unformattedDate) => {
  return (
    unformattedDate.getFullYear() +
    '-' +
    (unformattedDate.getMonth() + 1 < 10
      ? '0' + (unformattedDate.getMonth() + 1)
      : unformattedDate.getMonth() + 1) +
    '-' +
    (unformattedDate.getDate() < 10 ? '0' + unformattedDate.getDate() : unformattedDate.getDate())
  );
};

const pharmacySlice = createApi({
  reducerPath: 'pharmacy',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_MEMBER_API_URL,
    prepareHeaders: async (headers) => {
      const idToken = await retrieveIdToken();
      headers.set('Authorization', `Bearer ${idToken}`);
      return headers;
    },
  }),
  tagTypes: ['PharmacySettings, PharmacyReports'],
  endpoints: (builder) => ({
    getPharmacySettings: builder.query({
      query: () => `/api/pharmacies/settings`,
      providesTags: tagOnSuccess(['PharmacySettings']),
    }),
    updatePharmacySettings: builder.mutation({
      query: (updatePharmacySettingsRequest) => ({
        url: `/api/pharmacies/settings`,
        method: 'PATCH',
        body: updatePharmacySettingsRequest,
      }),
      invalidatesTags: tagOnSuccess(['PharmacySettings']),
    }),
    getPharmacyReports: builder.query({
      query: (options) => {
        let queryString = '';
        if (options?.start) {
          queryString += `?start_date=${dateApiFormat(options.start)}`;
        }
        if (options?.end) {
          queryString += queryString ? '&' : '?';
          queryString += `end_date=${dateApiFormat(options.end)}`;
        }
        if (options?.patient_type) {
          queryString += queryString ? '&' : '?';
          queryString += `patient_type=${options.patient_type}`;
        }
        return `/api/pharmacies/report${queryString}`;
      },
      providesTags: tagOnSuccess(['PharmacyReports']),
    }),
    getPharmacyMemberReports: builder.query({
      query: (options) => {
        let queryString = '';
        if (options?.start) {
          queryString += `?start_date=${dateApiFormat(options.start)}`;
        }
        if (options?.end) {
          queryString += queryString ? '&' : '?';
          queryString += `end_date=${dateApiFormat(options.end)}`;
        }
        if (options?.patient_type) {
          queryString += queryString ? '&' : '?';
          queryString += `patient_type=${options.patient_type}`;
        }
        if (options?.sort) {
          queryString += queryString ? '&' : '?';
          queryString += `sort=${options.sort}`;
        }
        if (options?.direction) {
          queryString += queryString ? '&' : '?';
          queryString += `direction=${options.direction}`;
        }
        if (options?.limit) {
          queryString += queryString ? '&' : '?';
          queryString += `limit=${options.limit}`;
        }
        if (options?.offset) {
          queryString += queryString ? '&' : '?';
          queryString += `offset=${options.offset}`;
        }
        return `/api/pharmacies/report/patients${queryString}`;
      },
      providesTags: tagOnSuccess(['PharmacyReports']),
    }),
    updatePatientChurnAlert: builder.mutation({
      query: (patient_id) => ({
        url: `/api/pharmacies/report/patients/${patient_id}/dismiss-churn-alert`,
        method: 'PATCH',
      }),
      invalidatesTags: tagOnSuccess(['PharmacyReports']),
    }),
  }),
});

export default pharmacySlice;
