import { useEffect, useRef, useState } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Icon, Input } from '../../Components/Atoms';
import LoadingIndicator from '../../Components/LoadingIndicator/LoadingIndicator';
import PasswordRequirements from '../ForgottenPasswordPage/PasswordRequirements';
import Error from '../../Components/Error/Error';
import PrivacyPolicy from '../../Components/PrivacyPolicy/PrivacyPolicy';
import TandC from '../../Components/TandC/TandC';
import { ROUTES } from '../../constants';
import { overlayContentKeys, setContentKey, setOpen } from '../../redux/overlaySlice';
import { signOut, useFirstTimeLogin } from '../../services/auth';
import validatePassword, { specialChars } from '../../helpers/passwordRequirementsValidator';
import './FirstTimeSetupPage.css';

const FirstTimeSetupPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { firstTimeUser, doPasswordReset } = useFirstTimeLogin();
  const [currentStage, setCurrentStage] = useState(0);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState([]);
  const [isError, setIsError] = useState(false);
  const [regexChecks, setRegexChecks] = useState([
    { text: 'Be at least 8 characters.', valid: false },
    { text: 'Must include at least 1 number.', valid: false },
    { text: 'Must include at least 1 upper case letter.', valid: false },
    { text: 'Must include at least 1 lower case letter.', valid: false },
    { text: `Must include at least 1 special character: ${specialChars.join(' ')}`, valid: false },
  ]);
  const scrollableRef = useRef();

  useEffect(() => {
    scrollableRef.current?.scrollTo(0, 0);
  }, [currentStage]);

  const onInputChange = (newValue) => {
    const validationErrors = validatePassword(newValue);
    validationErrors.length && setRegexChecks(validationErrors);
  };
  const onSubmit = () => {
    if (password !== confirmPassword) {
      setErrorMessage(['The passwords you have entered do not match']);
      setIsError(true);
      return;
    }

    setLoading(true);
    setErrorMessage([]);
    doPasswordReset(firstTimeUser, password)
      .then(signOut)
      .then(() => history.push(ROUTES.LOGIN))
      .catch((e) => {
        setErrorMessage([e.message]);
        setLoading(false);
        setIsError(true);
      });
  };

  const showCookiePolicy = () => {
    dispatch(setContentKey(overlayContentKeys.CookiePolicy));
    dispatch(setOpen(true));
  };

  const tcContent = (
    <div data-testid="tc-content">
      <h3>Terms &amp; Conditions</h3>
      <div className="scrollable" data-testid="tc-scrollable" ref={scrollableRef}>
        <TandC />
      </div>
      <div className="accept-container">
        <Button variant="primary" type="button" role="button" onClick={() => setCurrentStage(1)}>
          Accept <Icon iconName="chevronRight" className="chevron" />
        </Button>
      </div>
    </div>
  );

  const privacyPolicyContent = (
    <div data-testid="pp-content">
      <h3>Privacy Policy</h3>
      <div className="scrollable" data-testid="pp-scrollable" ref={scrollableRef}>
        <PrivacyPolicy />
      </div>
      <div className="accept-container">
        <Button variant="primary" type="button" role="button" onClick={() => setCurrentStage(2)}>
          Accept <Icon iconName="chevronRight" className="chevron" />
        </Button>
      </div>
    </div>
  );

  const createPasswordContent = (
    <div data-testid="cp-content">
      <h2>Create Password</h2>
      <Form>
        <Input inputName="username" type="text" autoComplete="username" hidden />
        <div className="password-requirements">
          <PasswordRequirements requirements={regexChecks} />
        </div>
        <Input
          inputName="new-password"
          label={<>New Password</>}
          className="password-input"
          type="password"
          autoComplete="new-password"
          data-testid="cp-password"
          value={password}
          onChange={(e) => {
            onInputChange(e.target.value);
            setPassword(e.target.value);
          }}
        />
        <Input
          inputName="new-password-confirm"
          label={<>Re-enter New Password</>}
          className="password-input"
          type="password"
          autoComplete="new-password"
          data-testid="cp-confirm-password"
          value={confirmPassword}
          onInput={(e) => setConfirmPassword(e.target.value)}
        />
        {isError ? (
          <div className="errors-container">
            <Error>{errorMessage}</Error>
          </div>
        ) : null}
      </Form>
      <div className="accept-container">
        <div className="accept-cookie-notice">
          We use web storage to enable this portal to work. You can find more information in our
          <button onClick={showCookiePolicy}>cookie policy</button>
        </div>
        <Button
          variant="primary"
          type="button"
          role="button"
          onClick={onSubmit}
          disabled={!firstTimeUser}
        >
          {loading ? <LoadingIndicator /> : 'Save'}
        </Button>
      </div>
    </div>
  );

  const stageContent = () => {
    switch (currentStage) {
      case 1:
        return privacyPolicyContent;
      case 2:
        return createPasswordContent;
      case 0:
      default:
        return tcContent;
    }
  };

  const stageTimelineItemClass = (itemStage) => {
    if (itemStage < currentStage) {
      return 'completed';
    } else if (itemStage === currentStage) {
      return 'active';
    } else {
      return null;
    }
  };

  return (
    <Container id="FirstTimeSetupPage">
      <Row className="justify-content-md-center mt-3">
        <Col md={8} className="first-time-setup-container">
          <h1>Set Up Account</h1>
          <hr />
          <Container>
            <Row>
              <Col md={4} className="timeline">
                <ol>
                  <li className={stageTimelineItemClass(0)}>Terms &amp; Conditions</li>
                  <li className={stageTimelineItemClass(1)}>Privacy Policy</li>
                  <li className={stageTimelineItemClass(2)}>Create Password</li>
                </ol>
              </Col>
              <Col className="content">{stageContent()}</Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  );
};

export default FirstTimeSetupPage;
