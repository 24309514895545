import { configureStore } from '@reduxjs/toolkit';
import authSlice from './authSlice';
import { requestIdMiddleWare } from './middleware';
import orderSlice from './orderSlice';
import overlaySlice from './overlaySlice';
import processingOrdersSlice from './processingOrdersSlice';
import pharmacySlice from './pharmacySlice';
import selectedOrdersSlice from './selectedOrdersSlice';
import tabSlice from './tabSlice';

export default configureStore({
  reducer: {
    tab: tabSlice.reducer,
    auth: authSlice.reducer,
    overlay: overlaySlice.reducer,
    processingOrders: processingOrdersSlice.reducer,
    selectedOrders: selectedOrdersSlice.reducer,
    [orderSlice.reducerPath]: orderSlice.reducer,
    [pharmacySlice.reducerPath]: pharmacySlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      orderSlice.middleware,
      pharmacySlice.middleware,
      requestIdMiddleWare,
    ]),
});
