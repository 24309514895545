const getBlobURL = (code, type) => {
  const blob = new Blob([code], { type });
  return URL.createObjectURL(blob);
};

const generateIframeUrl = ({ html, css }) => {
  const cssURL = getBlobURL(css, 'text/css');
  const cssLink = `<link rel="stylesheet" type="text/css" href="${cssURL}" />`;

  const source = `
    <html>
      <head>
      <link href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.0/css/bootstrap.min.css" rel="stylesheet">
        ${css && cssLink}
      </head>
      <body>
        ${html}
      </body>
    </html>
  `;
  return getBlobURL(source, 'text/html');
};

export default generateIframeUrl;
