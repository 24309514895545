import styles from './PasswordRequirements.module.css';

const PasswordRequirements = ({ requirements }) => {
  return (
    <div className={styles.container}>
      <ul>
        Passwords must:
        {requirements.map((requirement) => (
          <li key={requirement.text} className={requirement.valid ? styles.valid : styles.invalid}>
            {requirement.text}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PasswordRequirements;
