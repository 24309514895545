import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactGA from 'react-ga4';
import Error from '../../../Components/Error/Error';
import { ERROR_MESSAGES } from '../../../constants';
import { selectedOrdersSelector, setSelectOrders } from '../../../redux/selectedOrdersSlice';
import './OrderTable.css';
import DataTable from 'react-data-table-component';
import { Icon } from '../../../Components/Atoms';

const OrderTable = ({
  headings,
  results,
  callBackFunction,
  callBackField,
  showMainError,
  selectedTab,
  newProcessingOrders,
}) => {
  const dispatch = useDispatch();
  const selectedOrders = useSelector(selectedOrdersSelector);
  const [toggleClearRows, setToggleClearRows] = useState(false);

  useEffect(() => {
    selectedOrders.length < 1 && setToggleClearRows((prev) => !prev);
  }, [selectedOrders.length, setToggleClearRows]);

  const handleChange = ({ selectedRows }) => {
    dispatch(setSelectOrders(selectedRows));
  };

  const rowSelectCritera = (order) => {
    return selectedOrders.find((selectedOrder) => selectedOrder.coop_id === order.coop_id);
  };

  const customSort = (rows, selector, direction) => {
    ReactGA.event({
      category: 'Orders',
      action:
        'Sort_' +
        headings.find((heading) => heading.selector.toString() === selector.toString()).eventName,
    });
    return rows.sort((rowA, rowB) => {
      const aField = selector(rowA);
      const bField = selector(rowB);
      let comparison = 0;
      if (aField > bField) {
        comparison = 1;
      } else if (aField < bField) {
        comparison = -1;
      }
      return direction === 'desc' ? comparison * -1 : comparison;
    });
  };

  const conditionalRowStyles = [
    {
      when: (order) =>
        selectedTab === 'Processing' &&
        newProcessingOrders.some(
          (processingOrder) => processingOrder.coop_id === order.coop_id && processingOrder.isNew
        ),
      classNames: ['newOrdersRow'],
    },
  ];

  return (
    <>
      <div
        className="searchResultContainer"
        data-testid={results.length > 0 ? 'data-table-with-results' : 'data-table-no-results'}
      >
        <DataTable
          columns={headings}
          data={results}
          selectableRows
          onSelectedRowsChange={handleChange}
          selectableRowSelected={rowSelectCritera}
          onRowClicked={(row) => callBackFunction(row[callBackField])}
          sortFunction={customSort}
          highlightOnHover={true}
          sortIcon={<Icon iconName="caretDown" />}
          conditionalRowStyles={conditionalRowStyles}
          clearSelectedRows={toggleClearRows}
        />
        {showMainError && (
          <div className="searchResultError">
            <Error>{ERROR_MESSAGES.ORDER_LIST}</Error>
          </div>
        )}
      </div>
    </>
  );
};

OrderTable.propTypes = {
  headings: PropTypes.arrayOf(PropTypes.object).isRequired,
  results: PropTypes.arrayOf(PropTypes.object),
  callBackFunction: PropTypes.func,
  callBackField: PropTypes.string,
  showMainError: PropTypes.bool,
};

OrderTable.defaultProps = {
  results: [],
};

export default OrderTable;
