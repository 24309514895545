import React from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga4';
import './ReportsTable.css';
import DataTable from 'react-data-table-component';
import { Icon } from '../../../Components/Atoms';
import { calculateCurrentPage, calculateOffset } from '../../../helpers/reportsTable';
import { Card } from 'react-bootstrap';

const ReportsTable = ({
  headings,
  results,
  direction,
  limit,
  totalRows,
  queryOffset,
  setReportsQuery,
  setShowReportsTable,
  title,
  value,
  text,
  icon,
}) => {
  const customSort = (column, sortDirection) => {
    const { sortField } = column;
    setReportsQuery((prev) => {
      return {
        ...prev,
        sort: sortField,
        direction: sortDirection,
      };
    });
    ReactGA.event({
      category: 'Reports',
      action: `Reports_Sort_${column.eventName}`,
    });
  };

  const handleChangePage = (page) => {
    const offset = calculateOffset(page, limit);
    const pageMovement = queryOffset < offset ? 'Forward' : 'Back';
    ReactGA.event({
      category: 'Reports',
      action: `Reports_Pagination_${pageMovement}_Click`,
    });
    setReportsQuery((prev) => {
      return {
        ...prev,
        offset: offset,
      };
    });
  };

  const handlePerRowsChange = (newPerPage, page) => {
    ReactGA.event({
      category: 'Reports',
      action: `Reports_Rows_Per_Page_Click`,
    });
    setReportsQuery((prev) => {
      return {
        ...prev,
        limit: newPerPage,
        offset: 0,
      };
    });
  };

  return (
    <Card>
      <div className="d-flex">
        <Icon iconName={icon} className="icon" />
        <div className="ml-4">
          <p className="title-3">{title}</p>
          <h1 className="card-value">{value}</h1>
        </div>
      </div>
      <p className="text">{text}</p>
      <Icon
        iconName="arrowsMinimize"
        className="arrowsIcon"
        onClick={() => setShowReportsTable(false)}
      />
      <div
        className="searchResultContainer"
        data-testid={results.length > 0 ? 'data-table-with-results' : 'data-table-no-results'}
      >
        <DataTable
          columns={headings}
          data={results}
          sortIcon={<Icon iconName="caretDown" />}
          sortServer
          onSort={customSort}
          defaultSortAsc={direction !== 'asc'}
          pagination={totalRows > 10}
          paginationServer
          paginationDefaultPage={calculateCurrentPage(limit, queryOffset)}
          paginationPerPage={limit}
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handleChangePage}
          paginationIconFirstPage={<Icon iconName="chevronsLeft" />}
          paginationIconPrevious={<Icon iconName="chevronLeft" />}
          paginationIconNext={<Icon iconName="chevronRightTeal" />}
          paginationIconLastPage={<Icon iconName="chevronsRight" />}
        />
      </div>
    </Card>
  );
};

ReportsTable.propTypes = {
  headings: PropTypes.arrayOf(PropTypes.object).isRequired,
  results: PropTypes.arrayOf(PropTypes.object),
};

ReportsTable.defaultProps = {
  results: [],
};

export default React.memo(ReportsTable);
