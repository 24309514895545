import { useState } from 'react';
import styles from './Tooltip.module.scss';

const Tooltip = ({ children, text, ...props }) => {
  const [show, setShow] = useState(false);

  return (
    <div className={styles.container}>
      <div className={[styles.tooltip, show ? styles.visible : null].filter((s) => s).join(' ')}>
        {text}
      </div>
      <div onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)} {...props}>
        {children}
      </div>
    </div>
  );
};

export default Tooltip;
