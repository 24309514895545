import React from 'react';
import { Spinner } from 'react-bootstrap';

const LoadingIndicator = (props) => (
  <Spinner animation="border" role="status" {...props}>
    <span className="sr-only">Loading...</span>
  </Spinner>
);

export default LoadingIndicator;
