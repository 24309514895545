let currentDate = new Date();
currentDate.setUTCHours(0, 0, 0, 0);
const millisecondsInOneDay = 24 * 60 * 60 * 1000;

const dateOptions = [
  { display: 'All data', start: null, end: currentDate, event: 'All_Data_Click' },
  {
    display: 'Last 7 days',
    start: new Date(Date.now() - 7 * millisecondsInOneDay),
    end: currentDate,
    event: 'Last_7_Days_Click',
  },
  {
    display: 'Last 14 days',
    start: new Date(Date.now() - 14 * millisecondsInOneDay),
    end: currentDate,
    event: 'Last_14_Days_Click',
  },
  {
    display: 'Last 30 days',
    start: new Date(Date.now() - 30 * millisecondsInOneDay),
    end: currentDate,
    event: 'Last_30_Days_Click',
  },
  {
    display: '+ Custom Range',
    start: null,
    end: null,
    datePicker: true,
    event: 'Custom_Range_Click',
  },
];

const filterOptions = {
  all_patients: {
    name: 'all_patients',
    display: 'All patients',
    description:
      "Showing the totals for both 'Your patients' who signed up via QR code and 'Marketing patients' who signed up online",
    event: {
      click: 'Reports_All_Patients_Click',
      view: 'Reports_All_Patients_View',
      listViewClick: 'Reports_All_Patients_ListView_Click',
      listViewView: 'Reports_All_Patients_ListView_View',
    },
  },
  your_patients: {
    name: 'your_patients',
    display: 'Your patients',
    description: "Showing the numbers for 'Your patients' who signed up via QR code",
    event: {
      click: 'Reports_Your_Patients_Click',
      view: 'Reports_Your_Patients_View',
      listViewClick: 'Reports_Your_Patients_ListView_Click',
      listViewView: 'Reports_Your_Patients_ListView_View',
    },
  },
  marketing_patients: {
    name: 'marketing_patients',
    display: 'Marketing patients',
    description:
      "Showing the numbers for 'Marketing patients' who signed up online and selected your pharmacy",
    event: {
      click: 'Reports_Marketing_Patients_Click',
      view: 'Reports_Marketing_Patients_View',
      listViewClick: 'Reports_Marketing_Patients_ListView_Click',
      listViewView: 'Reports_Marketing_Patients_ListView_View',
    },
  },
};

const card = {
  patients: {
    all_patients: {
      title: 'Total patients',
      text: 'Patients who have selected your pharmacy to dispense their prescription',
      icon: 'patients',
    },
    your_patients: {
      title: 'Your patients',
      text: 'Patients who have signed up to your pharmacy via QR code',
      icon: 'patients',
    },
    marketing_patients: {
      title: 'Marketing patients',
      text: 'Patients who have selected your pharmacy online',
      icon: 'patients',
    },
  },
  orders: {
    name: 'orders',
    display: 'Orders created',
    title: 'Orders created',
    text: 'All orders that were sent via Hey Pharmacist to the Portal',
    icon: 'orders',
  },
  gp_turnaround: {
    name: 'gp_turnaround',
    display: 'GP turnaround',
    title: 'GP turnaround',
    text: "Average time it has taken for GP's to approve prescriptions (in hours)",
    icon: 'gp',
  },
  dispensing_process: {
    name: 'dispensing_process',
    display: 'Dispensing process',
    title: 'Dispensing process',
    text: "Average time it has taken for orders to go from 'prescription issued' to 'completed'",
    icon: 'clock-with-pill',
  },
};

const ReportsPageOptions = {
  dateOptions,
  filterOptions,
  card,
};

export default ReportsPageOptions;
