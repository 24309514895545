import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Link, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { ReactComponent as HPLogo } from '../../assets/hp-logo.svg';
import { ROUTES } from '../../constants';
import { selectAuthAuthenticated } from '../../redux/authSlice';
import { signOut } from '../../services/auth';
import { Button, Icon } from '../../Components/Atoms';
import './HeaderBar.css';

const settingsEnabled = process.env.REACT_APP_ENABLE_SETTINGS === 'true';
const servicesEnabled = process.env.REACT_APP_ENABLE_SERVICES === 'true';

const HeaderBar = () => {
  const history = useHistory();
  const location = useLocation();
  const loggedIn = useSelector(selectAuthAuthenticated);
  const newProcessingOrdersCount = useSelector((state) => state.processingOrders.newOrderCount);

  const handleSignOut = () => {
    ReactGA.event({
      category: 'Login',
      action: 'Logout',
    });
    signOut().then(() => history.push(ROUTES.LOGIN));
  };
  return (
    <nav className="headerBar">
      <div className="navbarInnerLeftContainer">
        <Link to={ROUTES.ORDERS}>
          <HPLogo className="logoImage" />
        </Link>
        {loggedIn && (
          <>
            <Link
              onClick={() =>
                ReactGA.event({
                  category: 'Orders',
                  action: 'Orders_Click',
                })
              }
              to={ROUTES.ORDERS}
              className={`headerLink ${location.pathname === ROUTES.ORDERS ? 'currentPage' : ''}`}
            >
              <div>
                <Icon
                  iconName="pill"
                  className={`pillIcon ${location.pathname === ROUTES.ORDERS ? 'currentPage' : ''}`}
                />
                <Icon
                  iconName="pillGreen"
                  className={`pillGreenIcon ${
                    location.pathname === ROUTES.ORDERS ? 'currentPage' : ''
                  }`}
                />
                Orders{newProcessingOrdersCount ? ' (' + newProcessingOrdersCount + ' new)' : ''}
              </div>
            </Link>
            {servicesEnabled ? (
              <Link
                onClick={() =>
                  ReactGA.event({
                    category: 'Services',
                    action: 'Services_Click',
                  })
                }
                to={ROUTES.SERVICES}
                className={`headerLink ${
                  location.pathname === ROUTES.SERVICES ? 'currentPage' : ''
                }`}
              >
                <div>
                  <Icon
                    iconName="calendarEvent"
                    className={`servicesIcon ${
                      location.pathname === ROUTES.SERVICES ? 'currentPage' : ''
                    }`}
                  />
                  <Icon
                    iconName="calendarEventGreen"
                    className={`servicesGreenIcon ${
                      location.pathname === ROUTES.SERVICES ? 'currentPage' : ''
                    }`}
                  />
                  Services
                </div>
              </Link>
            ) : null}
            <Link
              onClick={() =>
                ReactGA.event({
                  category: 'Reports',
                  action: 'Reports_Click',
                })
              }
              to={ROUTES.REPORTS}
              className={`headerLink ${location.pathname === ROUTES.REPORTS ? 'currentPage' : ''}`}
            >
              <div>
                <Icon
                  iconName="chartBar"
                  className={`reportsIcon ${
                    location.pathname === ROUTES.REPORTS ? 'currentPage' : ''
                  }`}
                />
                <Icon
                  iconName="chartBarGreen"
                  className={`reportsGreenIcon ${
                    location.pathname === ROUTES.REPORTS ? 'currentPage' : ''
                  }`}
                />
                Reports
              </div>
            </Link>
            {settingsEnabled ? (
              <Link
                onClick={() =>
                  ReactGA.event({
                    category: 'Settings',
                    action: 'Settings_Click',
                  })
                }
                to={ROUTES.SETTINGS}
                className={`headerLink ${
                  location.pathname === ROUTES.SETTINGS ? 'currentPage' : ''
                }`}
              >
                <div>
                  <Icon
                    iconName="settings"
                    className={`settingsIcon ${
                      location.pathname === ROUTES.SETTINGS ? 'currentPage' : ''
                    }`}
                  />
                  <Icon
                    iconName="settingsGreen"
                    className={`settingsGreenIcon ${
                      location.pathname === ROUTES.SETTINGS ? 'currentPage' : ''
                    }`}
                  />
                  Settings
                </div>
              </Link>
            ) : null}
          </>
        )}
      </div>
      <div className="navbarInnerRightContainer">
        <a
          href="https://intercom-help.eu/numark/en/collections/288987-hey-pharmacist-portal"
          target="_blank"
          rel="noreferrer"
          className="helpAndSupportContainer"
          onClick={() =>
            ReactGA.event({
              category: 'Other',
              action: 'Help_Support_Click',
            })
          }
        >
          <Icon iconName="help" className="helpAndSupportIcon" />
          <div className="helpAndSupport">Help & Support</div>
        </a>
        {loggedIn && (
          <div className="logOutLink">
            <Button variant="secondary" className="logoutButton" onClick={handleSignOut}>
              Log out
            </Button>
          </div>
        )}
      </div>
    </nav>
  );
};

export default HeaderBar;
