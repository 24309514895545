import React from 'react';
import { COGNITO_AUTH_FAIL_REASONS, COGNITO_AUTH_RESULTS } from '../constants';
import { setAuthenticated } from '../redux/authSlice';
import store from '../redux/store';
import {
  authenticateUser,
  completeFirstLoginPasswordSet,
  getCurrentUser,
  getSession,
  globalSignOut,
  refreshSession,
  requestVerificationCodeForgottenPassword,
  sendCustomChallengeAnswer,
  updateForgottenPassword,
} from './cognitoLib';

export const AuthContext = React.createContext({
  firstTimeUser: null,
  setFirstTimeUser: () => {
    //This is intentional
  },
});

export const AuthProvider = ({ children }) => {
  const [firstTimeUser, setFirstTimeUser] = React.useState(null);

  return (
    <AuthContext.Provider value={{ firstTimeUser, setFirstTimeUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useFirstTimeLogin = () => {
  const { firstTimeUser, setFirstTimeUser } = React.useContext(AuthContext);

  return { firstTimeUser, setFirstTimeUser, doPasswordReset: completeFirstLoginPasswordSet };
};

export const sendAuthenticationVerificationCode = (verificationCodeSessionData, verificationCode) =>
  sendCustomChallengeAnswer(verificationCodeSessionData, verificationCode)
    .then((res) => {
      if (res.result === COGNITO_AUTH_RESULTS.SUCCESS) {
        store.dispatch(setAuthenticated(true));
      }

      return res;
    })
    .catch((e) => {
      switch (e.message) {
        case 'Invalid session for the user.':
          throw new Error(COGNITO_AUTH_FAIL_REASONS.MFA_EXPIRED_CODE);
        case 'Incorrect username or password.':
          throw new Error(COGNITO_AUTH_FAIL_REASONS.MFA_MAX_ATTEMPTS_EXCEEDED);
        default:
          throw new Error();
      }
    });

export const requestForgottenPasswordVerificationCode = (username) =>
  requestVerificationCodeForgottenPassword(username);

export const changeForgottenPassword = (username, verificationCode, newPassword) =>
  updateForgottenPassword(username, verificationCode, newPassword);

export const signIn = (username, password) =>
  authenticateUser(username, password).then((res) => {
    if (res.result === COGNITO_AUTH_RESULTS.SUCCESS) {
      store.dispatch(setAuthenticated(true));
    }

    return res;
  });

export const signOut = async () => {
  const cognitoUser = getCurrentUser();

  if (!cognitoUser) {
    return Promise.reject('No authenticated user');
  }

  cognitoUser.setSignInUserSession(await getCognitoSession());

  return globalSignOut(cognitoUser).then((msg) => {
    store.dispatch(setAuthenticated(false));
    return msg;
  });
};

const getCognitoSession = () => {
  const cognitoUser = getCurrentUser();

  if (!cognitoUser) {
    return Promise.reject('No authenticated user');
  }

  return getSession(cognitoUser);
};

const handleExpiredTokens = async () => {
  const cognitoUser = getCurrentUser();

  if (!cognitoUser) {
    return Promise.reject('No authenticated user');
  }

  const session = await getCognitoSession();

  if (session && session.getIdToken().getExpiration() * 1000 < Date.now()) {
    return refreshSession(cognitoUser, session.getRefreshToken());
  }

  return Promise.resolve(session);
};

export const refreshUserTokens = async () => {
  const cognitoUser = getCurrentUser();

  if (!cognitoUser) {
    return Promise.reject('No authenticated user');
  }

  const session = await getCognitoSession();

  if (!session.isValid()) {
    return Promise.reject('Session invalid');
  }

  return refreshSession(cognitoUser, session.getRefreshToken());
};

export const isSessionValid = () =>
  handleExpiredTokens()
    .then((session) => session.isValid())
    .catch(() => false);

export const retrieveIdToken = () =>
  isSessionValid()
    .then((valid) => {
      if (valid) {
        return getCognitoSession();
      } else {
        store.dispatch(setAuthenticated(false));
        return Promise.reject('Session invalid');
      }
    })
    .then((session) => session.getIdToken().getJwtToken());
