import React from 'react';
import { Card } from 'react-bootstrap';
import Error from '../../Components/Error/Error';
import LoadingWrapper from '../../Components/LoadingWrapper/LoadingWrapper';
import pharmacySlice from '../../redux/pharmacySlice';
import ServicesContent from './ServicesContent/ServicesContent';
import './ServicesPage.css';
import PageWrapper from '../../Components/PageWrapper/PageWrapper';

const ServicesPage = () => {
  const pharmacySettingsQuery = pharmacySlice.endpoints.getPharmacySettings.useQuery();
  const [updatePharmacySettings, updatePharmacySettingsMutation] =
    pharmacySlice.endpoints.updatePharmacySettings.useMutation();

  return (
    <PageWrapper id="ServicesPage" className="servicesContainer" tabIndex="0">
      <LoadingWrapper isLoading={pharmacySettingsQuery.isFetching}>
        <div className="servicesContent">
          {pharmacySettingsQuery.currentData &&
          !updatePharmacySettingsMutation.isError &&
          !pharmacySettingsQuery.isError ? (
            <>
              <ServicesContent
                pharmacySettingsData={pharmacySettingsQuery.currentData}
                updatePharmacySettings={updatePharmacySettings}
              />
            </>
          ) : (
            <Card>
              <Error />
            </Card>
          )}
        </div>
      </LoadingWrapper>
    </PageWrapper>
  );
};

export default ServicesPage;
