import { createSlice } from '@reduxjs/toolkit';

const authSlice = createSlice({
  name: 'auth',
  initialState: { authenticated: false, loaded: false },
  reducers: {
    setAuthenticated: (state, action) => {
      state.authenticated = action.payload;
    },
    setLoaded: (state, action) => {
      state.loaded = action.payload;
    },
  },
});

export const { setAuthenticated, setLoaded } = authSlice.actions;

export const selectAuthAuthenticated = (state) => state.auth.authenticated;

export const selectAuthLoaded = (state) => state.auth.loaded;

export default authSlice;
