import addressShortFormatter from '../../../helpers/addressShortFormatter';
import dateFormatter from '../../../helpers/dateFormatter';
import nhsNumberFormatter from '../../../helpers/nhsNumberFormatter';
import TableCell from './TableCell/TableCell';

export const headings = (patient_type) => {
  return [
    {
      id: 'patient_name',
      name: 'Patient Name',
      selector: (report) =>
        report?.first_name && report?.last_name ? `${report.last_name} ${report.first_name}` : null,
      sortable: true,
      sortField: 'last_name',
      eventName: 'Patient_Name',
      grow: 2,
    },
    {
      id: 'address',
      name: 'Address',
      selector: (report) => (report?.address ? addressShortFormatter(report.address) : null),
      sortable: false,
      eventName: 'Address',
      grow: 2,
    },
    {
      id: 'nhs_number',
      name: 'NHS Number',
      selector: (report) => (report?.nhs_number ? nhsNumberFormatter(report.nhs_number) : null),
      sortable: false,
      eventName: 'NHS_Number',
      grow: 2,
    },
    {
      id: 'contact_number',
      sortable: false,
      eventName: 'Contact No',
      name: 'Contact Number',
      omit: patient_type !== 'your_patients',
      grow: 2,
      cell: (row) => {
        return <TableCell.ContactNo data={row} />;
      },
    },
    {
      id: 'date_registered',
      name: 'Date Registered',
      selector: (report) =>
        report?.registration_date ? dateFormatter(report.registration_date) : null,
      sortable: true,
      sortField: 'registration_date',
      eventName: 'Date_Registered',
      grow: 2,
    },
    {
      id: 'last_order_date',
      name: 'Last Order Date',
      sortable: true,
      sortField: 'last_order_date',
      eventName: 'Last_Order_Date',
      grow: 2,
      cell: (row) => {
        if (patient_type === 'your_patients') {
          return <TableCell.LastOrderDate data={row} />;
        } else {
          return <>{dateFormatter(row?.last_order_date) || 'None'}</>;
        }
      },
    },
  ];
};
