import React, { useRef, useState } from 'react';
import { Icon } from '../../../../../Components/Atoms';
import dateFormatter from '../../../../../helpers/dateFormatter';
import './LastOrderDate.css';
import '../TableCell.css';
import pharmacySlice from '../../../../../redux/pharmacySlice';
import { REPORTS_PAGE } from '../../../../../constants';
import { numberOfMonthsBetween } from '../../../../../helpers/reportsTable';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import ReactGA from 'react-ga4';

const LastOrderDate = (props) => {
  const [updatePatientChurnAlert] = pharmacySlice.endpoints.updatePatientChurnAlert.useMutation();
  const { churn_alert_level, last_order_date, patient_id } = props.data;
  const [alertLevel, setAlertLevel] = useState(churn_alert_level || 0);
  const [show, setShow] = useState(false);
  const monthsAgo = Math.floor(numberOfMonthsBetween(new Date(), last_order_date), 1);

  const icon = REPORTS_PAGE.CHURN_ALERT_LEVEL[alertLevel].ICON;
  const warning = REPORTS_PAGE.CHURN_ALERT_LEVEL[alertLevel].WARNING || '';

  const handleOnMouseEnter = (sendGAEvent) => {
    setShow(true);
    if (sendGAEvent) {
      ReactGA.event({
        category: 'Reports',
        action: REPORTS_PAGE.CHURN_ALERT_LEVEL[alertLevel].GA.HOVER,
      });
    }
  };

  const handleOnMouseLeave = () => {
    setShow(false);
  };

  const handleDismiss = () => {
    setShow(false);
    ReactGA.event({
      category: 'Reports',
      action: REPORTS_PAGE.CHURN_ALERT_LEVEL[alertLevel].GA.CLICK,
    });
    setAlertLevel(0);
    updatePatientChurnAlert(patient_id);
  };

  const ref = useRef(null);

  if (alertLevel === 0) {
    return (
      <div className={`tableCell`}>
        <p>{last_order_date ? dateFormatter(last_order_date) : 'None'}</p>
      </div>
    );
  }

  return (
    <OverlayTrigger
      show={show}
      key="bottom"
      placement="bottom"
      container={ref}
      overlay={
        <Tooltip
          id="reportsTooltip"
          onMouseEnter={() => handleOnMouseEnter(false)}
          onMouseLeave={handleOnMouseLeave}
        >
          <p>It has been over {monthsAgo} months since this patient last made an order.</p>
          <Button onClick={handleDismiss}>Remove Reminder</Button>
        </Tooltip>
      }
    >
      <div
        ref={ref}
        onMouseEnter={() => handleOnMouseEnter(true)}
        onMouseLeave={handleOnMouseLeave}
        className={`tableCell ${warning}`}
        data-testid={`last-order-date-${warning}-warning`}
      >
        <p>{last_order_date ? dateFormatter(last_order_date) : 'None'}</p>
        <Icon iconName={icon} />
      </div>
    </OverlayTrigger>
  );
};

export default LastOrderDate;
