import React, { useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import ReactGA from 'react-ga4';
import Error from '../../Components/Error/Error';
import LoadingWrapper from '../../Components/LoadingWrapper/LoadingWrapper';
import pharmacySlice from '../../redux/pharmacySlice';
import './SettingsPage.css';
import TabContent from './TabContent/TabContent';
import SettingOverlay from './SettingOverlay/SettingOverlay';
import { GOOGLE_ANALYTICS_CATEGORY, SETTING_TYPES } from '../../constants';
import PageWrapper from '../../Components/PageWrapper/PageWrapper';

const SettingsPage = () => {
  const pharmacySettingsQuery = pharmacySlice.endpoints.getPharmacySettings.useQuery();
  const [updatePharmacySettings, updatePharmacySettingsMutation] =
    pharmacySlice.endpoints.updatePharmacySettings.useMutation();

  const [overlayOpen, setOverlayOpen] = useState(false);

  const [currentSettingsTab, setCurrentSettingsTab] = useState(SETTING_TYPES.OrderUpdates);

  return (
    <PageWrapper id="SettingsPage" tabIndex="0">
      <LoadingWrapper isLoading={pharmacySettingsQuery.isFetching}>
        <div className="settingsContent">
          {pharmacySettingsQuery.currentData &&
          !updatePharmacySettingsMutation.isError &&
          !pharmacySettingsQuery.isError ? (
            <>
              {overlayOpen && (
                <SettingOverlay
                  currentSettingsTab={currentSettingsTab}
                  setOverlayOpen={setOverlayOpen}
                  pharmacySettings={pharmacySettingsQuery.currentData.pharmacy_settings}
                  updatePharmacySettings={updatePharmacySettings}
                />
              )}
              <Row>
                <Card className="headerCard">
                  <h1>Settings</h1>
                </Card>
              </Row>
              <Row>
                <Col md={4} className="settingsColumn">
                  <Card>
                    <h2>Collection</h2>
                    <hr />
                    <Row
                      onClick={() => {
                        ReactGA.event({
                          category: GOOGLE_ANALYTICS_CATEGORY.SETTINGS,
                          action: 'Settings_Order_Update_View',
                        });
                        setCurrentSettingsTab(SETTING_TYPES.OrderUpdates);
                      }}
                      className={`settingsOptionContainer ${
                        currentSettingsTab === SETTING_TYPES.OrderUpdates ? 'active' : ''
                      }`}
                      data-testid="order-updates-tab"
                    >
                      <h3 className="sub-heading-3">Order updates</h3>
                      <p className="title-4">
                        {pharmacySettingsQuery.currentData.pharmacy_settings
                          .order_notifications_enabled
                          ? 'ON'
                          : 'OFF'}
                      </p>
                    </Row>
                    <p></p>
                    <h2>Delivery</h2>
                    <hr />
                    <Row
                      onClick={() => {
                        ReactGA.event({
                          category: GOOGLE_ANALYTICS_CATEGORY.SETTINGS,
                          action: 'Settings_Your_Patients_Delivery_Views',
                        });
                        setCurrentSettingsTab(SETTING_TYPES.InStoreDelivery);
                      }}
                      className={`settingsOptionContainer ${
                        currentSettingsTab === SETTING_TYPES.InStoreDelivery ? 'active' : ''
                      }`}
                      data-testid="your-patients-tab"
                    >
                      <h3 className="sub-heading-3">Your patients</h3>
                      {pharmacySettingsQuery.currentData.pharmacy_settings.delivery_available && (
                        <p className="title-4">
                          {pharmacySettingsQuery.currentData.pharmacy_settings.delivery_enabled
                            ? 'ON'
                            : 'OFF'}
                        </p>
                      )}
                    </Row>
                    <Row
                      onClick={() => {
                        ReactGA.event({
                          category: GOOGLE_ANALYTICS_CATEGORY.SETTINGS,
                          action: 'Settings_Marketing_Patients_Delivery_Views',
                        });
                        setCurrentSettingsTab(SETTING_TYPES.MarketingPatients);
                      }}
                      className={`settingsOptionContainer ${
                        currentSettingsTab === SETTING_TYPES.MarketingPatients ? 'active' : ''
                      }`}
                      data-testid="marketing-patients-tab"
                    >
                      <h3 className="sub-heading-3">Marketing patients</h3>
                      {pharmacySettingsQuery.currentData.pharmacy_settings
                        .direct_users_delivery_available && (
                        <p className="title-4">
                          {pharmacySettingsQuery.currentData.pharmacy_settings
                            .direct_users_delivery_enabled
                            ? 'ON'
                            : 'OFF'}
                        </p>
                      )}
                    </Row>
                  </Card>
                </Col>
                <Col md={8} className="settingsColumn">
                  <Card>
                    <TabContent
                      currentSettingsTab={currentSettingsTab}
                      pharmacySettingsData={pharmacySettingsQuery.currentData}
                      setOverlayOpen={setOverlayOpen}
                      updatePharmacySettings={updatePharmacySettings}
                    />
                  </Card>
                </Col>
              </Row>
            </>
          ) : (
            <Card>
              <Error />
            </Card>
          )}
        </div>
      </LoadingWrapper>
    </PageWrapper>
  );
};

export default SettingsPage;
