import React from 'react';
import dateTimeFormatter from '../../helpers/dateTimeFormatter';
import PropTypes from 'prop-types';
import dateFormatter from '../../helpers/dateFormatter';
import addressFormatter from '../../helpers/addressFormatter';

const OrderContent = ({ order }) => {
  const field = (fieldName, value) => (
    <div>
      <h6>{fieldName}</h6>
      <span className="fieldValue">{value || '-'}</span>
    </div>
  );

  if (!order) {
    return null;
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="confidentialContainer">
          <h1 className="confidential">CONFIDENTIAL</h1>
        </div>
        <div className="card headerCard">
          <div>
            <h1 className="name">{`${order.patient?.first_name} ${order.patient?.last_name}`}</h1>
            <h3 className="orderNumber">Order #{order.oms_id}</h3>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-4">
          <div className="card">
            <h3>Patient Details</h3>
            {field('Date of Birth', dateFormatter(order.patient?.date_of_birth))}
            {field('NHS Number', order.patient?.nhs_number)}
            {field('Home Address', addressFormatter({ ...order.address, name: null }))}
          </div>
          <div className="card">
            <h3>Primary Contact Details</h3>
            {field(
              'Name',
              `${order.primary_patient?.first_name} ${order.primary_patient?.surname}`
            )}
            {field('Phone', order.primary_patient?.phone_number)}
            {field('Email', order.primary_patient?.email)}
          </div>
          <div className="card">
            <h3>GP Details</h3>
            {field(
              'GP Registered Address',
              addressFormatter({ ...order.gp_registered_address, name: null })
            )}
            {field('Phone', order.gp?.phone)}
            {field('System', order.gp?.system)}
          </div>
        </div>
        <div className="col-8">
          <div className="card">
            <h3>Order Details</h3>
            {field('Order Number', `#${order.oms_id}`)}
            {field('Order ID', order.coop_id)}
            {field('Order Created', dateTimeFormatter(order.received_on)?.LocalDate)}
            {field('Patient Notes', order.gp_notes)}
          </div>
          <div className="card">
            <h3>Medication</h3>
            <div className="medicationCardContent container">
              <div className="row">
                <div className="col">
                  <span className="fieldName">Medicine</span>
                </div>
                <div className="col">
                  <span className="fieldName">Instructions</span>
                </div>
                <div className="col-3">
                  <span className="fieldName">Status</span>
                </div>
              </div>
              {order.order_items?.map((item) => (
                <div className="row" key={item.name}>
                  <div className="col">
                    <span className="fieldValue">{item.name}</span>
                  </div>
                  <div className="col">
                    <span className="fieldValue">{item.instructions}</span>
                  </div>
                  <div className="col-3">
                    <span className="fieldValue">{item.status}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="card">
            <h3>Pharmacy Details</h3>
            {field('Pharmacy Name', order.pharmacy?.name)}
            {field('Pharmacy Address', addressFormatter(order.pharmacy?.address))}
          </div>
        </div>
      </div>
    </div>
  );
};

OrderContent.propTypes = {
  order: PropTypes.any,
};

export default OrderContent;
