import { Icon } from '../../Atoms';
import styles from './Snackbar.module.scss';

interface SnackbarProps extends React.HTMLAttributes<HTMLElement> {
  shortText?: string;
  longText?: string;
  buttonText?: string;
}

const Snackbar: React.FunctionComponent<SnackbarProps> = ({
  shortText,
  longText,
  buttonText,
  onClick,
  ...props
}) => {
  return (
    <div className={styles.snackbar} {...props}>
      <div className={styles['icon-container']}>
        <Icon iconName="infoCircle" />
      </div>
      <div>
        {shortText && <div className={styles['short-text']}>{shortText}</div>}
        {longText && <div className={styles['long-text']}>{longText}</div>}
      </div>
      {buttonText && onClick && (
        <button onClick={onClick} className={styles.button}>
          {buttonText}
        </button>
      )}
    </div>
  );
};

export default Snackbar;
