import React from 'react';

const CookiePolicy = () => {
  return (
    <>
      <h1>Cookie Policy</h1>
      <h2>What are cookies and how are they used on this portal?</h2>
      <p>
        Cookies are small text files that are stored on your computer when you visit websites.
        Within this policy we will use the term to also cover HTML5 web storage, which is what is
        used on this portal. Like cookies they are small text files that are stored on your computer
        when you visit websites.
      </p>
      <p>We use HTML5 Web storage on this website:</p>
      <ul>
        <li>
          <strong>Web Storage.</strong> These are stored on your computer until you delete them.
        </li>
      </ul>
      <h2>What cookies are used on this portal?</h2>
      <p>
        Below is a description of what may be stored on your computer when visiting this portal.
        From time to time we may make changes to this list as we introduce or remove functionality
        from our portal.
      </p>
      <h2>Strictly necessary and functionality cookies</h2>
      <p>
        This website uses AWS Cognito to enable users to log in and restrict data access to a
        user&apos;s designated pharmacies. AWS Cognito uses keys prefixed with
        CognitoIdentityServiceProvider in your browser&apos;s local storage to provide this
        functionality. The information stored includes your name and email address to personalise
        your experience.
      </p>
      <h2>How do I delete HTML5 web storage?</h2>
      <p>
        You can delete HTML5 web storage by clearing your browsers cache. This can easily be done bt
        pressing Control + Shift + Delete or Command + Shift + Delete (Mac).
      </p>
      <h2>How do I change my cookie settings?</h2>
      <p>
        By default, cookies should be enabled on your computer or device. If not, your experience on
        this portal will be negatively impacted.
      </p>
      <p>
        You can also alter your cookie settings in your browser. Find out more at{' '}
        <a href="https://www.aboutcookies.org/">AboutCookies.org</a>
      </p>
      <h2>Further information about cookies</h2>
      <p>
        To find out more about cookies visit the{' '}
        <a href="https://ico.org.uk/">InformationCommissioner&apos;s Office website</a>
      </p>
    </>
  );
};

export default CookiePolicy;
