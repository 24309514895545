import React from 'react';
import { Switch } from 'react-router-dom';
import { ROUTES } from './constants';
import {
  FirstTimeSetupPage,
  ForgottenPasswordPage,
  LoginPage,
  NotFoundPage,
  OrderDetailsPage,
  OrdersPage,
  ReportsPage,
  SettingsPage,
  ServicesPage,
} from './pages';
import Route from './Components/Route/Route';
import TextOverlay from './Components/TextOverlay/TextOverlay';

const RouteComponent = ({ overlayOpen, settingsEnabled, servicesEnabled }) => {
  return (
    <>
      {overlayOpen && <TextOverlay />}
      <Switch>
        <Route exact path={ROUTES.LOGIN} title="Member Login">
          <LoginPage />
        </Route>
        <Route exact path={ROUTES.FIRST_TIME_SETUP} title="First Time Set Up">
          <FirstTimeSetupPage />
        </Route>
        <Route exact path={ROUTES.FORGOTTEN_PASSWORD} title="Forgotten Password">
          <ForgottenPasswordPage />
        </Route>
        <Route exact path={ROUTES.ORDERS} title="Orders" secured>
          <OrdersPage />
        </Route>
        <Route exact path={ROUTES.ORDER_DETAILS} title="Order Details" secured>
          <OrderDetailsPage />
        </Route>
        {settingsEnabled ? (
          <Route exact path={ROUTES.SETTINGS} title="Settings" secured>
            <SettingsPage />
          </Route>
        ) : null}
        {servicesEnabled ? (
          <Route exact path={ROUTES.SERVICES} title="Services" secured>
            <ServicesPage />
          </Route>
        ) : null}
        <Route exact path={ROUTES.REPORTS} title="Reports" secured>
          <ReportsPage />
        </Route>
        <Route title="Not Found">
          <NotFoundPage />
        </Route>
      </Switch>
    </>
  );
};

export default RouteComponent;
