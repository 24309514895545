import React from 'react';
import { Modal } from '../../../Components/Molecules';

const DropdownOverlay = ({ setOverlayOpen, onContinue, isNotify, loading, numberOfOrders }) => {
  let label;
  if (numberOfOrders === 1) {
    label = `Are you sure you want to change the status of ${numberOfOrders} order?`;
  } else {
    label = `Are you sure you want to change the status of ${numberOfOrders} orders?`;
  }
  return (
    <Modal
      icon={isNotify ? 'bell' : 'bellOff'}
      label={label}
      onClose={() => setOverlayOpen(false)}
      closingX={true}
      secondaryButtonLabel={!loading && 'Cancel'}
      secondaryButtonCallback={() => setOverlayOpen(false)}
      primaryButtonLabel={!loading && 'Continue'}
      primaryButtonCallback={onContinue}
      variant="secondary"
      data-testid="dropdown-overlay"
    >
      <p>
        {isNotify
          ? 'This will update your selection and notify patients.'
          : 'This will update your selection without notifying patients.'}
      </p>
    </Modal>
  );
};

export default DropdownOverlay;
