import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import UsernameForm from './UsernameForm';
import VerificationCodeForm from './VerificationCodeForm';
import './ForgottenPasswordPage.css';
import { useHistory } from 'react-router';
import { ROUTES } from '../../constants';

const ForgottenPasswordPage = () => {
  const [verificationSent, setVerificationSent] = useState(false);
  const [verificationUsername, setVerificationUsername] = useState('');
  const history = useHistory();

  const successfulPasswordChange = () => {
    history.push(ROUTES.LOGIN);
  };

  const successfulVerificationSent = (username) => {
    setVerificationUsername(username);
    setVerificationSent(true);
  };

  return (
    <Container id="MemberForgottenPasswordPage">
      <Row className="justify-content-md-center mt-3">
        <Col md={6} className="forgottenPasswordContainer">
          {verificationSent ? (
            <VerificationCodeForm
              username={verificationUsername}
              onSuccess={successfulPasswordChange}
            />
          ) : (
            <UsernameForm onSuccess={successfulVerificationSent} />
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default ForgottenPasswordPage;
