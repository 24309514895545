import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import './Error.css';
import PropTypes from 'prop-types';
import { Icon } from '../Atoms';

const Error = ({ children, showClose = false, onClose, icon }) => {
  useEffect(() => {
    ReactGA.event({
      category: 'Error',
      action: 'Error_General',
    });
  }, []);

  return (
    <div className="error-container" data-testid="error">
      <div className="error-content">
        <div className="error-icon">
          <Icon iconName={!!icon ? icon : 'warning'} />
        </div>
        <div>{children || "Sorry, something's gone wrong, please try again later"}</div>
      </div>
      {showClose && (
        <button className="error-close" onClick={onClose} data-testid="error-close">
          <Icon iconName="cross" />
        </button>
      )}
    </div>
  );
};

Error.propTypes = {
  showClose: PropTypes.bool,
  onClose: PropTypes.func,
};

export default Error;
