import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import ReactGA from 'react-ga4';
import orderSlice from '../../redux/orderSlice';
import pharmacySlice from '../../redux/pharmacySlice';
import { Icon } from '../Atoms';
import { clearSelectedOrders, selectedOrdersSelector } from '../../redux/selectedOrdersSlice';
import './OrderStatusDropdown.css';
import DropdownOverlay from './DropdownOverlay/DropdownOverlay';

const statuses = [
  {
    key: 'Request With Gp',
    text: 'Request With GP',
    backgroundColor: '#F3C088',
    textColor: '#000',
  },
  {
    key: 'Prescription Issued',
    text: 'Prescription Issued',
    section: 0,
    backgroundColor: '#FFD308',
    textColor: '#000',
  },
  {
    key: 'Prescription Part Issued',
    text: 'Prescription Part Issued',
    section: 0,
    backgroundColor: '#FFD308',
    textColor: '#000',
  },
  {
    key: 'Dispensing',
    text: 'Dispensing',
    section: 0,
    backgroundColor: '#80C7DC',
    textColor: '#000',
  },
  {
    key: 'Completed',
    text: 'Completed',
    section: 0,
    backgroundColor: '#9CCC92',
    textColor: '#000',
  },
  {
    key: 'Contact Gp',
    text: 'Contact GP',
    section: 1,
    backgroundColor: '#F2C4D1',
    textColor: '#000',
  },
  {
    key: 'Contact Us',
    text: 'Contact Us',
    section: 1,
    backgroundColor: '#F3C088',
    textColor: '#000',
  },
  {
    key: 'Cancelled',
    icon: <Icon iconName="bellOff" />,
    text: 'Cancelled',
    section: 1,
    backgroundColor: '#EABFB3',
    textColor: '#FFF',
  },
  {
    key: 'Collected/Delivered',
    icon: <Icon iconName="bellOff" />,
    text: 'Collected/Delivered',
    section: 1,
    backgroundColor: '#9CCC92',
    textColor: '#FFF',
  },
];

const bulkActionStatus = {
  key: 'Bulk Action',
  text: 'Bulk Action',
  backgroundColor: '#000',
  textColor: '#FFF',
};

const OrderStatusDropdown = ({ order, onError, bulk = false }) => {
  const [updateOrder, updateOrderMutation] = orderSlice.endpoints.updateOrder.useMutation();
  const [updateOrders] = orderSlice.endpoints.updateOrders.useMutation({
    fixedCacheKey: 'shared-bulk-update',
  });
  const [errorLoaded, setErrorLoaded] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [newStatus, setNewStatus] = useState('');
  const [loading, setLoading] = useState(false);
  const selectedOrders = useSelector(selectedOrdersSelector);
  const pharmacySliceQuery = pharmacySlice.endpoints.getPharmacySettings.useQuery();
  const orderNotifications =
    pharmacySliceQuery?.data?.pharmacy_settings?.order_notifications_enabled;
  const dispatch = useDispatch();

  useEffect(() => {
    if (!errorLoaded && updateOrderMutation.isError) {
      setErrorLoaded(true);
      onError(true);
    } else if (!errorLoaded) {
      onError(false);
    }
  }, [updateOrderMutation.isError, updateOrderMutation.error, onError, errorLoaded]);

  const shouldSendNotification = () => {
    if (newStatus === 'Collected/Delivered' || newStatus === 'Cancelled') {
      return false;
    } else {
      return orderNotifications;
    }
  };

  const selectHandler = async (status) => {
    if (status === 'Collected/Delivered') {
      setNewStatus('Completed');
    } else {
      setNewStatus(status);
    }
    if (bulk) {
      setShowOverlay(true);
    } else {
      await updateStatus(order?.coop_id, status);
    }
  };

  const updateStatus = async (orderId, status) => {
    ReactGA.event({
      category: 'Orders',
      action: 'Status_Change_' + status,
    });
    const updateOrderRequest = {
      current_status: order.status,
      order_status: status === 'Collected/Delivered' ? 'Completed' : status,
    };
    setErrorLoaded(false);
    dispatch(clearSelectedOrders());
    const sendNotifications = shouldSendNotification();
    await updateOrder({ orderId, updateOrderRequest, sendNotifications });
  };

  const updateStatuses = async (orderIds, status) => {
    ReactGA.event({
      category: 'Orders',
      action: 'Bulk_Action_Click',
    });
    const updateOrdersRequest = orderIds.map((id) => ({
      order_id: id,
      order_status: status === 'Collected/Delivered' ? 'Completed' : status,
    }));
    setErrorLoaded(false);
    try {
      const sendNotifications = shouldSendNotification();
      await updateOrders({ updateOrdersRequest, sendNotifications }).unwrap();
      dispatch(clearSelectedOrders());
    } catch (e) {
      setErrorLoaded(true);
      onError(true);
    }
  };

  const currentStatusKey =
    order?.status === 'Prescription Processed' ? 'Prescription Issued' : order?.status;
  const currentStatus = bulk
    ? bulkActionStatus
    : statuses.find((status) => status.key === currentStatusKey);

  return (
    <>
      {showOverlay && (
        <DropdownOverlay
          setOverlayOpen={setShowOverlay}
          numberOfOrders={bulk ? selectedOrders.length : 1}
          isNotify={newStatus !== 'Cancelled' && newStatus !== 'Completed'}
          onContinue={async () => {
            setLoading(true);
            if (bulk) {
              await updateStatuses(
                selectedOrders.map((selectedOrder) => selectedOrder.coop_id),
                newStatus
              );
            } else {
              await updateStatus(order?.coop_id, newStatus);
            }
            setLoading(false);
            setShowOverlay(false);
          }}
          loading={loading}
        />
      )}
      <div onClick={(e) => e.stopPropagation()} className="dropdownContainer">
        <Dropdown onSelect={selectHandler}>
          <Dropdown.Toggle
            style={{
              backgroundColor: currentStatus.backgroundColor,
              borderColor: currentStatus.backgroundColor,
              color: currentStatus.textColor,
              textDecorationColor: currentStatus.textColor,
            }}
            className="dropdownButton"
          >
            <span className="dropdownButtonText">{currentStatus.text}</span>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <>
              {statuses
                .filter((status) => status.section === 0)
                .map((status) => {
                  if (order?.status !== status.key) {
                    return (
                      <Dropdown.Item
                        className="dropdownItem"
                        key={status.key}
                        eventKey={status.key}
                      >
                        <span
                          className="dropdownCircle"
                          style={{ backgroundColor: status.backgroundColor }}
                        ></span>
                        {status.text}
                      </Dropdown.Item>
                    );
                  } else {
                    return null;
                  }
                })}
              <Dropdown.Divider className="dropdownDivider" />
              {statuses
                .filter((status) => status.section === 1)
                .map((status) => {
                  if (order?.status !== status.key) {
                    return (
                      <Dropdown.Item
                        className="dropdownItem"
                        key={status.key}
                        eventKey={status.key}
                      >
                        <span
                          className="dropdownCircle"
                          style={{ backgroundColor: status.backgroundColor }}
                        ></span>
                        {status.text}
                        {status.icon}
                      </Dropdown.Item>
                    );
                  } else {
                    return null;
                  }
                })}
            </>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </>
  );
};

OrderStatusDropdown.propTypes = {
  order: PropTypes.shape({
    status: PropTypes.string,
    coop_id: PropTypes.string,
  }),
  onError: PropTypes.func,
};

export default OrderStatusDropdown;
