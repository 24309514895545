import { useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import ReactGA from 'react-ga4';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Icon } from '../../Components/Atoms';
import Error from '../../Components/Error/Error';
import LoadingWrapper from '../../Components/LoadingWrapper/LoadingWrapper';
import MemberOrderLogs from '../../Components/MemberOrderLogs/MemberOrderLogs';
import PrintOrderControl from '../../Components/OrderPrint/PrintOrderControl';
import OrderStatusDropdown from '../../Components/OrderStatusDropdown/OrderStatusDropdown';
import { ERROR_MESSAGES, GOOGLE_ANALYTICS_CATEGORY, ROUTES } from '../../constants';
import addressFormatter from '../../helpers/addressFormatter';
import dateFormatter from '../../helpers/dateFormatter';
import dateTimeFormatter from '../../helpers/dateTimeFormatter';
import nhsNumberFormatter from '../../helpers/nhsNumberFormatter';
import orderSlice from '../../redux/orderSlice';
import './OrderDetailsPage.css';

const field = (fieldName, value) => (
  <div>
    <h6 className="fieldName">{fieldName}</h6>
    <span className="fieldValue">{value || '-'}</span>
  </div>
);

const OrderDetailsPage = () => {
  let { orderId } = useParams();
  const orderDetailsQuery = orderSlice.endpoints.getOrderDetails.useQuery(orderId);
  const [showOrderLogs, setShowOrderLogs] = useState(false);
  const [showOrderLogsError, setShowOrderLogsError] = useState(false);
  const [showOrderStatusError, setShowOrderStatusError] = useState(false);
  const history = useHistory();

  const handleOrderLogsError = () => {
    setShowOrderLogsError(true);
    setShowOrderLogs(false);
  };

  const handleOrderLogsClick = () => {
    setShowOrderLogs(true);
    ReactGA.event({
      category: GOOGLE_ANALYTICS_CATEGORY.ORDER_DETAILS,
      action: 'Show_Event_Logs',
    });
  };

  const orderDetail = orderDetailsQuery.currentData;

  const content = (order) => (
    <>
      <Row>
        <Card className="headerCard">
          <div className="headerCardContent">
            <div className="headerLeft">
              <div>
                <Icon
                  iconName={
                    order.fulfillment_type === 'pharmacyDelivery' ? 'van' : 'buildingHospital'
                  }
                  className="deliveryIcon"
                  data-testid="delivery-icon"
                />
              </div>
              <div>
                <h1 className="name">{`${order.patient?.first_name} ${order.patient?.last_name}`}</h1>
                <h3 className="sub-heading-3 orderNumber">Order #{order.oms_id}</h3>
              </div>
            </div>
            <div className="headerRight">
              <OrderStatusDropdown
                order={{ status: order.current_status, coop_id: order.coop_id }}
                onError={setShowOrderStatusError}
              />
              <PrintOrderControl order={order} coop_id={order.coop_id} />
            </div>
          </div>
          {showOrderStatusError && (
            <div className="errorContainer">
              <Error showClose onClose={() => setShowOrderStatusError(false)}>
                {ERROR_MESSAGES.STATUS}
              </Error>
            </div>
          )}
        </Card>
      </Row>
      <Row>
        <Col md={4}>
          <Card>
            <h3>Patient Details</h3>
            {field('Date of Birth', dateFormatter(order.patient?.date_of_birth))}
            {field('NHS Number', nhsNumberFormatter(order.patient?.nhs_number))}
            {field(
              'Home Address',
              addressFormatter({ ...order.gp_registered_address, name: null })
            )}
          </Card>
          <Card>
            <h3>Primary Contact Details</h3>
            {field(
              'Name',
              `${order.primary_patient?.first_name} ${order.primary_patient?.surname}`
            )}
            {field('Phone', order.primary_patient?.phone_number)}
            {field('Email', order.primary_patient?.email)}
          </Card>
          <Card>
            <h3>GP Details</h3>
            {field('GP Registered Address', addressFormatter({ ...order.gp.address, name: null }))}
            {field('Phone', order.gp?.phone)}
            {field('System', order.gp?.system)}
          </Card>
        </Col>
        <Col md={8}>
          <Card>
            <h3>Order Details</h3>
            {field('Order Number', `#${order.oms_id}`)}
            {field('Order ID', order.coop_id)}
            {field('Order Created', dateTimeFormatter(order.received_on)?.LocalDate)}
            {field('Patient Notes', order.gp_notes)}
          </Card>
          <Card>
            <h3>Medication</h3>
            <Container className="medicationCardContent">
              <Row>
                <Col>
                  <h6 className="fieldName">Medicine</h6>
                </Col>
                <Col>
                  <h6 className="fieldName">Instructions</h6>
                </Col>
                <Col xs={3}>
                  <h6 className="fieldName">Status</h6>
                </Col>
              </Row>
              {order.order_items?.map((item) => (
                <Row key={item.name}>
                  <Col>
                    <span className="fieldValue">{item.name}</span>
                  </Col>
                  <Col>
                    <span className="fieldValue">{item.instructions}</span>
                  </Col>
                  <Col xs={3}>
                    <span className="fieldValue">{item.status}</span>
                  </Col>
                </Row>
              ))}
            </Container>
          </Card>
          {order.fulfillment_type === 'collectFromPharmacy' && (
            <Card>
              <h3>Pharmacy Details</h3>
              {field('Pharmacy Name', order.pharmacy?.name)}
              {field('Pharmacy Address', addressFormatter(order.pharmacy?.address))}
            </Card>
          )}
          {order.fulfillment_type === 'pharmacyDelivery' && (
            <Card data-testid="order-details-delivery-details-card">
              <h3>Delivery Details</h3>
              {field('Delivery Address', addressFormatter(order.address))}
            </Card>
          )}
          <Card>
            <h3>Event Logs</h3>
            {showOrderLogs ? (
              <MemberOrderLogs orderId={order.coop_id} onError={handleOrderLogsError} />
            ) : (
              <Button
                className="detailsLogsButton"
                variant="primary"
                data-testid="show-event-logs-button"
                onClick={handleOrderLogsClick}
              >
                Show Event Logs
              </Button>
            )}
            {showOrderLogsError && (
              <div className="orderLogsErrorContainer">
                <Error showClose onClose={() => setShowOrderLogsError(false)} />
              </div>
            )}
          </Card>
        </Col>
      </Row>
    </>
  );

  return (
    <Container id="OrderDetailsPage" className="detailsContainer" tabIndex="0">
      <div className="orderDetailsBack">
        <Button variant="back" onClick={() => history.push(ROUTES.ORDERS)}>
          <Icon iconName="chevronLeft"></Icon>Back to patient list
        </Button>
      </div>
      <LoadingWrapper isLoading={orderDetailsQuery.isFetching}>
        <div className="orderDetailsContent">
          {orderDetail ? (
            content(orderDetail)
          ) : (
            <Card>
              <Error />
            </Card>
          )}
        </div>
      </LoadingWrapper>
    </Container>
  );
};

export default OrderDetailsPage;
