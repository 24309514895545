import React from 'react';
import { Row } from 'react-bootstrap';
import { Icon } from '../../../Components/Atoms';
import ReportsContainer from '../../../Components/ReportsContainer/ReportsContainer';
import ReportsPageOptions from './../ReportsPageOptions';
import ReactGA from 'react-ga4';
import { percentageCalc } from '../../../helpers/percentageCalculator';
import './ReportsCardContainer.css';

const ReportsCardContainer = (props) => {
  const { patient_type, pharmacyData, setShowReportsTable } = props;
  return (
    <Row>
      <ReportsContainer
        iconName={ReportsPageOptions.card.patients[patient_type].icon}
        title={ReportsPageOptions.card.patients[patient_type].title}
        value={pharmacyData.users_with_selected_pharmacy || 0}
        text={ReportsPageOptions.card.patients[patient_type].text}
      >
        <Icon
          data-testid="reportsTableToggle"
          iconName="arrowsMaximize"
          onClick={() => {
            ReactGA.event({
              category: 'Reports',
              action: ReportsPageOptions.filterOptions[patient_type].event.listViewClick,
            });
            ReactGA.event({
              category: 'Reports',
              action: ReportsPageOptions.filterOptions[patient_type].event.listViewView,
            });
            setShowReportsTable(true);
          }}
          className="arrowsIcon"
        />
      </ReportsContainer>

      <ReportsContainer
        iconName={ReportsPageOptions.card.orders.icon}
        title={ReportsPageOptions.card.orders.title}
        value={pharmacyData.total_orders || 0}
        text={ReportsPageOptions.card.orders.text}
      />

      <ReportsContainer>
        <p className="title-3">Fulfillment method</p>
        <div className="deliveryRow">
          <Icon iconName="buildingHospital" className="deliveryPercentIcon" />
          <p className="percentDisplay mb-0 ml-3">
            {pharmacyData.collection_orders !== undefined &&
            pharmacyData.pharmacy_delivery_orders !== undefined
              ? percentageCalc(
                  pharmacyData.collection_orders,
                  pharmacyData.collection_orders + pharmacyData.pharmacy_delivery_orders
                )
              : 0}
            %
          </p>
          <p className="mb-0 ml-1">Collection</p>
        </div>
        <div className="deliveryRow">
          <Icon iconName="van" className="deliveryPercentIcon" />
          <p className="percentDisplay mb-0 ml-3">
            {pharmacyData.collection_orders !== undefined &&
            pharmacyData.pharmacy_delivery_orders !== undefined
              ? percentageCalc(
                  pharmacyData.pharmacy_delivery_orders,
                  pharmacyData.collection_orders + pharmacyData.pharmacy_delivery_orders
                )
              : 0}
            %
          </p>
          <p className="mb-0 ml-1">Delivery</p>
        </div>
      </ReportsContainer>
      {patient_type === 'all_patients' && (
        <>
          <ReportsContainer
            iconName={ReportsPageOptions.card.gp_turnaround.icon}
            title={ReportsPageOptions.card.gp_turnaround.title}
            value={`${pharmacyData.average_hours_between_creation_and_prescription}h`}
            text={ReportsPageOptions.card.gp_turnaround.text}
          />

          <ReportsContainer
            iconName={ReportsPageOptions.card.dispensing_process.icon}
            title={ReportsPageOptions.card.dispensing_process.title}
            value={`${pharmacyData.average_hours_between_prescription_and_completion}h`}
            text={ReportsPageOptions.card.dispensing_process.text}
          />
        </>
      )}
    </Row>
  );
};

export default ReportsCardContainer;
