import { useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { ReactComponent as EmptyIcon } from '../../assets/empty-box.svg';
import { Checkbox, Icon, Pill } from '../../Components/Atoms';
import { Snackbar } from '../../Components/Molecules';
import BulkOperationBar from '../../Components/BulkOperationBar/BulkOperationBar';
import LoadingIndicator from '../../Components/LoadingIndicator/LoadingIndicator';
import { GOOGLE_ANALYTICS_CATEGORY, ROUTES } from '../../constants';
import orderSlice from '../../redux/orderSlice';
import { clearSelectedOrders } from '../../redux/selectedOrdersSlice';
import { selectTab } from '../../redux/tabSlice';
import { clearProcessingOrdersNewAlert } from '../../redux/processingOrdersSlice';
import './OrdersPage.css';
import { headings, OrderTable } from './OrderTable';
import { Search } from './Search';
import { Notification } from '../../Components/Molecules/Notification';
import pharmacySlice from '../../redux/pharmacySlice';
import PageWrapper from '../../Components/PageWrapper/PageWrapper';

const tabPriorities = {
  Requested: { priority: [], others: ['Request With Gp'] },
  Processing: {
    priority: ['Prescription Issued', 'Prescription Part Issued', 'Prescription Processed'],
    others: ['Dispensing'],
  },
  Completed: { priority: ['Completed'], others: ['Cancelled'] },
  Issues: { priority: ['Contact Us'], others: ['Contact Gp'] },
};

const tabStatuses = {};
Object.keys(tabPriorities).forEach(
  (tab) => (tabStatuses[tab] = [...tabPriorities[tab].priority, ...tabPriorities[tab].others])
);

const OrdersPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [orders, setOrders] = useState([]);
  const [allOrdersSelected, setAllOrdersSelected] = useState(false);
  const [resultErrors, setResultErrors] = useState([]);
  const [showBulkActionError, setShowBulkActionError] = useState(false);
  const [tabCount, setTabCount] = useState(
    Object.keys(tabStatuses).reduce((count, tab) => {
      count[tab] = 0;
      return count;
    }, {})
  );
  const [showArchive, setShowArchive] = useState(false);
  const [showFilter, setShowFilter] = useState(false);

  const shouldShowNotificationBar = () => {
    return (
      pharmacySettingsQuery &&
      pharmacySettingsQuery.currentData &&
      pharmacySettingsQuery.currentData.pharmacy_settings &&
      pharmacySettingsQuery.currentData.pharmacy_settings.show_order_notification_banner
    );
  };

  const selectedOrders = useSelector((state) => state.selectedOrders.orders);
  const selectedTab = useSelector((state) => state.tab.selectedTab);
  const newProcessingOrders = useSelector((state) => state.processingOrders.orders);
  const newProcessingOrdersCount = useSelector((state) => state.processingOrders.newOrderCount);

  const [updatePharmacySettings] = pharmacySlice.endpoints.updatePharmacySettings.useMutation();
  const pharmacySettingsQuery = pharmacySlice.endpoints.getPharmacySettings.useQuery();

  const [, updateOrdersMutation] = orderSlice.endpoints.updateOrders.useMutation({
    fixedCacheKey: 'shared-bulk-update',
  });
  const [getOrders, getOrdersQuery] = orderSlice.endpoints.getOrders.useLazyQuery();
  const getOrderCounts = orderSlice.endpoints.getOrderCounts.useQuery({
    includeArchive: showArchive,
  });

  const prioritiseAndFilterOrders = useCallback((filterOrders, filteredTab) => {
    return [
      ...filterOrders
        .filter(({ status }) => tabPriorities[filteredTab].priority.includes(status))
        .sort(function (a, b) {
          return new Date(b.received_on) - new Date(a.received_on);
        }),
      ...filterOrders
        .filter(({ status }) => tabPriorities[filteredTab].others.includes(status))
        .sort(function (a, b) {
          return new Date(b.received_on) - new Date(a.received_on);
        }),
    ];
  }, []);

  useEffect(
    () => getOrders({ statuses: tabStatuses[selectedTab], includeArchive: showArchive }),
    [getOrders, selectedTab, showArchive]
  );

  useEffect(() => {
    if (getOrdersQuery.currentData) {
      const currentOrders = prioritiseAndFilterOrders(getOrdersQuery.currentData, selectedTab);
      setOrders(currentOrders);
    }
  }, [getOrdersQuery.currentData, prioritiseAndFilterOrders, selectedTab]);

  useEffect(() => {
    const data = getOrderCounts.currentData;
    if (data?.length) {
      const newTabCount = {};
      data.forEach((statusCount) => {
        Object.keys(tabStatuses).forEach((tab) => {
          newTabCount[tab] = newTabCount[tab] || 0;
          if (tabStatuses[tab].includes(statusCount.status)) {
            newTabCount[tab] += statusCount.count;
          }
        });
      });
      setTabCount(newTabCount);
    }
  }, [getOrderCounts.currentData]);

  useEffect(() => {
    if (allOrdersSelected && selectedOrders.length < orders.length) {
      setAllOrdersSelected(false);
    } else if (!allOrdersSelected && orders.length && orders.length === selectedOrders.length) {
      setAllOrdersSelected(true);
    }
  }, [allOrdersSelected, orders, selectedOrders]);

  const performTableChange = (newTab) => {
    ReactGA.event({
      category: 'Login',
      action: 'Orders_' + newTab + '_Click',
    });
    if (selectedTab !== newTab) {
      if (selectedTab === 'Processing') {
        dispatch(clearProcessingOrdersNewAlert());
      }
      dispatch(selectTab(newTab));
      dispatch(clearSelectedOrders());
      setAllOrdersSelected(false);
      setShowBulkActionError(false);
      ReactGA.event({
        category: 'Orders',
        action: 'Orders_' + newTab + '_View',
      });
    }
  };

  const changeOrderDetails = async (orderId) => {
    dispatch(clearSelectedOrders());
    history.push(ROUTES.ORDER_DETAILS.replace(':orderId', orderId));
  };

  const handleResultError = (orderId, message) => {
    setResultErrors([
      ...resultErrors,
      {
        id: orderId,
        message,
      },
    ]);
  };

  const handleBulkOperationError = () => {
    setShowBulkActionError(true);
    dispatch(clearSelectedOrders());
  };

  const dismissNotificationMessage = () => {
    ReactGA.event({
      category: GOOGLE_ANALYTICS_CATEGORY.ORDERS_SCREEN,
      action: 'Order_Alert_Click',
    });
    updatePharmacySettings({
      show_order_notification_banner: false,
    });
  };

  return (
    <>
      {updateOrdersMutation.isLoading && (
        <div className="bulkLoadingOverlay">
          <LoadingIndicator className="bulkLoadingIndicator" />
          <p className="title-2">Orders are being updated, please wait a little while.</p>
        </div>
      )}
      <PageWrapper id="MemberOrdersPage">
        {shouldShowNotificationBar() && (
          <Row className="justify-content-md-center mb-3 tableContainer">
            <Col>
              <Notification
                titleText={'Collection order updates are off'}
                descriptionText={
                  'Patients will not be notified if you update a collection order status'
                }
                urlText={'Turn On'}
                url={'settings'}
                buttonOnclickHandler={dismissNotificationMessage}
                dismissible={true}
              />
            </Col>
          </Row>
        )}
        <Row>
          <Col>
            <Search onSelectSearchResult={changeOrderDetails} />
          </Col>
        </Row>
        <Row className="justify-content-md-center tableContainer">
          <Col md={12}>
            <div className="tableHeaderContainer">
              <ul className="tableTabContainer">
                {Object.keys(tabPriorities).map((tab) => {
                  return (
                    <li key={tab} className="tableTabList">
                      <button
                        className={`tableTab ${selectedTab === tab && 'active'}`}
                        aria-current="page"
                        onClick={() => performTableChange(tab)}
                      >
                        {tab} <span className="tableTabNumber">({tabCount[tab]})</span>
                        {tab === 'Processing' && newProcessingOrdersCount ? (
                          <Pill variant="warning" size="small" className="ml-1">
                            {newProcessingOrdersCount} NEW
                          </Pill>
                        ) : null}
                      </button>
                    </li>
                  );
                })}
                {selectedTab === 'Completed' && (
                  <li
                    className={`tableTabList filter ${showFilter ? 'active' : ''}`}
                    onClick={() => {
                      setShowFilter((prev) => !prev);
                    }}
                  >
                    <button className="tableTab">
                      Filter
                      <Icon iconName="filter" />
                    </button>
                    {showFilter ? (
                      <div className="filterContainer">
                        <Checkbox
                          checked={showArchive}
                          setChecked={() => {
                            setShowArchive((prev) => !prev);
                            setShowFilter((prev) => !prev);
                          }}
                          label="Show orders that are more than 30 days old"
                        />
                      </div>
                    ) : null}
                  </li>
                )}
                <li>
                  <Icon
                    iconName="refresh"
                    onClick={() => {
                      ReactGA.event({
                        category: 'Orders',
                        action: 'Refresh_Click',
                      });
                      getOrders(tabStatuses[selectedTab]);
                      getOrderCounts.refetch();
                    }}
                    className="refreshIcon"
                    data-testid="refresh-icon"
                    tabIndex="0"
                    onKeyPress={(event) => {
                      if (event.key === 'Enter' && event.target.className === 'refreshIcon') {
                        getOrders(tabStatuses[selectedTab]);
                        getOrderCounts.refetch();
                      }
                    }}
                  />
                </li>
              </ul>
            </div>

            {getOrdersQuery.isLoading && (
              <div className="tableEmptyContainer">
                <LoadingIndicator />
              </div>
            )}

            {getOrdersQuery.isError && (
              <OrderTable
                headings={headings(selectedTab, handleResultError, newProcessingOrders)}
                callBackFunction={(id) => changeOrderDetails(id)}
                callBackField={'coop_id'}
                showMainError={true}
                selectedTab={selectedTab}
                newProcessingOrders={newProcessingOrders}
              />
            )}

            {!getOrdersQuery.isError && !getOrdersQuery.isLoading && orders.length ? (
              <OrderTable
                headings={headings(selectedTab, handleResultError, newProcessingOrders)}
                results={orders}
                callBackFunction={(id) => changeOrderDetails(id)}
                callBackField={'coop_id'}
                selectedTab={selectedTab}
                newProcessingOrders={newProcessingOrders}
              />
            ) : (
              <div className="tableEmptyContainer">
                <EmptyIcon className="emptyIcon" />
                <h2 className="tableEmptyHeader">You have no orders in this status</h2>
              </div>
            )}
          </Col>
        </Row>
      </PageWrapper>
      {selectedOrders.length ? <BulkOperationBar onError={handleBulkOperationError} /> : null}
      <div
        className={['bulkErrorSnackbar', showBulkActionError && 'show'].filter((c) => c).join(' ')}
      >
        <Snackbar
          shortText="Unable to complete bulk status change"
          longText="There has been an error with 1 or more orders updating. Please try again."
          buttonText="Dismiss"
          onClick={() => setShowBulkActionError(false)}
        />
      </div>
    </>
  );
};

export default OrdersPage;
