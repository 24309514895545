/*
 * Wraps amazon-cognito-identity-js functions with promises
 * for easier use and testing
 */

import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserPool,
  CookieStorage,
} from 'amazon-cognito-identity-js';

const userPool = new CognitoUserPool({
  UserPoolId: process.env.REACT_APP_USER_POOL_ID,
  ClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
  Storage: new CookieStorage({
    domain: process.env.REACT_APP_DOMAIN,
    sameSite: 'lax',
  }),
});

export const getCurrentUser = () => userPool.getCurrentUser();

export const completeFirstLoginPasswordSet = async (cognitoUser, newPassword) => {
  return new Promise((resolve, reject) => {
    cognitoUser.completeNewPasswordChallenge(
      newPassword,
      {},
      {
        onSuccess: (session) => resolve(session),
        onFailure: (e) => reject(e),
      }
    );
  });
};

export const authenticateUser = (username, password) => {
  const authDetails = new AuthenticationDetails({ Username: username, Password: password });
  const cognitoUser = new CognitoUser({
    Username: username,
    Pool: userPool,
    Storage: new CookieStorage({ domain: process.env.REACT_APP_DOMAIN }),
  });
  cognitoUser.setAuthenticationFlowType('CUSTOM_AUTH');

  return new Promise((resolve, reject) =>
    cognitoUser.authenticateUser(authDetails, {
      onSuccess: (session) => resolve({ result: 'SUCCESS', data: session }),
      onFailure: (e) => reject(e),
      newPasswordRequired: () => resolve({ result: 'NEW_PASSWORD', data: cognitoUser }),
      customChallenge: () => resolve({ result: 'MFA', data: cognitoUser }),
    })
  );
};

export const sendCustomChallengeAnswer = (cognitoUser, challengeAnswer) => {
  return new Promise((resolve, reject) =>
    cognitoUser.sendCustomChallengeAnswer(challengeAnswer, {
      onSuccess: (session) => resolve({ result: 'SUCCESS', data: session }),
      onFailure: (e) => reject(e),
      customChallenge: () => resolve({ result: 'MFA', data: cognitoUser }),
    })
  );
};

export const requestVerificationCodeForgottenPassword = (username) => {
  const cognitoUser = new CognitoUser({
    Username: username,
    Pool: userPool,
    Storage: new CookieStorage({ domain: process.env.REACT_APP_DOMAIN }),
  });

  return new Promise((resolve, reject) =>
    cognitoUser.forgotPassword({
      onSuccess: (session) => resolve(session),
      onFailure: (e) => reject(e),
      inputVerificationCode: () => resolve(),
    })
  );
};

export const updateForgottenPassword = (username, verificationCode, newPassword) => {
  const cognitoUser = new CognitoUser({
    Username: username,
    Pool: userPool,
    Storage: new CookieStorage({ domain: process.env.REACT_APP_DOMAIN }),
  });

  return new Promise((resolve, reject) => {
    cognitoUser.confirmPassword(verificationCode, newPassword, {
      onSuccess: () => resolve(),
      onFailure: (e) => reject(e),
    });
  });
};

export const globalSignOut = (cognitoUser) =>
  new Promise((resolve, reject) =>
    cognitoUser.globalSignOut({
      onSuccess: (msg) => resolve(msg),
      onFailure: (e) => reject(e),
    })
  );

export const getSession = (cognitoUser) =>
  new Promise((resolve, reject) =>
    cognitoUser.getSession((e, session) => {
      if (session) {
        resolve(session);
      } else {
        reject(e);
      }
    })
  );

export const refreshSession = (cognitoUser, refreshToken) =>
  new Promise((resolve, reject) =>
    cognitoUser.refreshSession(refreshToken, (e, session) => {
      if (session) {
        resolve(session);
      } else {
        reject(e);
      }
    })
  );
