import React, { useState } from 'react';
import { Card, Row, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ReactGA from 'react-ga4';
import { Dropdown } from '../../Components/Atoms';
import Error from '../../Components/Error/Error';
import LoadingWrapper from '../../Components/LoadingWrapper/LoadingWrapper';

import pharmacySlice from '../../redux/pharmacySlice';
import './ReportsPage.css';
import ReportsPageOptions from './ReportsPageOptions';

import ReportsTable from './ReportsTable/ReportsTable';
import { headings } from './ReportsTable/headings';
import PageWrapper from '../../Components/PageWrapper/PageWrapper';
import ReportsCardContainer from './ReportsCardContainer/ReportsCardContainer';

const ReportsPage = () => {
  const [selectedDateRange, setSelectedDateRange] = useState(ReportsPageOptions.dateOptions[0]);

  const [reportsQuery, setReportsQuery] = useState({
    patient_type: 'all_patients',
    sort: 'last_name',
    direction: 'asc',
    limit: 10,
    offset: 0,
  });

  const [showReportsTable, setShowReportsTable] = useState(false);

  const pharmacyReportsQuery = pharmacySlice.endpoints.getPharmacyReports.useQuery(reportsQuery);
  const pharmacyMemberReportsQuery =
    pharmacySlice.endpoints.getPharmacyMemberReports.useQuery(reportsQuery);

  const DropdownWithCallback = React.forwardRef(({ onClick }, ref) => (
    <Dropdown
      options={ReportsPageOptions.dateOptions}
      optionsDisplay={ReportsPageOptions.dateOptions.map((dateOption) => dateOption.display)}
      value={selectedDateRange.display}
      onChange={(selectedDropdownValue) => {
        ReactGA.event({
          category: 'Reports',
          action: 'Date_Dropdown_Click',
        });
        ReactGA.event({
          category: 'Reports',
          action: ReportsPageOptions.dateOptions[selectedDropdownValue].event,
        });
        setSelectedDateRange(ReportsPageOptions.dateOptions[selectedDropdownValue]);
        if (ReportsPageOptions.dateOptions[selectedDropdownValue].datePicker) {
          onClick();
        } else {
          setReportsQuery((prev) => {
            return {
              ...prev,
              start: ReportsPageOptions.dateOptions[selectedDropdownValue].start,
              end: ReportsPageOptions.dateOptions[selectedDropdownValue].end,
            };
          });
        }
      }}
      className="dateDropdownButton"
      ref={ref}
    />
  ));

  const displayPage =
    pharmacyMemberReportsQuery.currentData &&
    !pharmacyMemberReportsQuery.isError &&
    pharmacyReportsQuery.currentData &&
    !pharmacyReportsQuery.isError;

  return (
    <PageWrapper id="ReportsPage" className="reportsContainer" tabIndex="0">
      <LoadingWrapper
        isLoading={pharmacyReportsQuery.isFetching || pharmacyMemberReportsQuery.isFetching}
      >
        <div className="reportsContent">
          {displayPage ? (
            <>
              <Row className="mx-0">
                <h1 className="mb-3">Reports</h1>
              </Row>
              <Row className="mx-0">
                <DatePicker
                  selected={selectedDateRange.start}
                  onChange={(changedDate) => {
                    const [start, end] = changedDate;
                    setSelectedDateRange({ ...selectedDateRange, start: start, end: end });
                    if (start && end) {
                      setReportsQuery((prev) => {
                        return { ...prev, start: start, end: end };
                      });
                    }
                  }}
                  startDate={selectedDateRange.start}
                  endDate={selectedDateRange.end}
                  selectsRange
                  customInput={<DropdownWithCallback />}
                  monthsShown={2}
                  wrapperClassName="datePickerWrapper"
                />
                {selectedDateRange.start && selectedDateRange.end && (
                  <p className="dateRangeText">
                    {selectedDateRange.start.toLocaleDateString('en-GB')}
                    {' - '}
                    {selectedDateRange.end.toLocaleDateString('en-GB')}
                  </p>
                )}
              </Row>
              <Row id="ReportsFilterContainer" className="mx-0 mt-3 flex-column">
                <ToggleButtonGroup
                  type="radio"
                  name="radio"
                  className="d-block"
                  defaultValue={reportsQuery.patient_type}
                  onChange={(e) => {
                    ReactGA.event({
                      category: 'Reports',
                      action: ReportsPageOptions.filterOptions[e].event.click,
                    });
                    ReactGA.event({
                      category: 'Reports',
                      action: ReportsPageOptions.filterOptions[e].event.view,
                    });
                    setReportsQuery((prev) => {
                      return {
                        ...prev,
                        patient_type: ReportsPageOptions.filterOptions[e].name,
                      };
                    });
                  }}
                >
                  {Object.entries(ReportsPageOptions.filterOptions).map((option) => {
                    const [key, value] = option;
                    return (
                      <ToggleButton key={key} value={key} name={key} className="px-4">
                        {value.display}
                      </ToggleButton>
                    );
                  })}
                </ToggleButtonGroup>
                <p className="mt-3 mb-0 d-block">
                  {ReportsPageOptions.filterOptions[reportsQuery.patient_type].description}
                </p>
              </Row>

              {showReportsTable && (
                <ReportsTable
                  headings={headings(reportsQuery.patient_type)}
                  results={pharmacyMemberReportsQuery?.currentData?.patients || []}
                  totalRows={pharmacyMemberReportsQuery?.currentData?.total_patients || 0}
                  direction={reportsQuery.direction}
                  limit={reportsQuery.limit}
                  queryOffset={reportsQuery.offset}
                  setReportsQuery={setReportsQuery}
                  setShowReportsTable={setShowReportsTable}
                  icon={ReportsPageOptions.card.patients[reportsQuery.patient_type].icon}
                  title={ReportsPageOptions.card.patients[reportsQuery.patient_type].title}
                  value={pharmacyReportsQuery.currentData.users_with_selected_pharmacy || 0}
                  text={ReportsPageOptions.card.patients[reportsQuery.patient_type].text}
                />
              )}

              {!showReportsTable && (
                <ReportsCardContainer
                  patient_type={reportsQuery.patient_type}
                  setShowReportsTable={setShowReportsTable}
                  pharmacyData={pharmacyReportsQuery.currentData}
                />
              )}
            </>
          ) : (
            <Card>
              <Error />
            </Card>
          )}
        </div>
      </LoadingWrapper>
    </PageWrapper>
  );
};

export default ReportsPage;
