import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route as ReactRoute, useHistory } from 'react-router-dom';
import Footer from '../../pages/layout/Footer';
import HeaderBar from '../../pages/layout/HeaderBar';
import { selectAuthAuthenticated } from '../../redux/authSlice';
import './Route.css';
import { ROUTES } from '../../constants';

const Route = ({ path, title, secured = false, exact = false, children }) => {
  const authenticated = useSelector(selectAuthAuthenticated);
  const history = useHistory();

  useEffect(() => {
    if (secured && !authenticated) {
      history.push(ROUTES.LOGIN);
    }
  }, [secured, authenticated, history]);

  document.title = `Hey Pharmacist Portal${title && ' - ' + title}`;

  return (
    <ReactRoute
      path={path}
      exact={exact}
      render={() => (
        <div className="page-container">
          <HeaderBar />
          <div className="page-content-container">{(authenticated || !secured) && children}</div>
          <Footer />
        </div>
      )}
    />
  );
};

Route.propTypes = {
  path: PropTypes.string,
  secured: PropTypes.bool,
  title: PropTypes.string,
  exact: PropTypes.bool,
  children: PropTypes.any,
};

export default Route;
