import React, { useState, useEffect } from 'react';
import { Row, Form } from 'react-bootstrap';
import { Button, Dropdown } from '../../../Components/Atoms';
import './TabContent.css';
import ReactGA from 'react-ga4';
import { GOOGLE_ANALYTICS_CATEGORY, SETTING_TYPES } from '../../../constants';

const milesDisplay = (miles) => {
  const milesSuffix = miles === 1 ? '' : 's';
  return miles + ' mile' + milesSuffix;
};

const TabContent = ({
  currentSettingsTab,
  pharmacySettingsData,
  setOverlayOpen,
  updatePharmacySettings,
}) => {
  const [selectedDeliveryRadius, setSelectedDeliveryRadius] = useState(
    pharmacySettingsData.pharmacy_settings?.selected_pharmacy_users_delivery_radius
  );
  const [directDeliveryRadius, setDirectDeliveryRadius] = useState(
    pharmacySettingsData.pharmacy_settings?.direct_users_delivery_radius
  );

  useEffect(() => {
    if (pharmacySettingsData) {
      setSelectedDeliveryRadius(
        pharmacySettingsData.pharmacy_settings?.selected_pharmacy_users_delivery_radius
      );
      setDirectDeliveryRadius(pharmacySettingsData.pharmacy_settings?.direct_users_delivery_radius);
    }
  }, [pharmacySettingsData]);

  if (currentSettingsTab === SETTING_TYPES.InStoreDelivery) {
    if (pharmacySettingsData.pharmacy_settings.delivery_available) {
      return (
        <Form>
          <Row className="tabHeaderContainer">
            <h3 className="sub-heading-3 mb-0">Your patients</h3>
            <div className="d-flex">
              <p
                className={`optionSwitchLabel ${
                  pharmacySettingsData.pharmacy_settings.delivery_enabled ? 'active' : ''
                }`}
              >
                {pharmacySettingsData.pharmacy_settings.delivery_enabled ? 'Active' : 'Deactivated'}
              </p>
              <Form.Check
                type="switch"
                id="inStore_Switch"
                className="optionSwitchToggle"
                label={pharmacySettingsData.pharmacy_settings.delivery_enabled ? 'ON' : 'OFF'}
                checked={pharmacySettingsData.pharmacy_settings.delivery_enabled}
                onChange={() => setOverlayOpen(true)}
              />
            </div>
          </Row>
          {pharmacySettingsData.pharmacy_settings.delivery_enabled ? (
            <>
              <Row>
                <p>
                  You currently have delivery active for patients who signed up to Hey Pharmacist
                  whilst in your store, either via QR or ODS code.
                </p>
              </Row>
              <Row>
                <p className="title-4">Delivery radius</p>
              </Row>
              <Row>
                <p>Select the distance from your store that you’d like to offer delivery.</p>
              </Row>
              <Row>
                <Dropdown
                  options={pharmacySettingsData.selected_pharmacy_users_delivery_radius_options}
                  optionsDisplay={pharmacySettingsData.selected_pharmacy_users_delivery_radius_options.map(
                    (option) => milesDisplay(option)
                  )}
                  icons={pharmacySettingsData.selected_pharmacy_users_delivery_radius_options.map(
                    (option) =>
                      option === selectedDeliveryRadius ? 'radioButtonFilled' : 'radioButtonEmpty'
                  )}
                  value={milesDisplay(selectedDeliveryRadius)}
                  onChange={(selectedDropdownValue) => {
                    setSelectedDeliveryRadius(
                      pharmacySettingsData.selected_pharmacy_users_delivery_radius_options[
                        selectedDropdownValue
                      ]
                    );
                  }}
                  className="radiusDropdownButton"
                />
                <Button
                  onClick={() =>
                    updatePharmacySettings({
                      selected_pharmacy_users_delivery_radius: selectedDeliveryRadius,
                    })
                  }
                  variant="primary"
                >
                  Save
                </Button>
              </Row>
            </>
          ) : (
            <Row>
              <p>
                Delivery is currently deactivated for patients who signed up in store, either via QR
                or ODS code.
              </p>
            </Row>
          )}
        </Form>
      );
    } else {
      return (
        <Form>
          <Row className="tabHeaderContainer">
            <h3 className="sub-heading-3 mb-0">Coming Soon</h3>
          </Row>
          <Row>
            <p>
              This feature is in the final stages of development and will be coming soon. <br />
              We will be in contact as soon as this is available for you.
            </p>
          </Row>
        </Form>
      );
    }
  }
  if (currentSettingsTab === SETTING_TYPES.MarketingPatients) {
    if (pharmacySettingsData.pharmacy_settings.direct_users_delivery_available) {
      return (
        <Form>
          <Row className="tabHeaderContainer">
            <h3 className="sub-heading-3 mb-0">Marketing patients</h3>
            <div className="d-flex">
              <p
                className={`optionSwitchLabel ${
                  pharmacySettingsData.pharmacy_settings.direct_users_delivery_enabled
                    ? 'active'
                    : ''
                }`}
              >
                {pharmacySettingsData.pharmacy_settings.direct_users_delivery_enabled
                  ? 'Active'
                  : 'Deactivated'}
              </p>
              <Form.Check
                type="switch"
                id="inStore_Switch"
                className="optionSwitchToggle"
                label={
                  pharmacySettingsData.pharmacy_settings.direct_users_delivery_enabled
                    ? 'ON'
                    : 'OFF'
                }
                checked={pharmacySettingsData.pharmacy_settings.direct_users_delivery_enabled}
                onChange={() => setOverlayOpen(true)}
              />
            </div>
          </Row>
          {pharmacySettingsData.pharmacy_settings.direct_users_delivery_enabled ? (
            <>
              <Row>
                <p>
                  Delivery is currently activated for patients who signed up to Hey Pharmacist
                  online and selected your store for delivery.
                </p>
                {pharmacySettingsData.pharmacy_settings.dsp_exclusion && (
                  <p>
                    Your pharmacy has the postage exclusion zone activated. Meaning patients within
                    a 1 mile radius will not have Royal Mail postage as an option.
                  </p>
                )}
              </Row>
              <Row>
                <p className="title-4">Delivery radius</p>
              </Row>
              <Row>
                <p>Select the distance from your store that you’d like to offer delivery.</p>
              </Row>
              <Row>
                <Dropdown
                  options={pharmacySettingsData.direct_users_delivery_radius_options}
                  optionsDisplay={pharmacySettingsData.direct_users_delivery_radius_options.map(
                    (option) => milesDisplay(option)
                  )}
                  icons={pharmacySettingsData.direct_users_delivery_radius_options.map((option) =>
                    option === directDeliveryRadius ? 'radioButtonFilled' : 'radioButtonEmpty'
                  )}
                  value={milesDisplay(directDeliveryRadius)}
                  onChange={(selectedDropdownValue) => {
                    setDirectDeliveryRadius(
                      pharmacySettingsData.direct_users_delivery_radius_options[
                        selectedDropdownValue
                      ]
                    );
                  }}
                  className="radiusDropdownButton"
                />
                <Button
                  onClick={() =>
                    updatePharmacySettings({
                      direct_users_delivery_radius: directDeliveryRadius,
                    })
                  }
                  variant="primary"
                >
                  Save
                </Button>
              </Row>
            </>
          ) : (
            <Row>
              <p>
                Delivery is currently deactivated for patients who signed up to Hey Pharmacist
                online and selected your store for delivery.
              </p>
            </Row>
          )}
        </Form>
      );
    } else {
      return (
        <Form>
          <Row className="tabHeaderContainer">
            <h3 className="sub-heading-3 mb-0">Marketing patients</h3>
          </Row>
          <Row>
            <p>
              You are not currently able to offer delivery to patients who didn’t sign up to you
              in-store. If you are interested in being able to offer delivery to new patients
              online, please speak to your Numark sales representative.
            </p>
          </Row>
        </Form>
      );
    }
  }

  if (currentSettingsTab === SETTING_TYPES.OrderUpdates) {
    return (
      <Form>
        <Row className="tabHeaderContainer">
          <h3 className="sub-heading-3 mb-0">Order Updates</h3>
          <div className="d-flex">
            <p
              className={`optionSwitchLabel ${
                pharmacySettingsData.pharmacy_settings.order_notifications_enabled ? 'active' : ''
              }`}
            >
              {pharmacySettingsData.pharmacy_settings.order_notifications_enabled
                ? 'Active'
                : 'Deactivated'}
            </p>
            <Form.Check
              type="switch"
              id="inStore_Switch"
              className="optionSwitchToggle"
              label={
                pharmacySettingsData.pharmacy_settings.order_notifications_enabled ? 'ON' : 'OFF'
              }
              checked={pharmacySettingsData.pharmacy_settings.order_notifications_enabled}
              onChange={() => {
                ReactGA.event({
                  category: GOOGLE_ANALYTICS_CATEGORY.SETTINGS,
                  action: 'Settings_Order_Update_Toggle_Click',
                });
                setOverlayOpen(true);
              }}
            />
          </div>
        </Row>
        {pharmacySettingsData.pharmacy_settings.order_notifications_enabled ? (
          <>
            <Row>
              <p>
                Emails and app notifications are currently activated for patients who order to your
                pharmacy.
              </p>
              <p>
                Patients are being notified on their order's progress and will be told when it's
                ready to collect once you update the Portal. If you don't update the Portal,
                patients will not know when their order is ready.
              </p>
            </Row>
          </>
        ) : (
          <Row>
            <p>
              Emails and app notifications are deactivated for patients who order to your pharmacy.
            </p>
            <p>
              Patients are not being notified on their order's progress and will be asked to contact
              your pharmacy to find out when their order is ready to collect.
            </p>
          </Row>
        )}
      </Form>
    );
  }
};
export default TabContent;
