import React from 'react';
import ReactGA from 'react-ga4';
import { Modal } from '../../../Components/Molecules';
import './SettingOverlay.css';
import { GOOGLE_ANALYTICS_CATEGORY, SETTING_TYPES } from '../../../constants';

const SettingOverlay = ({
  currentSettingsTab,
  setOverlayOpen,
  pharmacySettings,
  updatePharmacySettings,
}) => {
  if (
    (currentSettingsTab === SETTING_TYPES.InStoreDelivery && pharmacySettings.delivery_enabled) ||
    (currentSettingsTab === SETTING_TYPES.MarketingPatients &&
      pharmacySettings.direct_users_delivery_enabled)
  ) {
    return (
      <Modal
        label="Are you sure you want to turn off delivery?"
        onClose={() => setOverlayOpen(false)}
        secondaryButtonLabel="Cancel"
        variant="primary"
        secondaryButtonCallback={() => setOverlayOpen(false)}
        primaryButtonLabel="Turn off delivery"
        primaryButtonCallback={() => {
          if (currentSettingsTab === SETTING_TYPES.InStoreDelivery) {
            ReactGA.event({
              category: 'Settings',
              action: 'Settings_Your_Patients_delivery',
            });
            updatePharmacySettings({
              delivery_enabled: false,
            }).then(setOverlayOpen(false));
          } else if (currentSettingsTab === SETTING_TYPES.MarketingPatients) {
            ReactGA.event({
              category: GOOGLE_ANALYTICS_CATEGORY.SETTINGS,
              action: 'Settings_Marketing_Patients_Delivery',
            });
            updatePharmacySettings({
              direct_users_delivery_enabled: false,
            }).then(setOverlayOpen(false));
          }
        }}
      >
        <p>
          This will deactivate delivery for patients going forward, but won't affect current pending
          deliveries.
        </p>
      </Modal>
    );
  }

  if (currentSettingsTab === SETTING_TYPES.InStoreDelivery && !pharmacySettings.delivery_enabled) {
    return (
      <Modal
        label="Are you sure you want to turn on delivery?"
        onClose={() => setOverlayOpen(false)}
        secondaryButtonLabel="Cancel"
        variant="primary"
        secondaryButtonCallback={() => setOverlayOpen(false)}
        primaryButtonLabel="Turn on delivery"
        primaryButtonCallback={() => {
          ReactGA.event({
            category: GOOGLE_ANALYTICS_CATEGORY.SETTINGS,
            action: 'Settings_Your_Patients_delivery',
          });
          updatePharmacySettings({
            delivery_enabled: true,
          }).then(setOverlayOpen(false));
        }}
      >
        <p>This will activate free delivery for patients within the radius of your pharmacy.</p>
      </Modal>
    );
  }

  if (
    currentSettingsTab === SETTING_TYPES.MarketingPatients &&
    !pharmacySettings.direct_users_delivery_enabled
  ) {
    return (
      <Modal
        label="Terms of Service"
        onClose={() => setOverlayOpen(false)}
        secondaryButtonLabel="Cancel"
        variant="primary"
        secondaryButtonCallback={() => setOverlayOpen(false)}
        primaryButtonLabel="Agree & activate"
        primaryButtonCallback={() => {
          ReactGA.event({
            category: GOOGLE_ANALYTICS_CATEGORY.SETTINGS,
            action: 'Settings_Marketing_Patients_Delivery',
          });
          updatePharmacySettings({
            direct_users_delivery_enabled: true,
          }).then(setOverlayOpen(false));
        }}
      >
        <p>
          Before you can turn on delivery for marketing patients, you are required to read and agree
          to the following terms of service:
          <ol className="settingOverlayList">
            <li className="settingOverlayListItem">
              I agree that all patient orders will be dispatched to the patient within 7 days of the
              prescription being issued
            </li>
            <li className="settingOverlayListItem">
              I agree to use the portal to update the patient through each stage of the dispatch
              process from dispensing through to completed
            </li>
            <li className="settingOverlayListItem">
              I agree that there will be no delivery charge when delivering prescriptions to
              patients
            </li>
          </ol>
        </p>
      </Modal>
    );
  }

  if (
    currentSettingsTab === SETTING_TYPES.OrderUpdates &&
    !pharmacySettings.order_notifications_enabled
  ) {
    return (
      <Modal
        label="Are you sure you want to turn on updates for collection?"
        onClose={() => setOverlayOpen(false)}
        secondaryButtonLabel="Cancel"
        variant="primary"
        secondaryButtonCallback={() => {
          ReactGA.event({
            category: GOOGLE_ANALYTICS_CATEGORY.SETTINGS,
            action: 'Settings_Order_Update_Confirm_Cancel',
          });
          setOverlayOpen(false);
        }}
        primaryButtonLabel="Turn on"
        primaryButtonCallback={() => {
          ReactGA.event({
            category: GOOGLE_ANALYTICS_CATEGORY.SETTINGS,
            action: 'Settings_Order_Update_Confirm_Turnon',
          });
          updatePharmacySettings({
            order_notifications_enabled: true,
          }).then(setOverlayOpen(false));
        }}
      >
        <p>
          Patients will be notified on their order's progress when you update their order status and
          will be told when their medication is ready to collect.
        </p>
      </Modal>
    );
  }

  if (
    currentSettingsTab === SETTING_TYPES.OrderUpdates &&
    pharmacySettings.order_notifications_enabled
  ) {
    return (
      <Modal
        label="Are you sure you want to turn off order updates for collection?"
        onClose={() => setOverlayOpen(false)}
        secondaryButtonLabel="Cancel"
        variant="primary"
        secondaryButtonCallback={() => {
          ReactGA.event({
            category: GOOGLE_ANALYTICS_CATEGORY.SETTINGS,
            action: 'Settings_Order_Update_Confirm_Cancel',
          });
          setOverlayOpen(false);
        }}
        primaryButtonLabel="Turn off"
        primaryButtonCallback={() => {
          ReactGA.event({
            category: GOOGLE_ANALYTICS_CATEGORY.SETTINGS,
            action: 'Settings_Order_Update_Confirm_Turnoff',
          });
          updatePharmacySettings({
            order_notifications_enabled: false,
          }).then(setOverlayOpen(false));
        }}
      >
        <p>
          From now on, patients will not be notified of their order's progress and will be told to
          contact your pharmacy before collecting their medication. Delivery is not affected.
        </p>
      </Modal>
    );
  }
};

export default SettingOverlay;
