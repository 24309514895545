import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/colours.css';
import './styles/fonts.css';
import './styles/typography.css';
import './index.css';
import AppRouter from './AppRouter';
import store from './redux/store';
import ReactGA from 'react-ga4';
ReactGA.initialize('G-FCX42R9BQH');

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <AppRouter />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
