import { useEffect, useState } from 'react';
import { Container, Form } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { Button, Input } from '../../Components/Atoms';
import Error from '../../Components/Error/Error';
import LoadingIndicator from '../../Components/LoadingIndicator/LoadingIndicator';
import { COGNITO_AUTH_RESULTS, ERROR_MESSAGES, ROUTES } from '../../constants';
import { sendAuthenticationVerificationCode, signIn, useFirstTimeLogin } from '../../services/auth';
import './LoginPage.css';

const translations = require('../../assets/translations.json');
const STAGES = {
  PASSWORD: 'PASSWORD',
  VERIFICATION: 'VERIFICATION',
};

const LoginPage = () => {
  const history = useHistory();
  const { setFirstTimeUser } = useFirstTimeLogin();
  const [heading, setHeading] = useState('Log In');
  const [stage, setStage] = useState(STAGES.PASSWORD);
  const [loading, setLoading] = useState(false);
  const [sessionData, setSessionData] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [isError, setIsError] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [hasRedirectErrors, setHasRedirectErrors] = useState(false);

  let redirectParams = new URLSearchParams(window.location.search).get('error');
  useEffect(() => {
    if (stage === STAGES.PASSWORD) {
      setHeading('Log In');
      setHasRedirectErrors(!!redirectParams);
    } else if (stage === STAGES.VERIFICATION) {
      setHeading('Enter code to log in');
    }
  }, [stage, redirectParams]);

  const onSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage('');
    setIsError(false);

    setLoading(true);

    try {
      let session;
      if (stage === STAGES.PASSWORD) {
        ReactGA.event({
          category: 'Login',
          action: 'Login_Continue',
        });
        session = await signIn(username, password);
      } else {
        ReactGA.event({
          category: 'Login',
          action: 'MFA_Continue',
        });
        session = await sendAuthenticationVerificationCode(sessionData, verificationCode).catch(
          (err) => {
            setDisableSubmitButton(true);
            if (err.message && ERROR_MESSAGES[err.message]) {
              setErrorMessage(ERROR_MESSAGES[err.message]);
            } else {
              setErrorMessage(ERROR_MESSAGES.MFA_FAIL_GENERIC);
            }
          }
        );
        if (session.result === COGNITO_AUTH_RESULTS.MFA) {
          setErrorMessage(ERROR_MESSAGES.MFA_INCORRECT_CODE);
        }
      }

      if (session.result === COGNITO_AUTH_RESULTS.SUCCESS) {
        history.push(ROUTES.ORDERS);
      } else if (session.result === COGNITO_AUTH_RESULTS.MFA) {
        setStage(STAGES.VERIFICATION);
        setSessionData(session.data);
      } else if (session.result === COGNITO_AUTH_RESULTS.NEW_PASSWORD) {
        setFirstTimeUser(session.data);
        history.push(ROUTES.FIRST_TIME_SETUP);
      }
    } catch (err) {
      setIsError(true);
      console.error(err);
    }

    setLoading(false);
  };

  return (
    <>
      <Container className="login-container">
        {hasRedirectErrors && (
          <div className="login-session-expired-error-container">
            <Error icon="alertCircle">{translations.LoginPage.sessionExpired}</Error>
          </div>
        )}
        <h1>{heading}</h1>
        <Form onSubmit={onSubmit}>
          {stage === STAGES.PASSWORD && (
            <>
              <Input
                inputName="email-address"
                label="Email address"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                data-testid="username"
                type="text"
                autoComplete="username"
                disabled={loading}
              />
              <Input
                inputName="password"
                label="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                autoComplete="current-password"
                disabled={loading}
                data-testid="password"
              />
              <div className="mb-4">
                <Link
                  onClick={() =>
                    ReactGA.event({
                      category: 'Login',
                      action: 'Forgottten_Password_Click',
                    })
                  }
                  to={ROUTES.FORGOTTEN_PASSWORD}
                  className="link-blue"
                >
                  Forgotten your password?
                </Link>
              </div>
              {isError && (
                <div className="login-error-container">
                  <Error>Incorrect email and/or password, please try again</Error>
                </div>
              )}
              <SubmitButton text="Continue" loading={loading} disabled={!username || !password} />
            </>
          )}
          {stage === STAGES.VERIFICATION && (
            <div className="verification-code-container">
              <p>
                For security, we have sent a code to your email address:
                <br />
                {username}
              </p>
              <p>Please check your email and enter the code below:</p>
              <Input
                inputName="verification-code"
                data-testid="verification-code"
                type="text"
                autoComplete="off"
                disabled={loading}
                value={verificationCode}
                placeholder="Enter code, e.g. 123456"
                onInput={(e) => {
                  setVerificationCode(e.target.value);
                }}
              />
              {errorMessage ? (
                <div className="login-error-container">
                  <Error>{errorMessage}</Error>
                </div>
              ) : null}
              <SubmitButton
                text="Continue"
                loading={loading}
                disabled={!verificationCode || disableSubmitButton}
              />
            </div>
          )}
        </Form>
      </Container>
    </>
  );
};

const SubmitButton = ({ loading, disabled, text }) => (
  <div className="login-button-container">
    {loading ? (
      <LoadingIndicator />
    ) : (
      <Button type="submit" disabled={disabled} variant="primary">
        {text}
      </Button>
    )}
  </div>
);

export default LoginPage;
