import React, { useState, useRef } from 'react';
import { Icon } from '../../../../../Components/Atoms';
import '../TableCell.css';
import './ContactNo.css';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import ReactGA from 'react-ga4';

const ContactNo = (props) => {
  const { phone_number, primary_contact_details, primary_patient } = props?.data;
  const name = `${primary_contact_details?.first_name} ${primary_contact_details?.last_name}` || '';
  const [show, setShow] = useState(false);
  const handleOnMouseEnter = (sendGAEvent) => {
    setShow(true);
    if (sendGAEvent) {
      ReactGA.event({
        category: 'Reports',
        action: 'Reports_Your_Patients_List_Hover_Linked_Account_Icon',
      });
    }
  };

  const handleOnMouseLeave = () => {
    setShow(false);
  };

  const ref = useRef(null);
  if (primary_patient) {
    return (
      <div className="tableCell">
        <p>{phone_number ? phone_number : 'None'}</p>
      </div>
    );
  }

  return (
    <OverlayTrigger
      show={show}
      key="bottom"
      placement="bottom"
      container={ref}
      overlay={
        <Tooltip
          id="reportsTooltip"
          onMouseEnter={() => handleOnMouseEnter(false)}
          onMouseLeave={handleOnMouseLeave}
        >
          <p>
            This is a contact number for the account holder, {name}, that this patient is linked to
          </p>
        </Tooltip>
      }
    >
      <div
        ref={ref}
        className="tableCell"
        onMouseEnter={() => handleOnMouseEnter(true)}
        onMouseLeave={handleOnMouseLeave}
        data-testid="contact-no"
      >
        <p>{phone_number ? phone_number : 'None'}</p>

        <Icon iconName="link" data-testid="contact-no-icon-link" />
      </div>
    </OverlayTrigger>
  );
};

export default ContactNo;
