import { useState } from 'react';
import validatePassword, { specialChars } from '../../helpers/passwordRequirementsValidator';
import {
  changeForgottenPassword,
  requestForgottenPasswordVerificationCode,
} from '../../services/auth';
import { Button, Input } from '../../Components/Atoms';
import LoadingIndicator from '../../Components/LoadingIndicator/LoadingIndicator';
import Error from '../../Components/Error/Error';
import './ForgottenPasswordForm.css';
import PasswordRequirements from './PasswordRequirements';

const VerificationCodeForm = ({ onSuccess, username }) => {
  const [errorMessage, setErrorMessage] = useState([]);
  const [regexChecks, setRegexChecks] = useState([
    { text: 'Be at least 8 characters.', valid: false },
    { text: 'Must include at least 1 number.', valid: false },
    { text: 'Must include at least 1 upper case letter.', valid: false },
    { text: 'Must include at least 1 lower case letter.', valid: false },
    { text: `Must include at least 1 special character: ${specialChars.join(' ')}`, valid: false },
  ]);
  const [verificationCode, setVerificationCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirm, setNewPasswordConfirm] = useState('');
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const resendVerification = () => {
    setLoading(true);
    setRegexChecks([
      { text: 'Be at least 8 characters.', valid: false },
      { text: 'Must include at least 1 number.', valid: false },
      { text: 'Must include at least 1 upper case letter.', valid: false },
      { text: 'Must include at least 1 lower case letter.', valid: false },
      {
        text: `Must include at least 1 special character: ${specialChars.join(' ')}`,
        valid: false,
      },
    ]);

    requestForgottenPasswordVerificationCode(username)
      .then((e) => {
        setErrorMessage([]);
      })
      .catch((e) => {
        if (e.name !== 'UserNotFoundException') {
          setErrorMessage([e.message]);
          setIsError(true);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const onInputChange = (newValue) => {
    const validationErrors = validatePassword(newValue);
    validationErrors.length && setRegexChecks(validationErrors);
  };
  const onSubmit = (event) => {
    event.preventDefault();

    if (newPassword !== newPasswordConfirm) {
      setErrorMessage(['The passwords you have entered do not match']);
      setIsError(true);
      return;
    }

    setLoading(true);
    setErrorMessage([]);
    changeForgottenPassword(username, verificationCode, newPassword)
      .then(() => onSuccess())
      .catch((e) => {
        if (e.name === 'UserNotFoundException') {
          setErrorMessage(['Unable to update password.']);
        } else {
          setErrorMessage([e.message]);
        }
        setIsError(true);
        setLoading(false);
      });
  };

  return (
    <>
      <h1 className="forgottenPasswordHeader">Reset Password</h1>
      <p className="forgottenPasswordText">
        To reset your password, we have sent a code to your email address:
        <br />
        {username}.
      </p>
      <p className="forgottenPasswordText">Please check your email and enter the code below:</p>
      <form className="forgottenPasswordForm">
        <Input
          inputName="verification-code"
          className="mb-3"
          data-testid="verification-code"
          type="text"
          placeholder="Enter code eg. 123456"
          autoComplete="one-time-code"
          onInput={(e) => {
            setVerificationCode(e.target.value);
          }}
        />
        <div className="resend-code-container">
          Didn't receive it?
          <p onClick={resendVerification} className="resend-code">
            Re-send code
          </p>
        </div>
        <PasswordRequirements requirements={regexChecks} />
        <Input
          inputName="new-password"
          className="mb-3"
          label={<>New Password</>}
          data-testid="new-password"
          type="password"
          autoComplete="new-password"
          onChange={(e) => {
            onInputChange(e.target.value);
            setNewPassword(e.target.value);
          }}
        />
        <Input
          inputName="new-password-confirm"
          className="mb-3"
          label={<>Re-enter New Password</>}
          data-testid="new-password-confirm"
          type="password"
          autoComplete="new-password"
          onChange={(e) => {
            setNewPasswordConfirm(e.target.value);
          }}
        />
        {isError ? (
          <div className="errors-container">
            <Error>{errorMessage}</Error>
          </div>
        ) : null}
        {loading ? (
          <LoadingIndicator />
        ) : (
          <>
            <Button
              variant="primary"
              data-testid="new-password-submit"
              type="submit"
              className="forgottenPasswordButton"
              onClick={onSubmit}
            >
              Save
            </Button>
          </>
        )}
      </form>
    </>
  );
};

export default VerificationCodeForm;
