import { useState } from 'react';
import ReactGA from 'react-ga4';
import validateEmail from '../../helpers/emailValidator';
import { requestForgottenPasswordVerificationCode } from '../../services/auth';
import { Button, Input } from '../../Components/Atoms';
import LoadingIndicator from '../../Components/LoadingIndicator/LoadingIndicator';
import './ForgottenPasswordForm.css';

const UsernameForm = ({ onSuccess }) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [username, setUsername] = useState('');
  const [loading, setLoading] = useState(false);

  const onSubmit = (event) => {
    event.preventDefault();
    setErrorMessage('');
    ReactGA.event({
      category: 'Login',
      action: 'Forgotten_Password_Resend_Code',
    });

    const validationError = validateEmail(username);
    if (validationError) {
      setErrorMessage(validationError);
      return;
    }
    setLoading(true);

    requestForgottenPasswordVerificationCode(username)
      .then(() => onSuccess(username))
      .catch((e) => {
        if (e.name === 'UserNotFoundException') {
          onSuccess(username);
        } else {
          setErrorMessage(e.message);
          setLoading(false);
        }
      });
  };

  return (
    <>
      <h1 className="forgottenPasswordHeader">Reset Password</h1>
      <p className="forgottenPasswordText">
        To reset your password, please enter your <b>pharmacy email</b> address to which your
        account is registered:
      </p>
      <form className="forgottenPasswordForm">
        <Input
          inputName="email-address"
          className="emailInput"
          label="Email address"
          data-testid="username"
          type="text"
          autoComplete="username"
          onInput={(e) => {
            setUsername(e.target.value);
          }}
        />
        {loading ? (
          <LoadingIndicator />
        ) : (
          <Button
            variant="primary"
            type="submit"
            className="forgottenPasswordButton"
            onClick={onSubmit}
          >
            Send Reset Code
          </Button>
        )}
      </form>
      {errorMessage && (
        <div className="pt-2" data-testid="errorMessage">
          There was an error when trying to send your verification code: {errorMessage}
        </div>
      )}
    </>
  );
};

export default UsernameForm;
