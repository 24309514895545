export const ROUTES = {
  REGISTER: '/register',
  LOGIN: '/login',
  FIRST_TIME_SETUP: '/first-time-setup',
  FORGOTTEN_PASSWORD: '/forgotten-password',
  ORDERS: '/',
  ORDER_DETAILS: '/order/:orderId',
  SETTINGS: '/settings',
  SERVICES: '/services',
  REPORTS: '/reports',
};

export const ERROR_MESSAGES = {
  PRINT: "Sorry, there's been a problem preparing for print, please try again later",
  REMINDER: "Sorry, there's been a problem sending a reminder, please try again later",
  STATUS: "Sorry, there's been a problem updating the status, please try again later",
  SEARCH: "Sorry, there's been a problem with search, please try again later",
  ORDER_LIST: "Sorry, there's been a problem refreshing the order list, please try again later",
  MFA_INCORRECT_CODE: 'Incorrect code, please try again',
  MFA_EXPIRED_CODE:
    'Sorry, the code you have entered has expired, please go back and login again to get a new code',
  MFA_FAIL_GENERIC:
    "Sorry, there's been a problem logging you in, please go back and try logging in again",
  REPORTS_LIST: "Sorry, there's been a problem refreshing the patient list, please try again later",
};

export const GOOGLE_ANALYTICS_CATEGORY = {
  SETTINGS: 'Settings',
  ORDERS_SCREEN: 'Orders Screen',
  ORDER_DETAILS: 'Order Details',
};

export const SETTING_TYPES = {
  InStoreDelivery: 'InStoreDelivery',
  MarketingPatients: 'MarketingPatients',
  OrderUpdates: 'OrderUpdates',
};

export const COGNITO_AUTH_RESULTS = {
  SUCCESS: 'SUCCESS',
  FAIL: 'FAIL',
  MFA: 'MFA',
  NEW_PASSWORD: 'NEW_PASSWORD',
};

export const COGNITO_AUTH_FAIL_REASONS = {
  MFA_EXPIRED_CODE: 'MFA_EXPIRED_CODE',
  MFA_MAX_ATTEMPTS_EXCEEDED: 'MFA_MAX_ATTEMPTS_EXCEEDED',
};

export const REPORTS_PAGE = {
  CHURN_ALERT_LEVEL: {
    0: {},
    1: {
      GA: {
        HOVER: 'Reports_Your_Patients_List_Hover_Amber',
        CLICK: 'Reports_Your_Patients_List_Dismiss_Click_Amber',
      },
      ICON: 'alertCircleOrange',
      WARNING: 'Amber',
    },
    2: {
      GA: {
        HOVER: 'Reports_Your_Patients_List_Hover_Red',
        CLICK: 'Reports_Your_Patients_List_Dismiss_Click_Red',
      },
      ICON: 'alertTriangleRed',
      WARNING: 'Red',
    },
  },
};
