import { useState } from 'react';
import { Button, Icon } from '../../../Components/Atoms';
import LoadingWrapper from '../../../Components/LoadingWrapper/LoadingWrapper';
import { refreshUserTokens } from '../../../services/auth';
import './ServicesContent.css';

const ServicesContent = ({ pharmacySettingsData, updatePharmacySettings }) => {
  const [loading, setLoading] = useState(false);

  const servicesUrl =
    process.env.REACT_APP_ENABLE_SERVICES_SSO === 'true'
      ? process.env.REACT_APP_SERVICES_SSO_URL
      : 'https://app.hashealth.com/users/sign_in';

  const handleClick = async (agreeToTerms) => {
    setLoading(true);

    if (agreeToTerms) {
      updatePharmacySettings({
        pharmacy_services_enabled: true,
      });
    }

    await refreshUserTokens();
    window.open(servicesUrl, '_blank', 'noreferrer');
    setLoading(false);
  };

  if (pharmacySettingsData.pharmacy_settings.pharmacy_services_available) {
    if (pharmacySettingsData.pharmacy_settings.pharmacy_services_enabled) {
      return (
        <>
          <h1>Manage your pharmacy services</h1>
          <p className="body-long-copy-book-2">
            We're partnered with Has Health to help you manage a range of services - including flu
            and pneumonia vaccinations - through Hey Pharmacist.
          </p>
          <p className="body-long-copy-book-2 mb-4">
            Following the secure link below will direct you to our partner website, where you can
            select and manage your pharmacy services.
          </p>
          <Button variant="primary" onClick={() => handleClick()}>
            <LoadingWrapper isLoading={loading}>
              Open services in Has Health{' '}
              <Icon iconName="externalLink" className="externalLinkIcon" />
            </LoadingWrapper>
          </Button>
        </>
      );
    } else {
      return (
        <>
          <h1>Accept pharmacy services T&C's</h1>
          <p className="body-long-copy-book-2">
            Before you can start using pharmacy services, you are required to read and accept to the
            following terms of service:
          </p>
          <div className="scrollable">
            <p>
              PLEASE READ THE TERMS OF THIS AGREEMENT CAREFULLY (THE "TERMS"). IF YOU CLICK ON THE
              'ACCEPT' BUTTON, YOU ARE AGREEING TO AND ACCEPTING THE TERMS ON BEHALF OF YOUR
              BUSINESS. THE TERMS WILL APPLY IN ADDITION TO YOUR NUMARK HASHEALTH PLAFORM AGREEMENT.
              BY CLICKING "ACCEPT", THE TERMS WILL BECOME LEGALLY BINDING ON YOUR BUSINESS AND YOUR
              BUSINESS WILL BE RESPONSIBLE FOR ENSURING ALL USERS COMPLY WITH THE TERMS. THEREFORE
              YOU MUST HAVE THE AUTHORITY TO ENTER INTO THIS AGREEMENT ON BEHALF OF YOUR BUSINESS.
              IF YOU DO NOT AGREE TO THE TERMS OR YOU ARE NOT AUTHORISED TO ACCEPT THE TERMS ON
              BEHALF OF YOUR BUSINESS, YOU MUST NOT CLICK THE ACCEPT BUTTON AND YOU ARE NOT ALLOWED
              TO USE OR ACCESS THIS SERVICE. YOU MAY PRINT THE WEB PAGE CONTAINING THE TERMS OR SAVE
              IT AS A FILE ON YOUR DEVICE. COPIES OF THE TERMS AND ALL VERSION CONTROLS ARE KEPT BY
              PHOENIX.
            </p>

            <p>BACKGROUND</p>
            <ol type="A">
              <li>
                The End User is either an affiliate of Phoenix Healthcare Distribution Limited
                ("Phoenix") or a Numark member. Phoenix and the Service Provider have entered into
                an agreement for the provision of the HasHealth Platform to Phoenix and its
                Affiliates (the "Master Agreement").
              </li>

              <li>
                The End User wishes to benefit from the terms of the Master Agreement, the Services
                and the HasHealth Platform.
              </li>

              <li>
                The Service Provider hereby agrees to grant the End User access to the Services
                subject to and in accordance with, the terms of this Agreement.
              </li>
            </ol>
            <p>The parties agree as follows:</p>
            <ol className="scrollableNumericList">
              <li className="scrollableNumericItem">
                <h4>DEFINITIONS AND INTERPRETATION</h4>
                <ol className="scrollableNumericList">
                  <li className="scrollableNumericItem">
                    <p>
                      Definitions <br />
                      In this Agreement, the following expressions have the following meanings:
                    </p>
                    <div className="row">
                      <h4 className="column">“Agreement”</h4>
                      <p className="column">
                        means this agreement, including all clauses and schedules hereto, as may be
                        amended by agreement of the parties from time to time in accordance with the
                        terms and conditions hereof.
                      </p>
                    </div>
                    <div className="row">
                      <h4 className="column">“Authorised User”</h4>
                      <p className="column">
                        means those customers, employees, volunteers, consultants and contractors of
                        the End User who are authorised to use the Software Services by the End
                        User.
                      </p>
                    </div>
                    <div className="row">
                      <h4 className="column">“Business Day”</h4>
                      <p className="column">
                        means a day (other than a Saturday or a Sunday or public holiday in the
                        United Kingdom) on which banks are open for business in London.
                      </p>
                    </div>
                    <div className="row">
                      <h4 className="column">“Confidential Information”</h4>
                      <p className="column">
                        means all information or documentation of a party that: (a) has been marked
                        “confidential” or with words of similar meaning, at the time of disclosure
                        by such party; (b) if disclosed orally or not marked confidential or with
                        words of similar meaning, was subsequently summarised in writing by the
                        disclosing entity and marked confidential or with words of similar meaning;
                        (c) any information or documentation that ought reasonably be treated as
                        confidential; or (d) any Confidential Information derived from information
                        of a party. <br />
                        The term Confidential Information does not include any information or
                        documentation that was: (a) already in the possession of the receiving
                        entity without an obligation of confidentiality; (b) developed independently
                        by the receiving entity, without violating the disclosing entity's
                        proprietary rights; (c) obtained from a source other than the disclosing
                        entity without an obligation of confidentiality; (d) publicly available when
                        received, or thereafter became publicly available (other than through any
                        unauthorised disclosure by, through or on behalf of, the receiving entity);
                        or (e) required to be disclosed by law, by any court of competent
                        jurisdiction or by any regulatory or administrative body.
                      </p>
                    </div>
                    <div className="row">
                      <h4 className="column">“Content”</h4>
                      <p className="column">
                        means any content on the HasHealth Platform provided by the End User
                        including any Authorised User(s).
                      </p>
                    </div>
                    <div className="row">
                      <h4 className="column">
                        “Controller”, “Data Processor” “processing” and “Data Subject”
                      </h4>
                      <p className="column">
                        shall have the same meaning as in the Data Protection Law.
                        <br />
                        In the case of the HasHealth platform these are: <br />
                        End User - Controller <br />
                        Service Provider - Data Processor
                      </p>
                    </div>
                    <div className="row">
                      <h4 className="column">“Data Protection Law”</h4>
                      <p className="column">
                        means (i) Regulation 2016/679 of the European Parliament and of the Council
                        on the protection of natural persons with regard to the Processing of
                        Personal Data and on the free movement of such data (General Data Protection
                        Regulation) (the "<b>EU GDPR</b>"); (ii) the EU GDPR as saved into United
                        Kingdom law by virtue of section 3 of the United Kingdom's European Union
                        (Withdrawal) Act 2018 (the "<b>UK GDPR</b>"); (iii) the EU e-Privacy
                        Directive (Directive 2002/58/EC); and (iv) any and all applicable national
                        data protection laws made under, pursuant to or that apply in conjunction
                        with any of (i), (ii) or (iii); in each case as may be amended or superseded
                        from time to time;
                      </p>
                    </div>
                    <div className="row">
                      <h4 className="column">“Effective Date”</h4>
                      <p className="column">
                        means the date of acceptance of this Agreement by the End User;
                      </p>
                    </div>
                    <div className="row">
                      <h4 className="column">“End User”</h4>
                      <p className="column">
                        means the organisation which contracts with Phoenix under the Numark
                        HeasHealth Platform Agreement and which uses the HasHealth Platform;
                      </p>
                    </div>
                    <div className="row">
                      <h4 className="column">“End User Contact”</h4>
                      <p className="column">
                        means an individual nominated by the End User as the key contact with the
                        Service Provider;
                      </p>
                    </div>
                    <div className="row">
                      <h4 className="column">“End User Data”</h4>
                      <p className="column">
                        means information relating to the End User's business, inputted by or on
                        behalf of the End User in the course of using the HasHealth Platform
                        including the Content but excluding any data, results, content, copyright,
                        database rights or other intellectual property owned by the Service
                        Provider.
                      </p>
                    </div>
                    <div className="row">
                      <h4 className="column">“Fees”</h4>
                      <p className="column">
                        Fees shall have the meaning given to it in clause 5.1
                      </p>
                    </div>
                    <div className="row">
                      <h4 className="column">“Good Industry Practice”</h4>
                      <p className="column">
                        means the exercise of that degree of skill, care, prudence, efficiency,
                        foresight and timeliness as would be expected from a company within the
                        industry sector.
                      </p>
                    </div>
                    <div className="row">
                      <h4 className="column">“HasHealth Platform”</h4>
                      <p className="column">
                        means the software as a service platform described in the Specification.
                      </p>
                    </div>
                    <div className="row">
                      <h4 className="column">“Intellectual Property Rights”</h4>
                      <p className="column">
                        means all patents, utility models, rights to inventions, copyright and
                        neighbouring and related rights, trade marks and service marks, business
                        names and domain names, rights in get- up and trade dress, goodwill and the
                        right to sue for passing off or unfair competition, rights in designs,
                        database rights, rights to use, and protect the confidentiality of,
                        confidential information (including know-how and trade secrets) and all and
                        other intellectual property rights, in each case whether registered or
                        unregistered and including all applications and rights to apply for and be
                        granted, renewals or extensions of, and rights to claim priority from, such
                        rights and all similar or equivalent rights or forms of protection that
                        subsist or will subsist now or in the future in any part of the world.
                      </p>
                    </div>
                    <div className="row">
                      <h4 className="column">“Numark HasHealth Platform Agreement”</h4>
                      <p className="column">
                        shall mean the agreement between Phoenix and the End User under which the
                        End User contracts for the HasHealth platform, as amended from time to time.
                      </p>
                    </div>
                    <div className="row">
                      <h4 className="column">“Personal Data”</h4>
                      <p className="column">
                        shall have the same meaning as in Data Protection Law.
                      </p>
                    </div>
                    <div className="row">
                      <h4 className="column">“Phoenix”</h4>
                      <p className="column">
                        shall have the meaning set out in the Background above.
                      </p>
                    </div>
                    <div className="row">
                      <h4 className="column">“Representative”</h4>
                      <p className="column">shall have the meaning given to it in clause 13.2</p>
                    </div>
                    <div className="row">
                      <h4 className="column">“Services”</h4>
                      <p className="column">has the meaning ascribed to that term in clause 2.</p>
                    </div>
                    <div className="row">
                      <h4 className="column">“Service Level”</h4>
                      <p className="column">
                        means the service level identified on the HasHealth platform as [weblink].
                      </p>
                    </div>
                    <div className="row">
                      <h4 className="column">"Service Provider"</h4>
                      <p className="column">
                        means Medihive UK Limited, a limited liability company incorporated in
                        England and Wales (registered no. 13834829), whose registered office is at
                        9th Floor, 3 Hardman Street, Manchester, M3 3HF, UK
                      </p>
                    </div>
                    <div className="row">
                      <h4 className="column">“Software”</h4>
                      <p className="column">
                        means the online software applications provided by the Service Provider as
                        part of the Software Services.
                      </p>
                    </div>
                    <div className="row">
                      <h4 className="column">“Software Services”</h4>
                      <p className="column">shall have the meaning given to it in clause 2.</p>
                    </div>
                    <div className="row">
                      <h4 className="column">“Specification”</h4>
                      <p className="column">
                        means the specification of the features of the HasHealth Platform available
                        under the Agreement as set out within the HasHealth Platform at [weblink].
                      </p>
                    </div>
                    <div className="row">
                      <h4 className="column">“Standard Support Hours”</h4>
                      <p className="column">
                        Means the hours between 09.00 to 17.00 on a Business Day.
                      </p>
                    </div>
                    <div className="row">
                      <h4 className="column">“Support Services”</h4>
                      <p className="column">shall have the meaning given to it in clause 2.</p>
                    </div>
                    <div className="row">
                      <h4 className="column">“Term”</h4>
                      <p className="column">
                        means the Initial Term and any extended term as provided for in clause 4.1.
                      </p>
                    </div>
                  </li>
                  <li className="scrollableNumericItem">
                    <p>Interpretation</p>
                    <ol className="scrollableNumericList">
                      <li className="scrollableNumericItem">
                        <p>In this Agreement (except where the context otherwise requires):</p>
                        <ol type="A">
                          <li>
                            any reference to a recital, clause or Schedule is to the relevant
                            recital, clause or Schedule of or to this Agreement; references to
                            paragraphs are to paragraphs of the relevant schedule to this Agreement;
                          </li>
                          <li>
                            the clause headings are included for convenience only and shall not
                            affect the interpretation of this Agreement;
                          </li>
                          <li>use of the singular includes the plural and vice versa;</li>
                          <li>use of any gender includes the other genders;</li>
                          <li>
                            any reference to 'persons' includes individuals, firms, partnerships,
                            companies, corporations, associations, organisations, foundations and
                            trusts (in each case whether or not having separate legal personality);
                          </li>
                          <li>
                            any reference in this Agreement to any statute, statutory provision,
                            subordinate legislation, code or guideline ("<b>legislation</b>") shall
                            be construed as referring to such legislation as the same may from time
                            to time be amended, modified, extended, varied, superseded, replaced,
                            substituted or consolidated;
                          </li>
                          <li>
                            each of the Service Provider and the End User are a 'party' and together
                            the 'parties' to this Agreement; and
                          </li>
                          <li>
                            any phrase introduced by the terms 'including', 'include', 'in
                            particular' or any similar expression shall be construed as illustrative
                            and shall not limit the sense of the words preceding those terms.
                          </li>
                        </ol>
                      </li>
                      <li className="scrollableNumericItem">
                        <p>
                          The Schedules form part of this Agreement and shall have effect as if set
                          out in full in the body of this Agreement and any references to this
                          Agreement includes the Schedules.
                        </p>
                      </li>
                    </ol>
                  </li>
                  <li className="scrollableNumericItem">
                    <p>
                      The provisions of this clause shall survive the termination of this Agreement.
                      <br />
                      In consideration of the mutual promises and undertakings contained in this
                      Agreement, the parties have agreed to be bound by the following terms and
                      conditions:
                    </p>
                  </li>
                </ol>
              </li>
              <li className="scrollableNumericItem">
                <h4>DEFINITIONS AND INTERPRETATION</h4>
                <ol className="scrollableNumericList">
                  <li className="scrollableNumericItem">
                    <p>
                      Purpose <br />
                      This Agreement sets forth the terms and conditions under which the Service
                      Provider agrees to grant access to the HasHealth Platform to the End User over
                      the internet during the Term (the “<b>Services</b>”), as more specifically
                      described in this Agreement, including:
                    </p>
                    <ol className="scrollableNumericList">
                      <li className="scrollableNumericItem">
                        <p>
                          the provision of access to the HasHealth Platform for the End User and
                          their respective Authorised Users (the “<b>Software Services</b>”);
                        </p>
                      </li>
                      <li className="scrollableNumericItem">
                        <p>the provision of scaling services; and</p>
                      </li>
                      <li className="scrollableNumericItem">
                        <p>the provision of the Support Services.</p>
                      </li>
                    </ol>
                  </li>
                  <li className="scrollableNumericItem">
                    <p>
                      Software Licence <br />
                      The Service Provider hereby grants to the End User a worldwide, non-
                      exclusive, non-sub licensable, limited, revocable licence to access and use
                      the HasHealth Platform and any of its associated Intellectual Property Rights
                      solely to the extent necessary for the End User to receive the benefit of the
                      Services for the End User's own internal business purposes during the Term.
                    </p>
                  </li>
                  <li className="scrollableNumericItem">
                    <p>
                      Backup and Recovery of the End User Data <br />
                      As a part of the Software Service, the Service Provider shall:
                    </p>
                    <ol className="scrollableNumericList">
                      <li className="scrollableNumericItem">
                        <p>
                          conduct disaster recovery testing in respect of the HasHealth Platform;
                          and
                        </p>
                      </li>
                      <li className="scrollableNumericItem">
                        <p>
                          keep at least one backup of the End User Data, for an orderly and timely
                          recovery of such data in the event that the Software Services may be
                          interrupted.
                        </p>
                      </li>
                    </ol>
                  </li>
                  <li className="scrollableNumericItem">
                    <p>
                      Standard Support <br />
                      During Standard Support Hours, the Service Provider shall as part of the
                      Support Services make available a telephone help desk and email help desk to
                      provide second-line technical support to the End User.
                    </p>
                  </li>
                  <li className="scrollableNumericItem">
                    <p>
                      Changes <br />
                      The Service Provider will make regular changes to update and enhance the
                      HasHealth Platform. The Service Provider will make best efforts to ensure
                      these changes do not materially decrease the functionality of the Software
                      Services. The Service Provider will provide at least 14 days’ advance notice
                      in writing to the End User of planned maintenance, or material updates and
                      enhancements to the HasHealth Platform and will use reasonable endeavours to
                      provide advance notice of any unplanned maintenance, updates and enhancements
                      to the HasHealth Platform.
                    </p>
                  </li>
                  <li className="scrollableNumericItem">
                    <p>
                      Non-exclusivity <br />
                      The parties acknowledge and agree that the Service Provider is providing the
                      Services on a non-exclusive basis to the End User and the Service Provider
                      remains free to provide the Services to other customers and the End User
                      remains free to contract with other service providers for services the same as
                      or similar to the Services.
                    </p>
                  </li>
                </ol>
              </li>
              <li className="scrollableNumericItem">
                <h4>RESPONSIBILITIES</h4>
                <p>Service Provider's Responsibilities</p>
                <ol className="scrollableNumericList">
                  <li className="scrollableNumericItem">
                    <p>The Service Provider shall:</p>
                    <ol className="scrollableNumericList">
                      <li className="scrollableNumericItem">
                        <p>
                          make the Software Services available for access by the End User and their
                          Authorised Users via the internet through one specified URL or IP address
                          through standard Internet protocols, HTTP or HTTPS in accordance with the
                          Specification and this Agreement;
                        </p>
                      </li>
                      <li className="scrollableNumericItem">
                        <p>
                          allocate adequate resources and staff, with appropriate levels of
                          qualification and expertise in order to provide the Services;
                        </p>
                      </li>
                      <li className="scrollableNumericItem">
                        <p>
                          provide the End User with such documentation relating to the Software
                          Services as the End User may reasonably require from time to time;
                        </p>
                      </li>
                      <li className="scrollableNumericItem">
                        <p>
                          provide the Services with all due skill and care, and in accordance with
                          Good Industry Practice and any applicable laws;
                        </p>
                      </li>
                      <li className="scrollableNumericItem">
                        <p>
                          in providing the Services, meet or exceed the relevant Service Level(s);
                        </p>
                      </li>
                      <li className="scrollableNumericItem">
                        <p>
                          not send, transmit or provide to the End User any Software Services or
                          other material which contains any viruses, malware, spyware, exploit or
                          other malicious code or which encourages conduct that could constitute a
                          criminal offence, give rise to civil liability or otherwise violate any
                          applicable law or regulation;
                        </p>
                      </li>
                      <li className="scrollableNumericItem">
                        <p>
                          provide the Software Services in a highly available cloud environment with
                          security corresponding to the levels of Good Industry Practice.
                        </p>
                      </li>
                      <li className="scrollableNumericItem">
                        <p>
                          shall perform vulnerability scanning on a weekly schedule and implement
                          security patches monthly, with the exception of zero day vulnerabilities
                          which will be tracked and patched within one week of such patch(es)
                          becoming available.
                        </p>
                      </li>
                      <li className="scrollableNumericItem">
                        <p>
                          notify the End User of any changes, updates, interruptions or maintaince
                          to the Software Services via the HasHealth Platform.
                        </p>
                      </li>
                    </ol>
                    <p>End User's Responsibilities</p>
                  </li>
                  <li className="scrollableNumericItem">
                    <p>
                      The End User shall and shall procure that its Authorised Users use reasonable
                      endeavours to:
                    </p>
                    <ol className="scrollableNumericList">
                      <li className="scrollableNumericItem">
                        <p>adhere to its security and password policies;</p>
                      </li>
                      <li className="scrollableNumericItem">
                        <p>
                          ensure that the End User Contact is available to liaise with, and respond
                          to queries from, the Service Provider for the resolution of any issues
                          that the Service Provider requires to resolve. The End User may replace
                          the End User Contact at any time by giving not less than two weeks' prior
                          written notice to the Service Provider;
                        </p>
                      </li>
                      <li className="scrollableNumericItem">
                        <p>
                          at all times be responsible for maintaining its own connectivity to the
                          internet and the functioning of its own network in order to avail of the
                          Software Services provided by the Service Provider;
                        </p>
                      </li>
                      <li className="scrollableNumericItem">
                        <p>
                          provide hardware, software, space, power, internet connectivity and other
                          services required to enable access to the Software Services from the End
                          User's location(s); and
                        </p>
                      </li>
                      <li className="scrollableNumericItem">
                        <p>
                          provide the Service Provider with all reasonable co-operation and
                          assistance in relation to this Agreement and all necessary access to such
                          information as may be necessary in order to provide the Services.
                        </p>
                      </li>
                    </ol>
                  </li>
                  <li className="scrollableNumericItem">
                    <p>
                      The End User shall not, except as may be allowed by any applicable law which
                      is incapable of exclusion by agreement between the parties:
                    </p>
                    <ol className="scrollableNumericList">
                      <li className="scrollableNumericItem">
                        <p>
                          except to the extent expressly permitted under this Agreement, attempt to
                          copy, modify, duplicate, create derivative works from, frame, mirror,
                          republish, download, all or any portion of the Software code in any form
                          of media or by any means; and
                        </p>
                      </li>
                      <li className="scrollableNumericItem">
                        <p>
                          attempt to reverse compile, disassemble, reverse engineer or otherwise
                          reduce to human-perceivable form all or any part of the Software code.
                        </p>
                      </li>
                    </ol>
                  </li>
                  <li className="scrollableNumericItem">
                    <p>The End User shall not:</p>
                    <ol className="scrollableNumericList">
                      <li className="scrollableNumericItem">
                        <p>
                          tamper with, bypass or alter the security features of the Software or
                          circumvent any technical licensing protections for any reason, including
                          but not limited to making Services available for use to any individual
                          that is not an Authorised User, a customer or client, or to discover the
                          underlying source code;
                        </p>
                      </li>
                      <li className="scrollableNumericItem">
                        <p>
                          access all or any part of the Software Services in order to build a
                          product or service which competes with the Services and/or the
                          documentation referred to in this clause 3.4; and
                        </p>
                      </li>
                      <li className="scrollableNumericItem">
                        <p>
                          licence, sell, rent, lease, transfer, assign, distribute, display,
                          disclose or otherwise commercially exploit, or otherwise make the Services
                          available to any third party except its Authorised Users.
                        </p>
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
              <li className="scrollableNumericItem">
                <h4>TERM AND TERMINATION</h4>
                <ol className="scrollableNumericList">
                  <li className="scrollableNumericItem">
                    <p>
                      Term <br />
                      Unless and until the Master Agreement and or the Phoenix Membership Agreement
                      is terminated or this Agreement is terminated in accordance with its terms:
                    </p>
                    <ol className="scrollableNumericList">
                      <li className="scrollableNumericItem">
                        <p>
                          this Agreement shall be deemed to have commenced on the Effective Date and
                          shall continue thereafter for a period of 12 months from the Effective
                          Date (“the Initial Term”); and
                        </p>
                      </li>
                      <li className="scrollableNumericItem">
                        <p>
                          this Agreement will automatically renew for self-repeating periods of 12
                          months each from the date that is 12 months from the Effective Date and
                          each anniversary of the Effective Date.
                        </p>
                      </li>
                    </ol>
                  </li>
                  <li className="scrollableNumericItem">
                    <p>Return of Materials</p>
                    <ol className="scrollableNumericList">
                      <li className="scrollableNumericItem">
                        <p>
                          Upon expiration or termination of this Agreement, each party shall return
                          to the other party, or permit the other party to remove, any property of
                          the other party then situated on such party's premises.
                        </p>
                      </li>
                      <li className="scrollableNumericItem">
                        <p>
                          In the case of End User Data, the Service Provider shall, without delay,
                          and in any event prior to cessation of the Software Services, upon
                          termination of this Agreement provide the End User with a final export of
                          the End User Data and shall certify the destruction of any End User Data
                          within the possession of the Service Provider. The parties agree to work
                          in good faith to execute the foregoing in a timely and efficient manner.
                        </p>
                      </li>
                    </ol>
                  </li>
                  <li className="scrollableNumericItem">
                    <p>
                      Upon termination of this Agreement all licences granted under this Agreement
                      shall terminate with effect from the date of termination and the End User's
                      right to receive the Services shall cease automatically. No refunds (whether
                      pro rata or otherwise) of any amount will be payable to the End User and the
                      End User agrees that it and its Authorised Users shall make no further use of
                      the Services.
                    </p>
                  </li>
                  <li className="scrollableNumericItem">
                    <p>
                      The provisions of clauses 1 (Definitions and Interpretation), 5 (Fees), 7
                      (Non-Disclosure of Confidential Information), 8 (End User Data Protection), 9
                      (Proprietary Rights), 10 (Intellectual Property Rights Indemnification), and
                      to the extent applicable clause 13 (General) shall survive the termination of
                      this Agreement however it arises, and shall continue to bind the parties or
                      the relevant party (as applicable) without limit in time.
                    </p>
                  </li>
                  <li className="scrollableNumericItem">
                    <p>
                      Termination of this Agreement shall not affect any rights of the parties
                      accrued up to the date of termination.
                    </p>
                  </li>
                </ol>
              </li>
              <li className="scrollableNumericItem">
                <h4>FEES AND EXPENSES</h4>
                <ol className="scrollableNumericList">
                  <li className="scrollableNumericItem">
                    <p>
                      The End User shall be responsible for and shall pay to Phoenix the fees for
                      the Services as described in the Numark HasHealth Platform Agreement (the “
                      <b>Fees</b>”), which the End User acknowledges and agrees are paid without any
                      refund or rebate in the event that this Agreement is terminated part way
                      through the Term.
                    </p>
                  </li>
                </ol>
              </li>
              <li className="scrollableNumericItem">
                <h4>REPRESENTATIONS AND WARRANTIES</h4>
                <ol className="scrollableNumericList">
                  <li className="scrollableNumericItem">
                    <p>
                      Each of the End User and the Service Provider represent and warrant to the
                      other that:
                    </p>
                    <ol className="scrollableNumericList">
                      <li className="scrollableNumericItem">
                        <p>
                          it is a business duly incorporated, validly existing, and in good standing
                          under the laws of its state of incorporation;
                        </p>
                      </li>
                      <li className="scrollableNumericItem">
                        <p>
                          it has all requisite corporate power, financial capacity, and authority to
                          execute, deliver, and perform its obligations under this Agreement;
                        </p>
                      </li>
                      <li className="scrollableNumericItem">
                        <p>
                          this Agreement, when executed and delivered, shall constitute valid and
                          binding obligations of it and be enforceable in accordance with its terms;
                        </p>
                      </li>
                      <li className="scrollableNumericItem">
                        <p>
                          it shall comply with laws and regulations applicable to the performance by
                          it of its obligations under this Agreement and shall obtain all applicable
                          permits, licences, consents and permissions required of it in connection
                          with its obligations under this Agreement; and
                        </p>
                      </li>
                      <li className="scrollableNumericItem">
                        <p>
                          there is no outstanding litigation, arbitration or other dispute to which
                          it is a party which, if decided unfavourably to it, would reasonably be
                          expected to have a potential or actual material adverse effect on its
                          ability to fulfil its obligations under this Agreement.
                        </p>
                      </li>
                    </ol>
                  </li>
                  <li className="scrollableNumericItem">
                    <p>The Service Provider represents and warrants that:</p>
                    <ol className="scrollableNumericList">
                      <li className="scrollableNumericItem">
                        <p>
                          the Services shall be provided with all due skill and care, and in
                          accordance with Good Industry Practice;
                        </p>
                      </li>
                      <li className="scrollableNumericItem">
                        <p>
                          the use of the Services by the End User and Authorised Users in accordance
                          with this Agreement does not infringe the Intellectual Property Rights of
                          any third party;
                        </p>
                      </li>
                      <li className="scrollableNumericItem">
                        <p>
                          the HasHealth Platform and Services will perform materially in accordance
                          with the Specification and any other relevant documentation provided to
                          the End User; and
                        </p>
                      </li>
                      <li className="scrollableNumericItem">
                        <p>
                          the Service Provider has put in place, and shall maintain during the term
                          of this Agreement, all appropriate technical and organizational measures
                          consistent with Good Industry Practice to ensure the security and
                          integrity of the End User Data taking into account the risks applicable to
                          the HasHealth Platform and Services from time to time.
                        </p>
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
              <li className="scrollableNumericItem">
                <h4>NON-DISCLOSURE OF CONFIDENTIAL INFORMATION</h4>
                <ol className="scrollableNumericList">
                  <li className="scrollableNumericItem">
                    <p>
                      Obligation of Confidentiality <br />
                      The parties agree to hold the Confidential Information of the other party in
                      strict confidence and shall maintain the confidentiality of such Confidential
                      Information using at least the same degree of care as it employs to safeguard
                      its own Confidential Information, but no less than reasonable care. Each party
                      shall unless required by law, not make the other's Confidential Information
                      available to any third party, or use the other's Confidential Information for
                      any purpose other than the implementation of this Agreement. The parties
                      further agree not to copy, reproduce, sell, transfer, or otherwise dispose of,
                      give or disclose such Confidential Information to third parties other than
                      employees, agents, or subcontractors of a party who have a need to know it in
                      connection with this Agreement or to use it for the performance of their
                      obligations under this Agreement, and who are subject to obligations of
                      confidentiality no less onerous than this clause 7. The Service Provider
                      acknowledges that in providing Services to End User or Phoenix, it may have
                      access to certain non-public business information of End User or Phoenix,
                      including, but not limited to End User Data.
                    </p>
                  </li>
                  <li className="scrollableNumericItem">
                    <p>
                      Cooperation to Prevent Disclosure of Confidential Information <br />
                      Each party shall use its best efforts to assist the other party in identifying
                      and preventing any unauthorised use or disclosure of any Confidential
                      Information. Without limitation of the foregoing, each party shall advise the
                      other party immediately in the event either party learns or has reason to
                      believe that any person who has had access to Confidential Information has
                      violated or intends to violate the terms of this Agreement and each party will
                      cooperate with the other party in seeking injunctive or other equitable relief
                      against any such person.
                    </p>
                  </li>
                  <li className="scrollableNumericItem">
                    <p>
                      Return or Destruction of Information <br />
                      Upon termination of this Agreement each party shall promptly (a) return or
                      destroy (at the election of the other party) all documents and materials (and
                      any copies) containing, reflecting, incorporating, or based on the other
                      party's Confidential Information; (b) erase all of the other party's
                      Confidential Information from its computer systems to the extent possible; and
                      (c) if requested, certify in writing to the other party that it has complied
                      with the requirements of this clause, provided that a party may retain
                      documents and materials containing, reflecting, incorporating, or based on the
                      other party's Confidential Information to the extent required by law or any
                      applicable governmental or regulatory authority, and to the extent reasonable
                      to permit the party to keep evidence that it has performed its obligations
                      under this Agreement.
                    </p>
                  </li>
                </ol>
              </li>
              <li className="scrollableNumericItem">
                <h4>END USER DATA AND DATA PROTECTION</h4>
                <ol className="scrollableNumericList">
                  <li className="scrollableNumericItem">
                    <p>
                      The End User shall own all rights, title and interest in and to all of the End
                      User Data, and shall have sole responsibility for the legality, reliability,
                      integrity, accuracy and quality of the End User Data used in conjunction with
                      and/or entered into the Services. The Service Provider shall make the Content
                      and the End User Data available 'as is' and shall not be liable for any
                      reliance placed by the End User or any third party on the Content or End User
                      Data, whether for medical decisions, clinical decisions, commercial and
                      business decisions or otherwise.
                    </p>
                  </li>
                  <li className="scrollableNumericItem">
                    <p>
                      Each party shall comply with Data Protection Law in connection with their
                      obligations under this Agreement. The Service Provider acknowledges that in
                      providing Services under this Agreement the Service Provider will process
                      Personal Data on behalf of the End User. In such circumstances, the Service
                      Provider acknowledges that the End User is a Controller and the Service
                      Provider is appointed as its Data Processor.
                    </p>
                  </li>
                  <li className="scrollableNumericItem">
                    <p>The Service Provider agrees that:</p>
                    <ol className="scrollableNumericList">
                      <li className="scrollableNumericItem">
                        <p>
                          the Service Provider processes (i) the Personal Data of employees,
                          officers, end-customers of the End User as applicable and (ii) categories
                          of Personal Data, on behalf of the End User in the context of providing
                          the services under this Agreement, for the duration of the Term. The
                          obligations and rights of the End User shall be as set out in this
                          Agreement;
                        </p>
                      </li>
                      <li className="scrollableNumericItem">
                        <p>
                          the Service Provider will only process such Personal Data in accordance
                          with the documented instructions of the End User, including with regard to
                          transfers of Personal Data to a third country and solely as strictly
                          necessary for the performance of its obligations under this Agreement,
                          unless required to do so by EU, EU Member State or UK law to which the
                          Service Provider is subject, in which case the Service Provider shall
                          inform the End User of that legal requirement before processing (unless
                          that law prohibits such information on important grounds of public
                          interest);
                        </p>
                      </li>
                      <li className="scrollableNumericItem">
                        <p>
                          the Service Provider shall ensure that the persons authorised by the
                          Service Provider to process such Personal Data are bound by appropriate
                          confidentiality obligations;
                        </p>
                      </li>
                      <li className="scrollableNumericItem">
                        <p>
                          the Service Provider shall implement such technical and organisational
                          security measures as are required to comply with the data security
                          obligations under Data Protection Law:
                        </p>
                        <ol type="i">
                          <li>
                            such that the processing of Personal Data shall meet the requirements of
                            Data Protection Law and ensure the protection of the rights of Data
                            Subjects;
                          </li>
                          <li>
                            so as to ensure a level of security in respect of Personal Data
                            processed by it that is appropriate to the risks that are presented by
                            the processing, in particular from accidental or unlawful destruction,
                            loss, alteration, unauthorised disclosure of, or access to Personal Data
                            transmitted, stored or otherwise processed;
                          </li>
                          <li>
                            to assist the End User in the fulfilment of the End User's obligations
                            to respond to Data Subject Requests relating to Personal Data;
                          </li>
                          <li>
                            such that the Service Provider shall in respect of the Personal Data
                            processed by it under this Agreement comply with the requirements
                            regarding security of processing set out in Data Protection Law; and
                          </li>
                          <li>
                            the Service Provider may not make any change to the security measures it
                            applies to the Personal Data to the extent that any such change would
                            conflict with the provisions of this Agreement.
                          </li>
                        </ol>
                      </li>
                      <li className="scrollableNumericItem">
                        <p>
                          it shall not engage any sub-processor without the prior written consent of
                          the End User and where the End User has consented to the appointment of a
                          sub-processor, the Service Provider shall not replace or engage other
                          sub-processors without the prior written consent of the End User;
                        </p>
                      </li>
                      <li className="scrollableNumericItem">
                        <p>
                          where any sub-contractor of the Service Provider will be processing such
                          Personal Data on behalf of the End User, the Service Provider shall ensure
                          that a written contract exists between the Service Provider and the
                          sub-contractor containing clauses equivalent to those imposed on the
                          Service Provider in this clause 8.2. In the event that any sub-processor
                          fails to meet its data protection obligations, the Service Provider shall
                          remain fully liable to the End User for the performance of the
                          sub-processor's obligations;
                        </p>
                      </li>
                      <li className="scrollableNumericItem">
                        <p>
                          it shall inform the End User promptly in the event of receiving a request
                          from a Data Subject to exercise their rights under Data Protection Law and
                          provide such co-operation and assistance as may be required to enable the
                          End User to deal with such request in accordance with the provisions of
                          Data Protection Law;
                        </p>
                      </li>
                      <li className="scrollableNumericItem">
                        <p>
                          it shall assist the End User by implementing appropriate technical and
                          organisational measures to allow the End User to comply with requests from
                          Data Subjects to exercise their rights under Data Protection Law;
                        </p>
                      </li>
                      <li className="scrollableNumericItem">
                        <p>
                          it shall assist the End User in ensuring compliance with its obligations
                          in respect of security of Personal Data, data protection impact
                          assessments and prior consultation requirements under Data Protection Law;
                        </p>
                      </li>
                      <li className="scrollableNumericItem">
                        <p>
                          when the Service Provider ceases to provide services relating to data
                          processing the Service Provider shall: (i) at the choice of the End User,
                          delete or return all such Personal Data to the End User; and (ii) delete
                          all existing copies of such Personal Data unless EU law or the laws of an
                          EU Member State require storage of the Personal Data;
                        </p>
                      </li>
                      <li className="scrollableNumericItem">
                        <p>
                          it shall: (i) make available to the End User all information necessary to
                          demonstrate compliance with the obligations laid down in this this clause
                          8.2; and (ii) allow for and assist with audits, including inspections,
                          conducted by the End User or another auditor mandated by the End User, in
                          order to ensure compliance with the obligations laid down in this clause
                          8, including its data security obligations under Data Protection Law. The
                          End User as applicable shall be entitled, at its discretion, to accept
                          adherence by the Service Provider to an approved code of conduct or an
                          approved certification mechanism to aid demonstration by the Service
                          Provider that it is compliant with the provisions of this clause 8;
                        </p>
                      </li>
                      <li className="scrollableNumericItem">
                        <p>
                          it shall inform the End User immediately if, in its opinion, it receives
                          an instruction from the End User which infringes Data Protection Law;
                        </p>
                      </li>
                      <li className="scrollableNumericItem">
                        <p>
                          it shall notify the End User without undue delay after becoming aware of
                          any breach of security leading to the accidental or unlawful destruction,
                          loss, alteration, unauthorised disclosure of, or access to, Personal Data
                          transmitted, stored or otherwise processed and provide the End User with
                          such co-operation and assistance as may be required to mitigate against
                          the effects of, and comply with any reporting obligations which may apply
                          in respect of, any such breach; and
                        </p>
                      </li>
                      <li className="scrollableNumericItem">
                        <p>
                          no such Personal Data shall be transferred outside of the area comprising
                          both the United Kingdom and the European Economic Area by the Service
                          Provider or any of its agents or sub-processors without the prior written
                          consent of the End User which may be subject to terms and conditions
                          (including, without limitation, that the data importer enters into model
                          clauses in the form approved by the European Commission and, where
                          relevant, complies with the provisions regarding sub-processors contained
                          in such model contracts in respect of any sub-processors). The Service
                          Provider shall comply with the requirements of Data Protection Law in
                          respect of transfers of such Personal Data outside of the area comprising
                          both the United Kingdom and the European Economic Area, to the extent that
                          the End User consents to any such transfer.
                        </p>
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
              <li className="scrollableNumericItem">
                <h4>PROPRIETARY RIGHTS</h4>
                <ol className="scrollableNumericList">
                  <li className="scrollableNumericItem">
                    <p>
                      The End User acknowledge and agree that the Service Provider and/or its
                      licensors own all Intellectual Property Rights in the Services (including any
                      improvements and developments to such whether made by the Service Provider or
                      the End User). Except as expressly stated herein, this Agreement does not
                      grant the End User any rights to, or in, patents, copyrights, database rights,
                      trade secrets, trade names, trade marks (whether registered or unregistered),
                      or any other rights or licences in respect of the Services.
                    </p>
                  </li>
                  <li className="scrollableNumericItem">
                    <p>
                      The End User shall ensure that all titles, logos, trade marks, copyright and
                      other notices applied by the Service Provider to the Services or any materials
                      provided or produced by the Service Provider or the Services in connection
                      with this Agreement shall be reproduced and not deleted or removed.
                    </p>
                  </li>
                  <li className="scrollableNumericItem">
                    <p>
                      The End User agrees that the Service Provider shall have a royalty-free,
                      worldwide, transferable, sublicensable, irrevocable, perpetual licence to use
                      or incorporate into any Service Provider products or services any suggestions,
                      enhancement requests, recommendations or other feedback provided by the End
                      User, or their Authorised Users relating to the operation of the Services or
                      other products of the Service Provider.
                    </p>
                  </li>
                  <li className="scrollableNumericItem">
                    <p>
                      End User Data Licence <br />
                      The End User hereby grants the Service Provider a limited, revocable, non-
                      transferable, non-sublicensable, worldwide, non-exclusive licence during the
                      term of this Agreement only to use End User Data (only in respect of which it
                      is the owner) for purpose of providing the Services and improving the
                      HasHealth Platform (save that patient Personal Data shall not be used for the
                      purpose of such improvements), including a licence to store, record, transmit,
                      maintain, and display End User Data only to the extent necessary in the
                      provisioning of the Services.
                    </p>
                  </li>
                  <li className="scrollableNumericItem">
                    <p>
                      Data of Service Provider <br />
                      The Service Provider's information and data (which shall be treated by the End
                      User as Confidential Information of the Service Provider), shall be and remain
                      the sole and exclusive property of the Service Provider.
                    </p>
                  </li>
                  <li className="scrollableNumericItem">
                    <p>
                      No Licence <br />
                      Except as expressly set forth herein, no licence is granted by either party to
                      the other with respect to the Confidential Information, proprietary rights or
                      End User Data. Nothing in this Agreement shall be construed to grant to either
                      party any ownership or other interest, in the Confidential Information,
                      proprietary rights or End User Data, except as may be provided under a licence
                      specifically applicable to such Confidential Information, proprietary rights
                      or End User Data.
                    </p>
                  </li>
                </ol>
              </li>
              <li className="scrollableNumericItem">
                <h4>RESPONSIBILITY</h4>
                <ol className="scrollableNumericList">
                  <li className="scrollableNumericItem">
                    <p>
                      The Service Provider in no way accepts any responsibility for the results
                      obtained by the End User's Authorised Users, clients or customers in their
                      utilisation of the Software Services.
                    </p>
                  </li>
                </ol>
              </li>
              <li className="scrollableNumericItem">
                <h4>INTELLECTUAL PROPERTY RIGHTS INDEMNIFICATION</h4>
                <ol className="scrollableNumericList">
                  <li className="scrollableNumericItem">
                    <p>
                      The Service Provider shall defend, indemnify and hold harmless the End User
                      against claims, actions, proceedings, losses, damages, expenses and costs
                      (including without limitation court costs and reasonable legal fees) arising
                      out of or in connection with any claim that the End User's (and/or Authorised
                      Users) use of the Software Services infringes or violates a third party's
                      Intellectual Property Rights, provided that:
                    </p>
                    <ol className="scrollableNumericList">
                      <li className="scrollableNumericItem">
                        <p>
                          the allegation of infringement is not specifically related to data,
                          functionality, branding or content specified by the End User (and/or its
                          Authorised Users);
                        </p>
                      </li>
                      <li className="scrollableNumericItem">
                        <p>the Service Provider is given prompt notice of any such claim;</p>
                      </li>
                      <li className="scrollableNumericItem">
                        <p>
                          the End User provides reasonable co-operation to the Service Provider in
                          the defence and settlement of such claim, at the Service Provider's
                          expense; and
                        </p>
                      </li>
                      <li className="scrollableNumericItem">
                        <p>
                          the Service Provider is given sole authority to defend or settle the
                          claim.
                        </p>
                      </li>
                    </ol>
                  </li>
                  <li className="scrollableNumericItem">
                    <p>
                      In the defence or settlement of any claim, the Service Provider may procure
                      the right for the End User (and/or Authorised Users) to continue using the
                      Services, or replace or modify the Software Services so that they become
                      non-infringing.
                    </p>
                  </li>
                  <li className="scrollableNumericItem">
                    <p>
                      In no event shall the Service Provider, its employees, agents and
                      subcontractors be liable to the End User to the extent that the alleged
                      infringement is based on:
                    </p>
                    <ol className="scrollableNumericList">
                      <li className="scrollableNumericItem">
                        <p>
                          a modification of the Software Services by anyone other than the Service
                          Provider; or
                        </p>
                      </li>
                      <li className="scrollableNumericItem">
                        <p>
                          any use of the Software Services and/or Software outside of the scope of
                          this Agreement or that is not in accordance with any other instructions
                          provided to the End User by the Service Provider; or
                        </p>
                      </li>
                      <li className="scrollableNumericItem">
                        <p>
                          any claim related to data, functionality, branding or content specified by
                          the End User.
                        </p>
                      </li>
                    </ol>
                  </li>
                  <li className="scrollableNumericItem">
                    <p>
                      The Service Provider acknowledges and agrees that Phoenix shall be entitled to
                      claim any losses suffered by the End User under this clause on their behalf
                      under the terms of the Master Agreement.
                    </p>
                  </li>
                  <li className="scrollableNumericItem">
                    <p>
                      The provisions of this clause shall survive the termination of this Agreement.
                    </p>
                  </li>
                </ol>
              </li>
              <li className="scrollableNumericItem">
                <h4>LIMITATION OF LIABILITY</h4>
                <ol className="scrollableNumericList">
                  <li className="scrollableNumericItem">
                    <p>
                      Except in respect of Clause 11 (Intellectual Property Rights Indemnification),
                      which shall not be limited in any way by this clause, this clause 12 sets out
                      the entire financial liability of either party (including any liability for
                      the acts or omissions of its employees, agents and sub- contractors) to the
                      other party in respect of:
                    </p>
                    <ol className="scrollableNumericList">
                      <li className="scrollableNumericItem">
                        <p>any breach of this Agreement;</p>
                      </li>
                      <li className="scrollableNumericItem">
                        <p>any use made by the End User of the Services or any part of them; and</p>
                      </li>
                      <li className="scrollableNumericItem">
                        <p>
                          any representation, statement or tortious act or omission (including
                          negligence) arising under or in connection with this Agreement.
                        </p>
                      </li>
                    </ol>
                  </li>
                  <li className="scrollableNumericItem">
                    <p>Except as expressly and specifically provided in this Agreement:</p>
                    <ol className="scrollableNumericList">
                      <li className="scrollableNumericItem">
                        <p>
                          the End User assumes sole responsibility for results obtained from the use
                          of the Software Services, and for conclusions drawn from such use. The
                          Service Provider shall have no liability for any damage caused by errors
                          or omissions in any information, instructions or content provided to the
                          Service Provider by the End User in connection with the Software Services,
                          or any actions taken by the Service Provider at the End User's direction;
                        </p>
                      </li>
                      <li className="scrollableNumericItem">
                        <p>
                          all warranties, representations, conditions and all other terms of any
                          kind whatsoever implied by statute or common law are, to the fullest
                          extent permitted by applicable law, excluded from this Agreement.
                        </p>
                      </li>
                    </ol>
                  </li>
                  <li className="scrollableNumericItem">
                    <p>Nothing in this Agreement excludes the liability of a party:</p>
                    <ol className="scrollableNumericList">
                      <li className="scrollableNumericItem">
                        <p>for death or personal injury caused by negligence;</p>
                      </li>
                      <li className="scrollableNumericItem">
                        <p>for fraud or fraudulent misrepresentation; or</p>
                      </li>
                      <li className="scrollableNumericItem">
                        <p>where such exclusion is not permitted by law.</p>
                      </li>
                    </ol>
                  </li>
                  <li className="scrollableNumericItem">
                    <p>Subject to Clause 12.2 and Clause 12.3:</p>
                    <ol className="scrollableNumericList">
                      <li className="scrollableNumericItem">
                        <p>
                          No party shall be liable to the other party whether in tort (including for
                          negligence or breach of statutory duty), contract, misrepresentation,
                          restitution or otherwise for any loss of profits, depletion of goodwill,
                          or pure economic loss, or for any special, indirect or consequential loss,
                          costs, damages, charges or expenses however arising under this Agreement.
                        </p>
                      </li>
                      <li className="scrollableNumericItem">
                        <p>
                          Subject to clause 12.4.3, each party's total liability in contract, tort
                          (including negligence or breach of statutory duty), misrepresentation,
                          restitution or otherwise, arising in connection with the performance or
                          contemplated performance of this Agreement for each claim or series of
                          related claims shall be limited to: one (1) times the total Fees paid and
                          payable to the Service Provider by Phoenix under the Master Services
                          Agreement during the 12 months immediately preceding the date on which the
                          claim arose.
                        </p>
                      </li>
                      <li className="scrollableNumericItem">
                        <p>
                          each party's total liability in contract, tort (including negligence or
                          breach of statutory duty), misrepresentation, restitution or otherwise,
                          arising in connection with the performance or contemplated performance of
                          clause 8 (End User Data and Data Protection) for each claim or series of
                          related claims shall be limited to three (3) times the total Fees paid and
                          payable to the Service Provider by Phoenix under the Master Services
                          Agreement during the Initial Term or subsequent to the Initial Term, the
                          12 months immediately preceding the date on which the claim arose.
                        </p>
                      </li>
                      <li className="scrollableNumericItem">
                        <p>
                          The End User agrees that, in entering into this Agreement, either it did
                          not rely on any representations (whether written or oral) of any kind of
                          any person other than those expressly set out in this Agreement or (if it
                          did rely on any representations, whether written or oral, not expressly
                          set out in this Agreement) that it shall have no remedy in respect of such
                          representations and (in either case) the Service Provider shall have no
                          liability otherwise than pursuant to the express provisions of this
                          Agreement.
                        </p>
                      </li>
                      <li className="scrollableNumericItem">
                        <p>
                          Each provision of this Clause 12 excluding or limiting liability shall be
                          construed separately, applying and surviving even if for any reason one or
                          other of these provisions is held inapplicable or unenforceable and shall
                          remain in force notwithstanding the termination of this Agreement,
                          howsoever arising.
                        </p>
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
              <li className="scrollableNumericItem">
                <h4>GENERAL</h4>
                <ol className="scrollableNumericList">
                  <li className="scrollableNumericItem">
                    <p>
                      Governing Law and Jurisdiction <br />
                      This Agreement and any disputes or claims arising out of or in connection with
                      it, its subject matter or formation (including non-contractual disputes or
                      claims) shall be governed by and construed in accordance with the laws of
                      England and Wales, and shall be subject to the exclusive jurisdiction of the
                      courts of England and Wales.
                    </p>
                  </li>
                  <li className="scrollableNumericItem">
                    <p>
                      Dispute Resolution <br />
                      In the event of any dispute or disagreement between the parties with respect
                      to the interpretation of any provision of this Agreement, or with respect to
                      the performance of either party hereunder, the End User shall escalate the
                      dispute or disagreement to Phoenix to be managed under the dispute resolution
                      provisions of the Master Services Agreement. The End User agrees to cooperate
                      with Phoenix in resolving the dispute or disagreement. Nothing in this clause
                      13.2 shall prevent either party from abstaining from the procedure herein
                      and/or obtaining injunctive relief where circumstances deem necessary.
                    </p>
                  </li>
                  <li className="scrollableNumericItem">
                    <p>
                      Cooperation <br />
                      Where agreement, approval, acceptance, consent or similar action by either
                      party hereto is required by any provision of this Agreement, such action shall
                      not be unreasonably delayed or withheld. Each party will cooperate with the
                      other by, among other things, making available, as reasonably requested by the
                      other, management decisions, information, approvals, and acceptances in order
                      that each party may properly accomplish its obligations and responsibilities
                      hereunder. The Service Provider will agree to cooperate at the End User's
                      expense with any End User supplier performing services, and all parties
                      supplying hardware, software, communication services, and other services and
                      products to the End User.
                    </p>
                  </li>
                  <li className="scrollableNumericItem">
                    <p>Force Majeure</p>
                    <ol className="scrollableNumericList">
                      <li className="scrollableNumericItem">
                        <p>
                          Neither party shall be liable to the other party for delays or any failure
                          to perform the Services or this Agreement due to causes beyond its
                          reasonable control. Such delays include, but are not limited to, an act of
                          God, fire, flood, earthquake, wind, storm or other natural disaster,
                          pandemic, epidemic, war, insurrection, riot, act of terrorism, military
                          operations, malicious damage, compliance with a law or governmental order,
                          rule, regulation or direction, failure of any utility service including
                          electric power, gas, water or telephone service, accident, breakdown of
                          plant or machinery, strikes, lockouts, and other industrial disputes (in
                          each case only where not relating to the delayed party's workforce), to
                          the extent not occasioned by the fault or negligence of the delayed party.
                          Any such excuse for delay shall last only as long as the event remains
                          beyond the reasonable control of the delayed party. However, the delayed
                          party shall use its best efforts to minimise the delays caused by any such
                          event beyond its reasonable control. The delayed party must notify the
                          other party promptly upon the occurrence of any such event (or performance
                          by the delayed party will not be considered excused pursuant to this
                          clause 13.4), and shall inform the other party of its plans to resume
                          performance, and resume performance as soon as possible following the
                          event.
                        </p>
                      </li>
                      <li className="scrollableNumericItem">
                        <p>
                          Neither the End User nor the Service Provider shall have any liability to
                          the other in respect of termination of this Agreement due to a force
                          majeure event, but rights and liabilities which have accrued prior to
                          termination shall subsist.
                        </p>
                      </li>
                    </ol>
                  </li>
                  <li className="scrollableNumericItem">
                    <p>
                      No Waiver <br />
                      The failure of either party at any time to require performance by the other
                      party of any provision of this Agreement shall in no way affect that party's
                      right to enforce such provisions, nor shall the waiver by either party of any
                      breach of any provision of this Agreement be taken or held to be a waiver of
                      any further breach of the same provision.
                    </p>
                  </li>
                  <li className="scrollableNumericItem">
                    <p>
                      Notices <br />
                      All notices to or by the respective parties shall be in writing in the English
                      language and shall be deemed to have been duly given when by email to the
                      email addresses listed below or to such other address as either party may
                      subsequently notify to the other in writing by hand or recorded delivery to
                      either party's registered address as may be notified to the other party.
                    </p>
                  </li>
                  <li className="scrollableNumericItem">
                    <p>
                      Entire Agreement <br />
                      This Agreement and its attached schedules constitute the entire agreement
                      between the parties and supersede any and all previous representations,
                      understandings, or agreements between the End User and the Service Provider as
                      to the subject matter hereof. This Agreement may only be amended by an
                      instrument in writing signed by the parties. Each party acknowledges that it
                      is not relying, and will not seek to rely, on any arrangement, understanding,
                      representation, warranty, agreement, term or condition which is not expressly
                      set out in this Agreement.
                    </p>
                  </li>
                  <li className="scrollableNumericItem">
                    <p>
                      Severability <br />
                      If the whole or any part of a provision of this Agreement is or becomes
                      illegal, invalid or unenforceable under the law of any jurisdiction, that
                      shall not affect the legality, validity or enforceability under the law of
                      that jurisdiction of the remainder of the provision in question or any other
                      provision of this Agreement and the legality, validity or enforceability under
                      the law of any other jurisdiction of that or any other provision of this
                      Agreement.
                    </p>
                  </li>
                  <li className="scrollableNumericItem">
                    <p>
                      No Partnership or Agency <br />
                      Nothing in this Agreement shall create, or be deemed to create, a partnership,
                      joint venture, or the relationship of principal and agent, between the
                      parties, and neither party shall have any right or authority to act on behalf
                      of the other or to bind the other in any way.
                    </p>
                  </li>
                  <li className="scrollableNumericItem">
                    <p>
                      The provisions of this clause shall survive the termination of this Agreement.
                    </p>
                  </li>
                </ol>
              </li>
              <li className="scrollableNumericItem">
                <h4>SUSPENSION OF SERVICES</h4>
                <ol className="scrollableNumericList">
                  <li className="scrollableNumericItem">
                    <p>
                      Without prejudice to any other right that the Service Provider may have, the
                      Service Provider may suspend the Software Services or any part thereof upon
                      advance written notice to the End User where:
                    </p>
                    <ol className="scrollableNumericList">
                      <li className="scrollableNumericItem">
                        <p>
                          the Service Provider becomes aware of a claim that the Software Services
                          or Software or content infringes on any third party rights or occurs in
                          violation of applicable laws or regulations; or
                        </p>
                      </li>
                      <li className="scrollableNumericItem">
                        <p>
                          the Service Provider detects that any method or device is being used to
                          circumvent the normal security measures implemented in connection with
                          access and use of the Software Services; or
                        </p>
                      </li>
                      <li className="scrollableNumericItem">
                        <p>
                          if there is a suspected, threatened or actual attack on either party's
                          applications and/or platforms used to provide the Software Services; or
                        </p>
                      </li>
                      <li className="scrollableNumericItem">
                        <p>
                          if any event occurs for which the Service Provider reasonably believes
                          that suspension of the Software Services is necessary to protect the
                          Software Services or to prevent third party access to the Software
                          Services; or
                        </p>
                      </li>
                      <li className="scrollableNumericItem">
                        <p>
                          the End User breaches any of the terms of the Numark HasHealth Platform
                          Agreement (following a notification by Phoneix to the Service Provider
                          including any failure to pay the Fees).
                        </p>
                      </li>
                    </ol>
                  </li>
                  <li className="scrollableNumericItem">
                    <p>
                      The Service Provider shall promptly resume the provision of the Software
                      Services once the circumstances giving rise to the suspension have ceased.
                    </p>
                  </li>
                </ol>
              </li>
              <li className="scrollableNumericItem">
                <h4>ANTI-BRIBERY</h4>
                <ol className="scrollableNumericList">
                  <li className="scrollableNumericItem">
                    <p>
                      For the purpose of this clause 15, the expressions '<b>adequate procedures</b>
                      ' and '<b>associated with</b>' shall be construed in accordance with the
                      Bribery Act 2010 and legislation or guidance published under it.
                    </p>
                  </li>
                  <li className="scrollableNumericItem">
                    <p>
                      Each party shall comply with applicable Bribery Laws including ensuring that
                      it has in place adequate procedures to prevent bribery and ensure that: (a)
                      all of that party's personnel; (b) all others associated with that party; and
                      (c) all of that party's subcontractors; involved in performing the Contract so
                      comply.
                    </p>
                  </li>
                  <li className="scrollableNumericItem">
                    <p>
                      Without limitation, neither party shall make or receive any bribe (as defined
                      in the Bribery Act 2010) or other improper payment or allow any such to be
                      made or received on its behalf, either in the United Kingdom or elsewhere and
                      shall implement and maintain adequate procedures to ensure that such bribes or
                      payments are not made or received directly or indirectly on its behalf.
                    </p>
                  </li>
                </ol>
              </li>
              <li className="scrollableNumericItem">
                <h4>ANTI-SLAVERY</h4>
                <p>
                  Both parties shall and shall procure that its officers, employees, agents and sub-
                  contractors (if any) shall: (a) comply with all Applicable Laws, and codes
                  relating to modern anti-slavery and human trafficking including the Modern Slavery
                  Act 2015 (<b>Relevant Requirements</b>) that are applicable within the United
                  Kingdom; (b) not engage in any activity, practice or conduct which would
                  constitute an offence under Chapter 30 Part 1 sections 1, 2 or 4 of the Modern
                  Slavery Act 2015 if such activity, practice or conduct had been carried out either
                  wholly or partially within the UK; and (c) have and shall maintain in place
                  throughout the term of this Agreement its own policies and procedures, including
                  adequate procedures under the Modern Slavery Act 2015, to ensure compliance with
                  the Relevant Requirements (especially Part 6 section 54), and will enforce them
                  where appropriate.
                </p>
              </li>
              <li className="scrollableNumericItem">
                <h4>RIGHT OF THIRD PARTIES</h4>
                <p>
                  Phoenix may enforce the provisions of this Agreement subject to, and in accordance
                  with, the Contracts (Rights of Third Parties) Act 1999. This does not affect and
                  right or remedy of Phoenix that exists or is available apart from the Contracts
                  (Rights of Third Parties) Act 1999. The parties do not require the consent of any
                  third party to vary the terms of this Agreement or to rescind this Agreement.
                </p>
              </li>
            </ol>
          </div>
          <Button onClick={() => handleClick(true)} variant="primary">
            <LoadingWrapper isLoading={loading}>
              Agree & Open Services <Icon iconName="externalLink" className="externalLinkIcon" />
            </LoadingWrapper>
          </Button>
        </>
      );
    }
  } else {
    return (
      <>
        <h1>Introducing pharmacy services</h1>
        <p className="body-long-copy-book-2">
          Did you know that you can now offer pharmacy services via Hey Pharmacist Portal?
        </p>
        <p className="body-long-copy-book-2">
          Thanks to our partnership with Has Health, patients are now able to book a range of
          services, including flu and phneumonia vaccines, via Hey Pharmacist website.
        </p>
        <div>
          <p className="body-long-copy-book-2">This feature offers a wide variety of benefits:</p>
          <p className="body-long-copy-semi-bold-2">Easier for patients and pharmacies</p>
          <ul>
            <li className="body-long-copy-book-2">
              Patients will no longer have to call your pharmacy to book a service
            </li>
            <li className="body-long-copy-book-2">
              A wider audience will be drawn to a digital way to book a service
            </li>
          </ul>
          <p className="body-long-copy-semi-bold-2">Improved efficiency for you</p>
          <ul>
            <li className="body-long-copy-book-2">
              Manage both patient orders and pharmacy services with a single sign in
            </li>
            <li className="body-long-copy-book-2">
              You have the control to manage your own avilability and offerings for services
            </li>
          </ul>
        </div>
        <p className="body-long-copy-book-2">
          Please speak to your Numark account manager if you are interested in adding this exciting
          new feature.
        </p>
      </>
    );
  }
};

export default ServicesContent;
