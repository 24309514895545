import React from 'react';
import './PageWrapper.css';

const PageWrapper = (props) => {
  const { id, className = '', children } = props;
  return (
    <div id={id} className={`${className} pageWrapper`}>
      {children}
    </div>
  );
};

export default PageWrapper;
