import React, { ReactNode, useState } from 'react';
import ReactGA from 'react-ga4';
import { Icon } from '..';
import styles from './Input.module.scss';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string | ReactNode;
  subLabel?: string;
  errorText?: string;
  inputName: string;
}

const Input: React.FunctionComponent<InputProps> = ({
  label,
  subLabel,
  errorText,
  type,
  inputName,
  className,
  ...props
}) => {
  const [showPassword, setShowPassword] = useState(type !== 'password');
  const inputType = showPassword ? 'text' : type;

  return (
    <div
      className={[styles.container, errorText && styles['container--error'], className]
        .filter((c) => c?.length)
        .join(' ')}
    >
      {label && (
        <label htmlFor={inputName} className={styles.label}>
          {label}
        </label>
      )}
      {subLabel && (
        <label htmlFor={inputName} className={styles['sub-label']}>
          {subLabel}
        </label>
      )}
      {type === 'password' ? (
        <div className={styles['password-input']}>
          <input name={inputName} className={styles.input} type={inputType} {...props} />
          <div
            onClick={() => {
              ReactGA.event({
                category: 'Login',
                action: 'Hide_Unhide_Password',
              });
              setShowPassword(!showPassword);
            }}
          >
            <Icon iconName={showPassword ? 'passwordShow' : 'passwordHide'} data-testid="icon" />
          </div>
        </div>
      ) : (
        <input name={inputName} className={styles.input} type={type} {...props} />
      )}
      {errorText && <div className={styles['error-text']}>{errorText}</div>}
    </div>
  );
};

export default Input;
