export const requestIdMiddleWare = () => (next) => (action) => {
  const headers = action.meta?.baseQueryMeta?.response?.headers;

  if (
    headers &&
    ['api/executeQuery/rejected', 'api/executeMutation/rejected'].includes(action.type)
  ) {
    action.payload.data = { ...action.payload.data, requestId: headers.get('X-Request-ID') };
  }

  next(action);
};
