import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { retrieveIdToken } from '../services/auth';

const tagOnSuccess = (tags) => (result, error) => error ? [] : tags;

const orderSlice = createApi({
  reducerPath: 'orders',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_MEMBER_API_URL,
    prepareHeaders: async (headers) => {
      const idToken = await retrieveIdToken();
      headers.set('Authorization', `Bearer ${idToken}`);
      return headers;
    },
  }),
  tagTypes: ['Order', 'OrderCount', 'ProcessingOrder', 'OrderLog'],
  endpoints: (builder) => ({
    getOrders: builder.query({
      query: (options) => {
        const { statuses, includeArchive } = options;
        let queryString = '';
        if (statuses?.length) {
          queryString += `?status=${statuses.map((s) => encodeURIComponent(s)).join('&status=')}`;
        }
        if (includeArchive) {
          queryString += `&includeArchive=${includeArchive}`;
        }
        return `/api/orders${queryString}`;
      },
      providesTags: tagOnSuccess(['Order']),
    }),
    getProcessingOrders: builder.query({
      query: () => {
        return `/api/orders?status=Prescription Issued&status=Prescription Part Issued&status=Prescription Processed&status=Dispensing`;
      },
      providesTags: tagOnSuccess(['ProcessingOrder']),
    }),
    getOrderCounts: builder.query({
      query: (options) => {
        const { includeArchive } = options ?? {};
        let queryString = '';
        if (includeArchive) {
          queryString += `?includeArchive=${includeArchive}`;
        }
        return `/api/orders/counts${queryString}`;
      },
      providesTags: tagOnSuccess(['OrderCount']),
    }),
    searchOrders: builder.query({
      query: (options) => {
        const { searchTerm, includeArchive } = options;
        const trimmedResult = searchTerm.replace(/(\d)\s+(?=\d)/g, `$1`);
        let queryString = '';
        if (includeArchive) {
          queryString += `&includeArchive=${includeArchive}`;
        }
        return `/api/orders?q=${encodeURIComponent(trimmedResult)}${queryString}`;
      },
      providesTags: tagOnSuccess(['Order']),
    }),
    getOrderDetails: builder.query({
      query: (orderId) => `/api/orders/${encodeURIComponent(orderId)}`,
      providesTags: tagOnSuccess(['Order']),
    }),
    updateOrder: builder.mutation({
      query: (options) => ({
        url: `/api/orders/${encodeURIComponent(options.orderId)}${
          options.sendNotifications ? '?send_notifications=true' : '?send_notifications=false'
        }`,
        method: 'PATCH',
        body: options.updateOrderRequest,
      }),
      invalidatesTags: tagOnSuccess(['Order', 'OrderCount']),
    }),
    updateOrders: builder.mutation({
      query: (options) => ({
        url: `/api/orders${
          options.sendNotifications ? '?send_notifications=true' : '?send_notifications=false'
        }`,
        method: 'PATCH',
        body: options.updateOrdersRequest,
      }),
      invalidatesTags: tagOnSuccess(['Order', 'OrderCount']),
    }),
    getOrderLogs: builder.query({
      query: (orderId) => `/api/orders/${encodeURIComponent(orderId)}/logs`,
      providesTags: tagOnSuccess(['OrderLog']),
    }),
    createOrderCollectionReminder: builder.mutation({
      query: (orderId) => ({
        url: `/api/orders/${encodeURIComponent(orderId)}/collection-reminder`,
        method: 'POST',
      }),
      invalidatesTags: tagOnSuccess(['Order']),
    }),
  }),
});

export default orderSlice;
