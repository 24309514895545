import styles from './Pill.module.scss';

type PillSize = 'medium' | 'small' | 'extra-small';
type PillVariant = 'information' | 'attention' | 'warning' | 'critical' | 'success' | 'neutral';

interface PillProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  size?: PillSize;
  variant?: PillVariant;
}

const Pill: React.FunctionComponent<PillProps> = ({
  children,
  variant = 'neutral',
  size = 'medium',
  className = '',
  ...props
}) => (
  <span
    className={[styles.pill, styles['pill--' + size], styles['pill--' + variant], className]
      .filter((c) => c?.length)
      .join(' ')}
    {...props}
  >
    {children}
  </span>
);

export default Pill;
