import React from 'react';
import { Col, Card } from 'react-bootstrap';
import { Icon } from '../Atoms';
import './ReportsContainer.css';

const ReportsContainer = (props) => {
  const { iconName, title, value, text, children } = props;
  return (
    <Col md={4} className="px-0">
      <Card>
        <div className="d-flex">
          {iconName && <Icon iconName={iconName} className="icon" />}
          <div className="ml-4">
            {title && <p className="title-3">{title}</p>}
            <h1 className="card-value">{value}</h1>
          </div>
        </div>
        {children}
        {text && <p className="card-text">{text}</p>}
      </Card>
    </Col>
  );
};

export default ReportsContainer;
