import dayjs from 'dayjs';
//Function to calculate the offset required for pagination API
export const calculateOffset = (page, limit) => {
  return limit * (page - 1);
};

//Function to calculate the current page based on the limit (perPage)
export const calculateCurrentPage = (limit, offset) => {
  return (limit + offset) / limit;
};

//Function to return the number of months between two dates
export const numberOfMonthsBetween = (dateA, dateB) => {
  const date1 = dayjs(dateA);
  return date1.diff(dateB, 'month', true);
};
