import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import orderSlice from '../../redux/orderSlice';
import { Icon } from '../Atoms';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import Tooltip from '../Tooltip/Tooltip';
import styles from './OrderReminder.module.scss';

const OrderReminder = ({ coop_id, onError }) => {
  const [createOrderCollectionReminder, orderReminderMutation] =
    orderSlice.endpoints.createOrderCollectionReminder.useMutation();
  const [errorLoaded, setErrorLoaded] = useState(false);

  useEffect(() => {
    if (!errorLoaded && orderReminderMutation.isError) {
      setErrorLoaded(true);
      onError();
    }
  }, [orderReminderMutation.isError, onError, errorLoaded]);

  const handleClick = (e) => {
    e.stopPropagation();
    setErrorLoaded(false);
    createOrderCollectionReminder(coop_id);
  };

  if (orderReminderMutation.isLoading) {
    return <LoadingIndicator />;
  } else if (orderReminderMutation.isSuccess) {
    return <Icon iconName="tick" className={styles['check-icon']} />;
  } else {
    return (
      <Tooltip text="Send reminder">
        <button
          className={styles['reminder-button']}
          onClick={handleClick}
          data-testid="reminder-button"
        >
          <Icon iconName="bell" />
        </button>
      </Tooltip>
    );
  }
};

OrderReminder.propTypes = {
  coop_id: PropTypes.any,
  onError: PropTypes.func,
};

export default OrderReminder;
