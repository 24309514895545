const dateStringOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };

const dateFormatter = (dateString) => {
  if (dateString) {
    const [year, month, day] = dateString.split('-');
    return new Date(year, month - 1, day).toLocaleDateString('en-GB', dateStringOptions);
  }
  return null;
};

export default dateFormatter;
