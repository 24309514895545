import './Button.css';

type ButtonVariant = 'primary' | 'secondary' | 'tertiary' | 'reminder' | 'back';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: ButtonVariant;
}

const Button: React.FunctionComponent<ButtonProps> = ({
  children,
  variant = 'primary',
  type = 'button',
  className = '',
  ...props
}) => (
  <button className={`button button--${variant} ${className}`} type={type} {...props}>
    {children}
  </button>
);

export default Button;
