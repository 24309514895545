import { createSlice } from '@reduxjs/toolkit';

const selectedOrdersSlice = createSlice({
  name: 'selectedOrders',
  initialState: { orders: [] },
  reducers: {
    selectOrder: (state, action) => {
      state.orders.push(action.payload);
    },
    selectOrders: (state, action) => {
      state.orders.push(...action.payload.filter((orderId) => !state.orders.includes(orderId)));
    },
    setSelectOrders: (state, action) => {
      state.orders = action.payload;
    },
    deselectOrder: (state, action) => {
      state.orders = state.orders.filter((orderId) => orderId !== action.payload);
    },
    clearSelectedOrders: (state) => {
      state.orders = [];
    },
  },
});

export const { selectOrder, selectOrders, setSelectOrders, deselectOrder, clearSelectedOrders } =
  selectedOrdersSlice.actions;

export const selectedOrdersSelector = (state) => state.selectedOrders.orders;

export default selectedOrdersSlice;
