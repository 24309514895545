import { createSlice } from '@reduxjs/toolkit';

const processingOrdersSlice = createSlice({
  name: 'processingOrders',
  initialState: { orders: [], newOrderCount: 0, isinitialised: false },
  reducers: {
    setProcessingOrders: (state, action) => {
      if (state.isinitialised) {
        const newOrders = action.payload
          ? action.payload.filter((newOrder) => {
              return !state.orders.some((oldOrder) => {
                return newOrder.coop_id === oldOrder.coop_id;
              });
            })
          : [];
        const existingOrders = action.payload
          ? state.orders.filter((existingOrder) => {
              return action.payload.some((newOrder) => {
                return existingOrder.coop_id === newOrder.coop_id;
              });
            })
          : [];
        const totalOrders = existingOrders.concat(
          newOrders.map((processingOrder) => ({
            ...processingOrder,
            isNew: true,
          }))
        );

        state.newOrderCount = totalOrders.filter((order) => order.isNew).length;
        state.orders = totalOrders;
      } else {
        if (action.payload) {
          state.orders = action.payload.map((processingOrder) => ({
            ...processingOrder,
            isNew: false,
          }));
          state.isinitialised = true;
        }
      }
    },
    clearProcessingOrders: (state) => {
      state.newOrderCount = 0;
      state.orders = [];
      state.isinitialised = false;
    },
    clearProcessingOrdersNewAlert: (state) => {
      state.newOrderCount = 0;
      state.orders = state.orders.map((order) => ({ ...order, isNew: false }));
    },
  },
});

export const { setProcessingOrders, clearProcessingOrders, clearProcessingOrdersNewAlert } =
  processingOrdersSlice.actions;
export const newOrderCountSelector = (state) => state.processingOrders.newOrderCount;
export const processingOrdersSelector = (state) => state.processingOrders.orders;

export default processingOrdersSlice;
