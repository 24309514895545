import styles from './Modal.module.scss';
import { Button, Icon } from '../../Atoms';
import { IconName } from '../../Atoms/Icon/Icon';

interface ModalProps extends React.HTMLAttributes<HTMLElement> {
  variant: 'primary' | 'secondary';
  closingX?: boolean;
  label?: string;
  icon?: IconName;
  primaryButtonLabel?: string;
  primaryButtonCallback?: any;
  secondaryButtonLabel?: string;
  secondaryButtonCallback?: any;
  onClose?: any;
}

const Modal: React.FunctionComponent<ModalProps> = ({
  children,
  closingX = false,
  label,
  onClose,
  icon,
  variant = 'primary',
  primaryButtonLabel,
  primaryButtonCallback,
  secondaryButtonLabel,
  secondaryButtonCallback,
  ...props
}) => (
  <div className={styles.background} onClick={() => onClose()}>
    <div className={styles.container} onClick={(e) => e.stopPropagation()} tabIndex={0} {...props}>
      <div className={styles[`content--${variant}`]}>
        <div>
          <div className={styles.close}>
            {closingX && <Icon iconName="circleXGreen" onClick={() => onClose()} />}
          </div>
          <div className={styles.icon}>{icon && <Icon iconName={icon} />}</div>
          <p className="title-3">{label}</p>
        </div>
        {children}
        {(primaryButtonLabel || secondaryButtonLabel) && (
          <>
            <hr />
            <div className={styles.row}>
              {secondaryButtonLabel && (
                <Button
                  className={styles.button}
                  onClick={() => secondaryButtonCallback()}
                  variant="tertiary"
                >
                  {secondaryButtonLabel}
                </Button>
              )}
              {primaryButtonLabel && (
                <Button
                  className={styles.button}
                  onClick={() => primaryButtonCallback()}
                  variant="primary"
                >
                  {primaryButtonLabel}
                </Button>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  </div>
);

export default Modal;
